<template>
  <div>
    <v-dialog v-model="overlay" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-container fill-height>
        <v-layout row justify-center align-center>
          <v-progress-circular indeterminate :size="70" :width="8" color="purple"></v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>
    <v-row no-gutters>
      <!-- io-comp -->
      <v-col class="col-8 pa-0 ma-0">
        <v-card style="background-color: #eef0f5;" class="pl-2 pr-2">
          <v-col class="ma-0 pa-0">
            <v-card-title class="title ma-0 pa-2">Ayarlar Listesi</v-card-title>
          </v-col>
          <v-row no-gutters class="io_editor">
            <v-col
                class="col-12"
                v-for="(item, i) in layoutData.comps"
                :key="i"
            >
              <v-card
                  class="my-2 mx-2"
                  :id="item.id"
                  @dragstart="dragStart(i, $event)"
                  @dragover.prevent
                  @dragenter="dragEnter(i, $event)"
                  @dragleave="dragLeave"
                  @dragend="dragEnd"
                  @drop="dragFinish(i, $event)"
                  draggable="true"
                  @mouseover="showDynamicButtons(i)"
                  @mouseleave="hideDynamicButtons(i)"
              >
                <v-row align="center" no-gutters>
                  <v-col class="col-4 mx-2">
                    <v-text-field v-if="item.input_type==='input'"
                                  dense
                                  v-model="item.label"></v-text-field>
                    <v-autocomplete
                        v-if="item.input_type==='select'"
                        dense
                        :items="item.values"
                        item-text="etiket"
                        item-value="deger"
                        :label="item.label"

                    ></v-autocomplete>

                  </v-col>



                  <!--<v-col align="center" class="col-4 mx-2"
                    ><span :style="'color:#' + item.one_color">{{
                      item.one_label
                    }}</span></v-col
                  >-->
                  <v-spacer></v-spacer>
                  <v-col
                      class="col-1"
                      v-if="isActionButtonsVisible[i].actionEdit"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                            v-on="on"
                            icon
                            color="indigo darken-1
"
                            @click="editItem(item)"
                        >
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </template>
                      <span>Bu elemanı düzenle</span>
                    </v-tooltip>
                  </v-col>

                  <v-col
                      class="col-1"
                      v-if="isActionButtonsVisible[i].actionDelete"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                            @click="removeItemAt(i)"
                            v-on="on"
                            icon
                            color="red darken-2"
                        >
                          <v-icon>delete_forever</v-icon>
                        </v-btn>
                      </template>
                      <span>Bu elemanı sil</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <!-- io-comp -->

      <!-- config part -->
      <v-col class="col-4 pl-4 pr-4">
        <v-card>
          <v-col class="ma-0 pa-0">
            <v-card-title class="title ma-0 pa-2"
            >Ayarlar Eleman Düzenleme</v-card-title
            >

            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >

              <v-text-field
                  class="col-auto"
                  label="Etiket"
                  v-model="formLabelModel"
                  @input="ioLabelInput"
              />
              <v-autocomplete
                  class="col-auto"
                  label="Bağlı alan"
                  :items="modemIOItems"
                  item-value="Id"
                  item-text="Text"
                  v-model="modemIOModel"
                  @input="modemIOSelect"
                  :rules="[v => !!v || 'Bağlı Alanın Doldurulması Gerekmektedir']"
              />
              <p class="ma-2">Liste Etiket ve Değerleri :</p>
              <v-row no-gutters >
                <v-col
                    class="col-12"
                    v-for="(item, i) in labelValueArr"
                    :key="i+5"
                >

                  <v-row no-gutters>
                    <v-col class="col-10 ">
                      <p class="ma-2">{{item.etiket +"  "+item.deger}}</p>

                    </v-col>
                    <v-col class="col-2">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                              @click="removeLabelValueAt(i)"
                              v-on="on"
                              icon
                              color="red darken-2"
                          >
                            <v-icon>delete_forever</v-icon>
                          </v-btn>
                        </template>
                        <span>Bu elemanı sil</span>
                      </v-tooltip>
                    </v-col>

                  </v-row>

                  <v-divider></v-divider>



                </v-col>
              </v-row>
              <v-row no-gutters >

                <v-col class="col-5">
                  <v-text-field
                      class="col-auto"
                      label="Etiket"
                      v-model="setLabelModel"
                  />

                </v-col>

                <v-col class="col-5">
                  <v-text-field
                      class="col-auto"
                      label="Değer"
                      v-model="setValueModel"
                  />
                </v-col>


                <v-col class="col-2 mt-6">
                  <v-btn
                      @click="addNewLabel"
                      class="text-capitalize white--text"
                      small
                      color="green darken-1"
                  >
                    <v-icon >mdi-plus-circle</v-icon>
                  </v-btn>
                </v-col>
              </v-row>



              <v-row justify="end" no-gutters>
                <v-col class="col-auto ma-2">
                  <v-btn
                      @click="addNewItem"
                      class="text-capitalize white--text"
                      small
                      color="green darken-1"
                  >
                    <v-icon left>mdi-plus-circle</v-icon> Yeni Ekle
                  </v-btn>
                </v-col>
                <v-col class="col-auto ma-2">
                  <v-btn
                      class="text-capitalize white--text"
                      small
                      color="red darken-2"
                      @click="saveChanges"
                  >
                    <v-icon left>mdi-content-save</v-icon> Değişiklikleri kaydet
                  </v-btn>
                </v-col>
              </v-row>

            </v-form>

          </v-col>
        </v-card>
      </v-col>
      <!-- config part -->
    </v-row>
    <vue-json-pretty
        :highlightMouseoverNode="true"
        :showLength="true"
        :showLine="true"
        :showSelectController="true"
        :data="layoutData"
    ></vue-json-pretty>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {generateRandomKey} from "../AlarmWizard/alarmwiz_events";

export default {
  name: "SettingsComponent",
  props: {
    fieldItem: {
      type: Object,
      required: true
    },
    dynamicM: [Array, Object],
    actionType: String,
    modemID: {
      type: [String, Number],
      required: false,
    }
  },
  data() {
    return {
      componentOBJ: { compName: "", compProp: null },


      valid:true,
      modemModel:"",
      tipModel:"",
      modemIOModel: null,
      modemIOItems: [],
      itemEdited: null,


      isActionButtonsVisible: [],


      formLabelModel: "",
      formItemModel: null,
      readonly: true,
      readOnly: [],
      dragging: -1,

      holderDragNode: null,
      dragItemIndex: -1,
      layoutData: {},

      setLabelModel:"",
      setValueModel:"",
      labelValueArr:[],

      overlay:true
    };
  },
  computed: {
    setSettingConfig: {
      set: function(value) {
        this.dynamicM["setting_config"] = value;
      },
      get: function() {
        return this.dynamicM["setting_config"];
      }
    },
    ...mapGetters({
      getterSocketMessage: "storeSocket/getterSocketMessage"
    })

  },
  created() {
    if(this.dynamicM[this.fieldItem.Field]===null || this.dynamicM[this.fieldItem.Field]==="" ||
        this.dynamicM[this.fieldItem.Field]===undefined){
      this.overlay=false;

      this.layoutData={
        comps:[]
      };

      let getModemIOList = {
        type: "getModemIOList",
        payload: { ModemId: parseInt(this.modemID) }
      };

      this.sendMessageToWS(getModemIOList);

    }
    else{
      this.overlay=false;


      this.layoutData = JSON.parse(
          decodeURIComponent(this.dynamicM[this.fieldItem.Field])
      );
      console.log(this.layoutData);
      this.layoutData.comps.forEach(() => {
        this.isActionButtonsVisible.push({
          actionDelete: false,
          actionEdit: false
        });
      });
      let getModemIOList = {
        type: "getModemIOList",
        payload: { ModemId: parseInt(this.modemID) }
      };

      this.sendMessageToWS(getModemIOList);
    }


    console.log("MODEM ID:", this.modemID);
  },
  watch: {
    getterSocketMessage: function(value) {
      let command = JSON.parse(value.data).command;
      if (command === "ModemIOList") {


        let data = JSON.parse(value.data).data;
        console.log("ModemIOList", data);
        if (data.Elements !== null) {
          Object.keys(data.Elements).forEach(key => {
            this.modemIOItems.push({
              Id: key,
              Text: data.Elements[key]
            });
          });
        }
      }
    },
    layoutData: {
      deep: true,
      handler(value) {
        this.dynamicM["setting_config"] = encodeURIComponent(
            JSON.stringify(value)
        );
      }
    }
  },
  methods: {
    /*
     * IO item sil, index'e göre
     * */
    removeItemAt(index) {
      this.layoutData.comps.splice(index, 1);
    },
    removeLabelValueAt(index){
      this.labelValueArr.splice(index,1);
    },
    /*
     * Yeni IO item ekle. isActionButtonsVisible sil ve düzenle aksiyon
     * butonlarını göstermek için kullanılan array.
     *
     * layoutData => yeni IO objesi ekle.
     * */
    addNewItem: function() {
      this.isActionButtonsVisible.push({
        actionDelete: false,
        actionEdit: false
      });

      if(this.$refs.form.validate()){
        console.log(this.modemIOModel);

        //Eğer önceden kaydedilmiş olan bir fieldın bağlı alanını değiştirmeden özelikleri
        // değiştirip yeni olarak kaydedilmek istenirse bağlı alan için bu kısım uygulanır.

        let [tip, master_id] = this.modemIOModel.split("_");
        var tip2=tip.toString();
        var master_id2=master_id.toString();


        //Yeni olarak eklenecek field
        console.log(this.layoutData);

        this.layoutData.comps.push({
          id: generateRandomKey(),
          tip: this.tipModel==="" ? tip2: this.tipModel,
          label: this.formLabelModel,
          master_id: this.modemModel==="" ? master_id2 :this.modemModel,
          birim:"",
          bit:"-1",
          input_type:this.labelValueArr.length>0 ? "select":"input",
          values:this.labelValueArr

        });
      }

    },
    addNewLabel:function(){
      this.labelValueArr.push({
        etiket:this.setLabelModel,
        deger:this.setValueModel
      });

      this.setLabelModel="";
      this.setValueModel="";

    },

    editItem: function(value) {
      console.log("Edit yapılacak item:", value);
      let targetElement = "";


      let x = value["tip"];
      let y = value["master_id"];

      targetElement = x + "_" + y ;

      console.log("Bağlı alan elemanı", targetElement);
      this.labelValueArr=value.values;
      this.modemIOModel = targetElement;
      this.formLabelModel = value.label;
      this.itemEdited = value;
    },
    /*
     * Sürükleme başla
     * */
    dragStart: function(index, event) {
      //event.dataTransfer.setData("Text", this.id);
      event.dataTransfer.dropEffect = "move";

      event.target.classList.add("draggedItem");
      event.target.classList.add("custom-card-list");

      this.dragging = index;
      this.holderDragNode = event;
    },
    /*
     * Item üzerine gelince.
     * */
    dragEnter: function(index, event) {
      if (index !== this.dragging) {
        event.dataTransfer.dropEffect = "copy";

        event.target.classList.remove("changeBGLeave");

        event.target.classList.add("custom-card-list");
        event.target.classList.add("changeBG");
      }
    },
    /*
     * Item üzerinde değil
     * */
    dragLeave: function(event) {
      event.target.classList.add("changeBGLeave");
      event.target.classList.remove("changeBG");
    },
    /*
     * Sürükleme bitti.
     * */
    dragEnd: function(event) {
      console.log("Drag end", event);
      this.dragging = -1;
      this.holderDragNode.target.classList.remove("draggedItem");
    },

    dragFinish: function(index, event) {
      let lights = document.getElementsByClassName("custom-card-list");
      while (lights.length)
        lights[0].className = lights[0].className.replace(
            /\bcustom-card-list\b/g,
            ""
        );

      event.target.classList.remove("changeBG");
      this.holderDragNode.target.classList.remove("draggedItem");

      this.moveItem(this.dragging, index);

      event.target.style.marginTop = "2px";
      event.target.style.marginBottom = "2px";
    },

    moveItem: function(from, to) {
      if (to === -1) {
        this.removeItemAt(from);
      } else {
        this.layoutData.comps.splice(
            to,
            0,
            this.layoutData.comps.splice(from, 1)[0]
        );
      }
    },
    modemIOSelect: function(value) {

      if (this.itemEdited !== null) {
        console.log("Bağlı alan seçildi:", value);

        let [tip, master_id] = value.split("_");

        this.itemEdited["tip"] = tip.toString();
        this.itemEdited["master_id"] = master_id.toString();


      }

      else{
        let [tip, master_id] = value.split("_");
        this.modemIOModel=value;
        this.tipModel=tip.toString();
        this.modemModel=master_id.toString();


      }
    },
    /*
     * Label düzenleme.
     * */
    ioLabelInput: function(value) {
      if (this.itemEdited !== null) {
        this.itemEdited["label"] = value;
      }
      else{
        this.formLabelModel=value;
      }
    },
    /*
     * Düzenle ve Sil butonlarını göster
     * */
    showDynamicButtons: function(index) {
      this.isActionButtonsVisible[index].actionDelete = true;
      this.isActionButtonsVisible[index].actionEdit = true;
    },
    /*
     * Düzenle ve Sil butonlarını gizle
     * */
    hideDynamicButtons: function(index) {
      this.isActionButtonsVisible[index].actionDelete = false;
      this.isActionButtonsVisible[index].actionEdit = false;
    },
    saveChanges: function() {
      console.log("Kaydedilecek Değer", this.layoutData);

      let a = encodeURIComponent(JSON.stringify(this.layoutData)).toString();
      console.log("URI=>", a);

      this.setSettingConfig = a;


    }
  }
};
</script>

<style scoped>
.io_editor {
}

.whiteBG {
  transition: 0.2s ease;
  background-color: #fff;
}

.changeBG {
  -webkit-animation: DnDAnimation 1s infinite;
  -moz-animation: DnDAnimation 1s infinite;
  -o-animation: DnDAnimation 1s infinite;
  animation: DnDAnimation 1s infinite;
}

.changeBGLeave {
  -webkit-animation: DnDAnimationLeave 1s;
  -moz-animation: DnDAnimationLeave 1s;
  -o-animation: DnDAnimationLeave 1s;
  animation: DnDAnimationLeave 1s;
}

@keyframes DnDAnimation {
  0% {
    background-color: #fff;
  }
  50% {
    background-color: #678dff;
  }
  100% {
    background-color: #fff;
  }
}

@keyframes DnDAnimationLeave {
  from {
    background-color: #678dff;
  }
  to {
    background-color: #fff;
  }
}

.custom-card-list * {
  pointer-events: none !important;
}

.draggedItem {
  transition: 0.2s ease;
  background-color: white !important;
  opacity: 0.5;
  transform: scale(0.95);
}
</style>
