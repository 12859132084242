<template>
  <v-row no-gutters>
    <!--    <span class="col-6 align-self-center">{{ getInputValue }}</span>-->
    <v-col cols="6" class="pa-0 align-self-center">
      <span>{{ indicatorData["label"] }}</span>
    </v-col>
    <v-col v-if="indicatorData['input_ui'] === '1'" cols="6" class="pa-0">
      <div
          class="tip1Indicator"
          :style="{
          backgroundColor:
            getInputValue === '1'
              ? `#${indicatorData['one_color']}`
              : `#${indicatorData['zero_color']}`,
          transition: 'background-color .4s ease-in-out'
        }"
      >
        <div class="indicatorOverlay"></div>
      </div>
    </v-col>

    <v-col v-else-if="indicatorData['input_ui'] === '0'" cols="6" class="pa-0">
      <p
          class="mb-0"
          :style="{
          color:
            getInputValue === '1'
              ? `#${indicatorData['one_color']}`
              : `#${indicatorData['zero_color']}`
        }"
      >
        {{ getInputValue }}
      </p>
    </v-col>

    <!-- Custom -->
    <v-col v-else-if="indicatorData['input_ui'] === '2'" cols="6" class="pa-0">
      <v-chip  class="body-1 font-weight-medium"
               :color="getInputValue === '1'
              ? `#${indicatorData['one_color']}`
              : `#${indicatorData['zero_color']}`"
               text-color="white"
      >
        {{ getInputValue === "1" ? indicatorData['one_label'] : indicatorData['zero_label'] }}
      </v-chip>
    </v-col>

  </v-row>
</template>

<script>
// converts decimal values to 8-bit binary array & returns desired bit value
import {findBinaryValue} from "@/components/ModemLayoutWizard/ModemLayoutSettings/svgComponentUtils/svgUtilFunctions";

export default {
  name: "inputIndicator",
  props: {
    /* Data as in 0 or 1
     * This prop is going to be the initial data that we receive from ListSetup
     * */
    inputData: {
      type: String,
      required: true
    },
    /*Contains object info*/
    indicatorData: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      indicatorValue: "0"
    };
  },
  computed: {
    /*
    * Using computed properties for checking data state
    * If "inputData" property changes getInputValue computed property...
    * will call findBinaryValue function with data as an argument and return the new value.
     */
    getInputValue: function () {
      return findBinaryValue(this.inputData,this.indicatorData["bit"]);
    },
  }
};
</script>

<style scoped>
.tip1Indicator {
  height: 30px;
  width: 30px;
  border-radius: 4px;
  border: 2px solid black;
}

.indicatorOverlay {
  display: block;
  background: rgb(0, 0, 0);
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0) 100%
  );
  margin: 0;
  padding: 0;
  height: 100%;
}
</style>
