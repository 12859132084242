<template>
  <div v-if="parentName !== 'ModemTabLayout'">
    <v-row no-gutters>
      <v-text-field
          label="Genişlik"
          @input="changeSizeOfSVG"
          v-model="currentItem['genislik']"
      />
    </v-row>

    <v-row no-gutters>
      <v-col class="col-6 pr-1">
        <v-text-field
            label="Açık Etiket"
            v-model="currentItem['onLabel']"
            @input="changeLabelOn"
        />
      </v-col>
      <v-col class="col-6 pl-1">
        <v-menu
            ref="cpRef"
            v-model="colorMenuOn"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-x
            min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
                label="Açık Renk"
                prepend-icon="event"
                readonly
                v-on="on"
                return-object
                v-model="currentItem['onLabelColor']"
            >
              <v-icon slot="prepend" class="oneLabelIcon">
                mdi-invert-colors
              </v-icon>
            </v-text-field>
          </template>
          <v-color-picker
              v-model="currentItem['onLabelColor']"
              @input="changeColorOnLabel"
          ></v-color-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col class="col-6 pr-1">
        <v-text-field label="Kapalı Etiket" v-model="currentItem['offLabel']" />
      </v-col>
      <v-col class="col-6 pl-1">
        <v-menu
            ref="cpRef"
            v-model="colorMenuOff"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-x
            min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
                label="Kapalı Renk"
                prepend-icon="event"
                readonly
                v-on="on"
                v-model="currentItem['offLabelColor']"
            >
              <v-icon slot="prepend" class="oneLabelIcon">
                mdi-invert-colors
              </v-icon>
            </v-text-field>
          </template>
          <v-color-picker
              v-model="currentItem['offLabelColor']"
              @input="changeColorOffLabel"
          ></v-color-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col class="col-6 pr-1">
        <v-autocomplete
            :items="ioListData"
            v-model="currentItem['bagli_alan']"
            label="Bağlı Alan"
            @change="ioListSelect"
            clearable
        />
      </v-col>
      <v-col class="col-6 pl-1">
        <v-autocomplete label="Bit" :items="bits" v-model="currentItem['bit']" />
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col class="col-6 pr-1">
        <v-text-field label="0 Değer" v-model="currentItem['offValue']" />
      </v-col>
      <v-col class="col-6 pl-1">
        <v-text-field label="1 Değer" v-model="currentItem['onValue']" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  changeSvgDimension,
  getSVGChildElements,
  getBitValue
} from "./svgComponentUtils/svgUtilFunctions";
import { mapGetters,mapActions } from "vuex";

export default {
  name: "slideSwitch",
  props: {
    currentItem: {
      type: Object,
      required: false
    },
    modemID: {
      type: [Array, Number],
      required: false
    },
    //from modem tab layout
    parentName: String,
    svgItemObject: {
      type: Object,
      required: false
    },
    listSetup: Object,
    dataProperty: Object
  },
  data() {
    return {
      switchDOMElements: {},

      ioListData: [],

      slideSwState: false,

      bits: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16"
      ],
      colorMenuOn: false,
      colorMenuOff: false,

      //this is going to include the Datas object from ProcessData response
      updatedDataValues: {}
    };
  },
  computed: {
    ...mapGetters({
      getterSocketMessage: "storeSocket/getterSocketMessage",
      getterIsSocketConnected: "storeSocket/getterIsSocketConnected"
    }),
    getListSetupComps: function() {
      return this.listSetup["comps"];
    },
    getDataProperty: function() {
      return this.dataProperty;
    },
    /*
     * Slide Switch Dynamic Styles
     * */
    switchBaseOnStyle: function() {
      return `fill:#${
          this.svgItemObject["onLabelColor"]
      }; transition: 1.5s ease-in-out`;
    },
    switchBaseOffStyle: function() {
      return `fill:#${
          this.svgItemObject["offLabelColor"]
      }; transition: 1.5s ease-in-out`;
    },
    switchCircleOnStyle: function() {
      return `transform:translateX(0px); transition: 1.5s ease-in-out`;
    },
    switchCircleOffStyle: function() {
      return `transform:translateX(-72px); transition: 1.5s ease-in-out`;
    },
    switchTextOffStyle: function() {
      return `transform:translateX(60px); transition: 1.5s ease-in-out`;
    },
    switchTextOnStyle: function() {
      return `transform:translateX(0px); transition: 1.5s ease-in-out`;
    }
  },
  created() {
    if (this.parentName !== "ModemTabLayout") {
      let requestIOList = {
        type: "getModemIOList",
        payload: {
          TargetID: this.currentItem["id"],
          ModemId: parseInt(this.modemID[0]),
          Secim: "2@4"
        }
      };
      this.sendMessageToWS(requestIOList);
    }
  },
  mounted() {
    if (this.parentName === "ModemTabLayout") {
      this.switchDOMElements = this.registerSVGElements();
      //set initial switch value here
      this.updatedDataValues = this.getDataProperty;
      console.log("SLIDE SW PROCESS DATA UPDATED DATA VALUES BEFORE", this.updatedDataValues);
      this.setBitSlideSwitch(getBitValue(this.getDataProperty,this.svgItemObject));
    }
  },
  watch: {
    getterSocketMessage: function(value) {
      let parsed = JSON.parse(value.data);
      if (
          parsed.command === "ModemIOList" &&
          parsed.data.TargetID === this.currentItem["id"]
      ) {
        this.ioListData = [];
        let dataObj = JSON.parse(value.data).data.Elements;
        this.ioListData = Object.keys(dataObj).map(key => {
          return {
            value: key,
            text: dataObj[key]
          };
        });
        console.log(this.ioListData);
      } else if (parsed.command === "ProcessData") {
        const data = parsed["data"];
        if (data['ModemId'] === this.modemID) {
          //console.log("Modem ID Slide Switch", this.modemID)
          this.updatedDataValues['Datas'] = {...this.updatedDataValues['Datas'],...data['Datas']};
          this.actionDatasUpdate(this.updatedDataValues['Datas']);
          this.setBitSlideSwitch(getBitValue(data,this.svgItemObject));
          //console.log("SLIDE SW PROCESS DATA UPDATED DATA VALUES AFTER", data, this.updatedDataValues['Datas']);
        }
      }
    }
  },
  methods: {
    ...mapActions({
      actionDatasUpdate: "storeDistributeSvgData/actionDatasUpdate"
    }),
    registerSVGElements: function() {
      const onOffGroup = getSVGChildElements(
          this.svgItemObject,
          "#switch-onoff-group"
      );
      onOffGroup.addEventListener("click", this.switchClickEvent, false);
      onOffGroup.setAttribute("cursor", "pointer");
      const [switchBaseRect, switchCircle, switchText] = onOffGroup.children;
      const textTSPAN = switchText.querySelector("tspan");
      const waitGroup = getSVGChildElements(
          this.svgItemObject,
          "#switch-wait-group"
      );

      return {
        onOffGroup,
        switchBaseRect,
        switchCircle,
        switchText,
        textTSPAN,
        waitGroup
      };
    },
    switchClickEvent: function() {
      const [tip, master_id, bit] = this.svgItemObject["bagli_alan"].split("_");
      const bitValue = getBitValue(this.updatedDataValues,this.svgItemObject);

      const setOutput = {
        type: "setModemOutput",
        payload: {
          ModemId: this.modemID ,
          Target: `${tip}_${master_id}_${bit}`,
          Value: bitValue === "1" ? 0 : 1
        }
      };
      this.sendMessageToWS(setOutput);

      // Show wait group each time switch is clicked.
      this.switchDOMElements["waitGroup"].setAttribute("display", "block");
      setTimeout(() => {
        this.switchDOMElements["waitGroup"].setAttribute("display", "none");
      }, 10000);
    },
    setBitSlideSwitch: function(value) {
      console.log("Set Bit Slide Switch called", value);
      if (value === "1") {
        // set on
        this.switchDOMElements["switchBaseRect"].setAttribute(
            "style",
            this.switchBaseOnStyle
        );
        this.switchDOMElements["switchCircle"].setAttribute(
            "style",
            this.switchCircleOnStyle
        );
        this.switchDOMElements["switchText"].setAttribute(
            "style",
            this.switchTextOnStyle
        );

        this.switchDOMElements[
            "textTSPAN"
            ].innerHTML = this.svgItemObject.onLabel;
      } else if (value === "0") {
        //set off
        this.switchDOMElements["switchBaseRect"].setAttribute(
            "style",
            this.switchBaseOffStyle
        );
        this.switchDOMElements["switchCircle"].setAttribute(
            "style",
            this.switchCircleOffStyle
        );
        this.switchDOMElements["switchText"].setAttribute(
            "style",
            this.switchTextOffStyle
        );

        this.switchDOMElements[
            "textTSPAN"
            ].innerHTML = this.svgItemObject.offLabel;
      }
    },
    changeLabelOn: function() {
      const switchText = getSVGChildElements(
          this.currentItem,
          "#switch-onoff-text"
      );
      switchText.querySelector("tspan").innerHTML = this.currentItem["onLabel"];
    },
    changeColorOnLabel: function(value) {
      const substrColor = value.substring(1, value.length - 2);
      const switchBase = getSVGChildElements(
          this.currentItem,
          "#switch-onoff-base"
      );

      switchBase.setAttribute("fill", `#${substrColor}`);
      this.currentItem["onLabelColor"] = substrColor;
    },
    changeColorOffLabel: function(value) {
      this.currentItem["offLabelColor"] = value.substring(1, value.length - 2);
    },
    changeSizeOfSVG: function() {
      changeSvgDimension(this.currentItem);
    },
    ioListSelect: function(value) {
      this.currentItem["set_bagli_alan"] = value;
      const splitted = value.split("_");
      const [tip, master, bit, birim] = splitted;

      console.log("IO List Select value", tip, master, bit, birim);
      if (bit !== undefined) {
        this.currentItem["bit"] = bit;
        this.currentItem["set_bit"] = bit;
      }
    }
  }
};
</script>

<style>
.active {
  -webkit-animation: path 2.2s ease-in-out forwards;
  -moz-animation: path 2.2s ease-in-out forwards;
  animation: path 2.2s ease-in-out forwards;
}

@keyframes path {
  0% {
    transform: translate(-50, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

.passive {
  transform: translateY(0);
  transition: 0.2s ease;
}
</style>
