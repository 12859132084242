<template>
    <v-container class="pa-0" fluid fill-height>
        <v-layout align-center justify-center>
            <v-flex class="pa-10" xs12 sm8 md4 lg4>

                <div class="tlx_logo_wrapper mt-5 mb-5 text-center">
                    <!--<img src="img/telix-logo.svg"/>-->
                    <picture>
                        <source :srcset="requireLogo" media="(min-width: 1000px)" />
                        <source :srcset="requireLogo" media="(max-width: 1000px)" />
                        <img width="120px" :srcset="requireLogo" />
                    </picture>
                </div>
                <v-form>
                    <div>
                        <v-btn @click="onNavigateBack" icon>
                            <v-icon>arrow_back</v-icon>
                        </v-btn>
                        <span class="pl-1" style="vertical-align: middle; display: inline-block">Geri</span>
                    </div>
                    <v-flex>
                        <v-text-field
                                class="mt-2 mb-2"
                                color="darkDef"
                                clearable
                                clear-icon="clear"
                                type="email"
                                label="E-Posta adresinizi girin."
                        ></v-text-field>
                        <div class="my-2">
                            <v-btn
                                    class="white--text text-capitalize body-1"
                                    block
                                    large
                                    color="loginButton"
                            >Şifremi sıfırla</v-btn>
                        </div>
                    </v-flex>
                </v-form>

            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import {bus} from "@/main";

    export default {
        name: "ForgotPassword",
        components: {

        },
        data(){
            return {
                page_title: "Şifremi unuttum",
                isThemeDark: this.$vuetify.theme.dark
            };
        },
        computed: {
            requireLogo: function() {
                return this.$vuetify.theme.dark
                    ? require("../assets/telix-logo-white.svg")
                    : require("../assets/telix-logo.svg");
            }
        },
        methods: {
            onNavigateBack: function () {
                console.log("clicked");
                bus.$emit("clickedBack", {
                        item:"Back"
                });
            }
        }

    }
</script>

<style scoped>

</style>
