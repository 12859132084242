<template>
  <div id="base_grid">
    <grid-layout
        color="gridItemBackground"
        :layout.sync="gridLayoutData"
        :col-num="12"
        :row-height="48"
        :is-draggable="true"
        :is-resizable="true"
        :is-mirrored="false"
        :vertical-compact="true"
        :margin="[20, 20]"
        :use-css-transforms="true"
        @layout-ready="layoutMountedEvent"
        autoSize
    >
      <grid-item
          :class="item.static ? `pinnedBorder--${theme}` : 'unpinnedBorder'"
          class="v_grid_item"
          :style="{ background: $vuetify.theme.themes[theme].gridItemBackground }"
          v-for="(item, index) in gridLayoutData"
          v-bind:key="item.i"
          :x="item.x"
          :y="item.y"
          :w="item.w"
          :h="item.h"
          :i="item.i"
          :minH="item.minH"
          :maxH="item.maxH"
          :is-resizable="item.resizable"
          :id="item.title"
          :static="item.static"
          style="overflow-y: scroll"
      >
        <div>
          <v-app-bar dense :color="item.color" class="customAppBar">
            <v-icon style="font-size: 32px" color="white">
              {{ item.icon }}
            </v-icon>
            <span class="white--text ml-3 ellipsis_class">{{
                item.label
              }}</span>
            <v-spacer></v-spacer>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                    small
                    @click="item.static = !item.static"
                    class="grid-item-toolbar-button"
                    icon
                    v-on="on"
                >
                  <v-icon
                      :class="item.static ? 'rotatedIcon' : 'unrotatedIcon'"
                      :color="item.static ? 'green accent-3' : 'white'"
                  >
                    mdi-pin</v-icon
                  >
                </v-btn>
              </template>
              <span>{{
                  item.static ? "Sabitlemeyi kaldır" : "Bu elemanı sabitle"
                }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn small icon v-on="on" @click="hideItem(item, index)">
                  <v-icon color="white"> mdi-eye-off</v-icon>
                </v-btn>
              </template>
              <span>Bu elemanı gizle</span>
            </v-tooltip>
          </v-app-bar>

          <component
              :ref="`${item['command']}`"
              :is="item['command']"
              :table-data="item['gridData']"
          >
            <template v-slot:no-data-view>
              <v-container>
                <v-img
                    class="my-6"
                    :height="120"
                    width="100%"
                    :aspect-ratio="16 / 9"
                    contain
                    style=" opacity: .5; "
                    :src="require(`@/assets/skeletons/${item['noDataSkeleton']}`)"
                />
              </v-container>
            </template>
          </component>
        </div>
      </grid-item>
    </grid-layout>
  </div>
</template>

<script>
import VueGridLayout from "vue-grid-layout";
import AlarmList from "@/components/SimpleTableComp/DashboardTables/AlarmList";
import ClosedAlarmList from "@/components/SimpleTableComp/DashboardTables/ClosedAlarmList";
import LowAntenna from "@/components/SimpleTableComp/DashboardTables/LowAntenna";
import DisconnectedCards from "@/components/SimpleTableComp/DashboardTables/DisconnectedCards";
import DisconnectedModems from "@/components/SimpleTableComp/DashboardTables/DisconnectedModems";
import { mapActions } from "vuex";
import store from "../../vuex_store/store_wrapper";

export default {
  name: "DynamicGridView",
  components: {
    AlarmList: AlarmList,
    ClosedAlarmList: ClosedAlarmList,
    LowAntenna: LowAntenna,
    DisconnectedCards: DisconnectedCards,
    DisconnectedModems: DisconnectedModems,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem
  },
  props: {
    gridObject: {
      type: Object,
      required: true,
      default: () => {
        return {
          AlarmList: {},
          ClosedAlarmList: {},
          DisconnectedCards: null,
          DisconnectedModems: [],
          LowAntenna: []
        };
      }
    }
  },
  data() {
    return {
      unsubscribe: null,
      setAutoSize: false,
      isPinnedShow: true,
      isUnpinnedShow: false,
      isLayoutCreated: false,
      gridLayoutData: [],
      gridItemConstants: [
        "AlarmList",
        "ClosedAlarmList",
        "DisconnectedCards",
        "LowAntenna",
        "DisconnectedModems"
      ],
      AlarmList: {
        x: 0,
        y: 0,
        h: 6,
        w: 8,
        i: 0,
        right: true,
        gridData: [],
        minH: 3,
        maxH: 10,
        static: false,
        hideItem: false,
        color: "#e64e4e",
        icon: "notification_important",
        command: "AlarmList",
        label: "Alarmlar",
        noDataSkeleton: "skeletonAlarm.svg"
      },
      ClosedAlarmList: {
        x: 0,
        y: 6,
        h: 6,
        w: 8,
        i: 1,
        right: true,
        gridData: [],
        minH: 3,
        maxH: 10,
        static: false,
        hideItem: false,
        color: "#4e5261",
        icon: "notifications_off",
        command: "ClosedAlarmList",
        label: "Kapalı Alarmlar",
        noDataSkeleton: "skeletonClosedAlarms.svg"
      },
      DisconnectedCards: {
        x: 8,
        y: 4,
        h: 4,
        w: 4,
        i: 4,
        right: true,
        gridData: [],
        minH: 3,
        maxH: 10,
        static: false,
        hideItem: false,
        color: "#545a96",
        icon: "perm_scan_wifi",
        command: "DisconnectedCards",
        label: "Bağlantısı Kopmuş Kartlar",
        noDataSkeleton: "skeletonDisconnectedCards.svg"
      },
      LowAntenna: {
        x: 8,
        y: 0,
        h: 4,
        w: 4,
        i: 2,
        right: true,
        gridData: [],
        minH: 3,
        maxH: 10,
        static: false,
        hideItem: false,
        color: "#e68d39",
        icon: "signal_cellular_null",
        command: "LowAntenna",
        label: "Düşük Antenler",
        noDataSkeleton: "skeletonLowAntenna.svg"
      },
      DisconnectedModems: {
        x: 8,
        y: 8,
        h: 4,
        w: 4,
        i: 3,
        right: true,
        gridData: [],
        minH: 3,
        maxH: 10,
        static: false,
        hideItem: false,
        color: "#1f2440",
        icon: "perm_scan_wifi",
        command: "DisconnectedModems",
        label: "Bağlantısı Kopmuş Modemler",
        noDataSkeleton: "skeletonDisconnectedModems.svg"
      }
    };
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    }
  },
  beforeDestroy () {
    // removing listeners or subscriptions if any
    this.unsubscribe && this.unsubscribe();
    window.removeEventListener("resize", this.resizeWindow);
  },
  created() {
    this.createGrid();
    window.addEventListener("resize", this.resizeWindow);
    //Subscribe to store actions. update layout array accordingly
    this.unsubscribe = store.subscribeAction(action => {
      const {type,payload} = action;
      if (type === "storeGridEvents/actionHideItem") {
        const { index } = payload;
        this.gridLayoutData.splice(index, 1);
      } else if (type === "storeGridEvents/actionShowItem") {
        const { item } = payload;
        const {i} = item;
        this.gridLayoutData.splice(i,0,item);
      }
    });
  },
  methods: {
    /*
    * Vuex Store actions => show & hide
    * */
    ...mapActions({
      actionHideItem: "storeGridEvents/actionHideItem",
      actionShowItem: "storeGridEvents/actionShowItem"
    }),
    /*call autoSize functions layout after layout is mounted*/
    layoutMountedEvent: function() {
      this.gridLayoutData.forEach(m => {
        console.log("Auto Size",this.$refs[m['command']][0])
        this.$refs[m['command']][0].setAutoSize();
      });
    },
    /*
    * Create grid layout using grid data.
    * map the grid object data to layout array.
    * This way we make sure that layout data has current updates
    * */
    createGrid: function() {
      console.log("Grid Object", this.gridObject);
      this.gridLayoutData = Object.keys(this.gridObject).map(k => {
        return { ...this[k], ...{ gridData: this.gridObject[k] } };
      });
      //After setting the layout data then start first grid setup.
      this.firstGridSetup();
    },
    /*Call actionHideItem (from vuex store) if item is hidden.*/
    hideItem: function(item, index) {
      this.actionHideItem({
        item: item,
        index: index
      });
    },
    /*
    * Resize event listener calls this function. At a certain breakpoint...
    * All grid items width of 12
    * */
    resizeWindow: function(e) {
      if (e.target.innerWidth < 960) {
        this.gridLayoutData = this.gridLayoutData.map(a => {
          return {
            ...a,
            w: 12
          };
        });
      } else {
        this.gridLayoutData = this.gridLayoutData.map(a => {
          if (
              a["command"] === "AlarmList" ||
              a["command"] === "ClosedAlarmList"
          ) {
            return {
              ...a,
              w: 8
            };
          } else {
            return {
              ...a,
              w: 4
            };
          }
        });
      }
    },
    /*
    * First setup after grid loaded
    * Checks the breakpoint for window.innerWidth. (Mobile / Desktop)
    * */
    firstGridSetup: function() {
      if (window.innerWidth < 960) {
        this.gridLayoutData = this.gridLayoutData.map(a => {
          return {
            ...a,
            w: 12
          };
        });
      } else {
        this.gridLayoutData = this.gridLayoutData.map(a => {
          if (
              a["command"] === "AlarmList" ||
              a["command"] === "ClosedAlarmList"
          ) {
            return {
              ...a,
              w: 8
            };
          } else {
            return {
              ...a,
              w: 4
            };
          }
        });
      }
    }
  }
};
</script>

<style scoped>
.customAppBar {
  position: sticky !important;
  top: 0 !important;
}

.vue-grid-item {
  background: white;
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.2);
  border-radius: 4px !important;
}

.grid-item-toolbar-button {
  margin: 3px 3px 3px 0;
}

.unrotatedIcon {
  transition: 0.2s ease;
  transform: rotateZ(0deg) !important;
}

.rotatedIcon {
  transition: 0.2s ease;
  transform: rotateZ(45deg) !important;
}

.ellipsis_class {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.pinnedBorder--light {
  border: 2px dashed rgba(0, 0, 0, 0.5);
}

.pinnedBorder--dark {
  border: 2px dashed rgba(255, 255, 255, 0.5);
}

.unpinnedBorder {
  border: none;
}
</style>
