<template>
  <div id="wrapdiv">
    <component
      :is="componentOBJ.compName"
      :dynamicProp="componentOBJ.compProp"
      eventPositive="svgFormSave"
      eventNegative="svgFormCancel"
      @svgFormSave="svgFormSave"
      @svgFormCancel="svgFormCancel"
    ></component>
    <v-row no-gutters>
      <!-- svg-part -->
      <v-col class="col-8 pa-0 ma-0">
        <v-card style="" class="pl-2 pr-2">
          <p v-if="svgData === ''">{{ modemSVGRequestMessage }}</p>
          <div
            v-html="svgData"
            class="svg_wrapper"
            id="svg_wrapper_id"
            style="background-color: #eef0f5; border: 2px dashed lightcoral"
          ></div>
          <v-banner
            color="#ebeef2"
            v-model="bannerModel"
            :single-line="false"
            transition="slide-y-transition"
          >
            <p class="mb-0 font-weight-medium" style="font-size: 18px;">
              {{
                this.currentLayoutItem.baslik !== undefined
                  ? this.currentLayoutItem.baslik + " elemanı düzenlenmektedir."
                  : this.currentLayoutItem.type + " elemanı düzenlenmektedir."
              }}
            </p>
            <template v-slot:actions="{}">
              <v-btn
                text
                color="indigo darken-1"
                @click="finishEditingSVG"
                class="text-capitalize"
              >
                Düzenlemeyi bitir
              </v-btn>
              <v-btn
                class="text-capitalize white--text"
                color="red darken-2"
                @click="removeChildSVG"
                ><v-icon left>delete</v-icon>
                Seçili Bileşeni Kaldır
              </v-btn>
            </template>
          </v-banner>
        </v-card>
      </v-col>
      <!-- svg-part -->

      <!-- editor-part -->
      <v-col class="col-4 pa-0 ma-0">
        <v-card style="background-color: #eef0f5;" class="pl-0 pr-0">
          <v-tabs v-model="tab">
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-genel" title="tab-genel">
              Tab Genel
            </v-tab>

            <v-tab href="#tab-elemanlar">
              Tab Elemanlar
            </v-tab>

            <v-tab-item value="tab-genel">
              <v-card flat tile>
                <v-text-field
                  label="En"
                  v-model="getSVGWidth"
                  type="number"
                  @input="changeBaseSVGDimensions"
                />
                <v-text-field
                  label="Boy"
                  v-model="getSVGHeight"
                  type="number"
                  @input="changeBaseSVGDimensions"
                />
                <v-text-field label="Görünür İsim" v-model="getSVGTagName" />

                <v-divider />

                <v-col class="col-12">
                  <component
                    :key="currentLayoutItem['id']"
                    :is="currentLayoutItem['type']"
                    :currentItem="currentLayoutItem"
                    :fileList="svgFileList"
                    :modemID="modemID"
                    :parentName="'ModemLayoutWizard'"
                  />
                </v-col>
              </v-card>
            </v-tab-item>

            <v-tab-item value="tab-elemanlar">
              <v-card flat tile>
                <v-expansion-panels>
                  <v-expansion-panel v-for="(item, i) in svgFileList" :key="i">
                    <v-expansion-panel-header>{{
                      item["isim"]
                    }}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row no-gutters>
                        <v-col
                          class="col-6 pa-2"
                          v-for="(el, el_index) in item['elemanlar']"
                          :key="el_index"
                        >
                          <v-card
                            @click="
                              svgAddPreview(
                                fileSVGs[el['class_name']],
                                el['class_name']
                              )
                            "
                            class="svg_preview_card text-center"
                            outlined
                            flat
                          >
                            <div
                              :ref="el['class_name']"
                              v-html="fileSVGs[el['class_name']]"
                            ></div>
                            <v-card-text
                              class="px-0 py-0 black--text nonePointer"
                              v-text="el['scada_name']"
                            ></v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
      <!-- editor-part -->
    </v-row>
    <vue-json-pretty
      :highlightMouseoverNode="true"
      :showLength="true"
      :showLine="true"
      :showSelectController="true"
      :data="layoutData"
    ></vue-json-pretty>
  </div>
</template>

<script>
import axios from "axios";
import groupBox from "./ModemLayoutSettings/groupBox";
import roundedLabel from "./ModemLayoutSettings/roundedLabel";
import slideSwitch from "./ModemLayoutSettings/slideSwitch";
import depoStd from "./ModemLayoutSettings/depoStd";
import kuyuStd from "./ModemLayoutSettings/kuyuStd";
import pompa2li from "./ModemLayoutSettings/pompa2li";
import pompaTek from "./ModemLayoutSettings/pompaTek";
import pompaUclu from "./ModemLayoutSettings/pompaUclu";
import deiceModule from "./ModemLayoutSettings/deiceModule";
import jeneratorStd from "./ModemLayoutSettings/jeneratorStd";
import pushButton from "./ModemLayoutSettings/pushButton";
import { mapGetters } from "vuex";
import { generateRandomKey } from "../AlarmWizard/alarmwiz_events";
import { constructAlertDialog } from "../ConstructDialogsAlertsSnackbars/constructor";
import CustomDialogAlert from "../DialogComponent/CustomDialogAlert";
import CustomSnackbar from "../SnackbarComp/CustomSnackbar";
import {
  getSvgPreview,
  getSvgPropertyData
} from "./ModemLayoutSettings/svgComponentUtils/svgUtilFunctions";
import yonBilgiStd from "@/components/ModemLayoutWizard/ModemLayoutSettings/yonBilgiStd";
import yuruyenTekliStd from "@/components/ModemLayoutWizard/ModemLayoutSettings/yuruyenTekliStd";
import yuruyenIkiliStd from "@/components/ModemLayoutWizard/ModemLayoutSettings/yuruyenIkiliStd";
import asansorStd from "@/components/ModemLayoutWizard/ModemLayoutSettings/asansorStd";
import katBilgiGosterge from "@/components/ModemLayoutWizard/ModemLayoutSettings/katBilgiGosterge";
import slideSwitchOffset from "@/components/ModemLayoutWizard/ModemLayoutSettings/slideSwitchOffset";
import dijitalGosterge from "@/components/ModemLayoutWizard/ModemLayoutSettings/dijitalGosterge";
import elemanEtiket from "@/components/ModemLayoutWizard/ModemLayoutSettings/elemanEtiket";
import cardViewInputOutput from "@/components/ModemLayoutWizard/ModemLayoutSettings/CardViewIO/cardViewInputOutput";
import flatorStd from "@/components/ModemLayoutWizard/ModemLayoutSettings/flatorStd";
import drenaj2MotorStd from "@/components/ModemLayoutWizard/ModemLayoutSettings/drenaj2MotorStd";
import pompaEtiketStd from "@/components/ModemLayoutWizard/ModemLayoutSettings/pompaEtiketStd";
import alarmEtiketStd from "@/components/ModemLayoutWizard/ModemLayoutSettings/alarmEtiketStd";
import yatayEtiketStd from "@/components/ModemLayoutWizard/ModemLayoutSettings/yatayEtiketStd";
import boxEtiketStd from "@/components/ModemLayoutWizard/ModemLayoutSettings/boxEtiketStd";
import pushButtonModern from "@/components/ModemLayoutWizard/ModemLayoutSettings/pushButtonModern";
import switchModern from "@/components/ModemLayoutWizard/ModemLayoutSettings/switchModern";
import jeneratorPanel from "@/components/ModemLayoutWizard/ModemLayoutSettings/jeneratorPanel";

export default {
  name: "ModemLayoutWizardComponent",
  components: {
    groupBox: groupBox,
    roundedLabel: roundedLabel,
    slideSwitch: slideSwitch,
    slideSwitchOffset: slideSwitchOffset,
    depoStd: depoStd,
    kuyuStd: kuyuStd,
    pompa2li: pompa2li,
    pompaTek: pompaTek,
    pompaUclu: pompaUclu,
    deiceModule: deiceModule,
    jeneratorStd: jeneratorStd,
    pushButton: pushButton,
    yonBilgiStd: yonBilgiStd,
    yuruyenTekliStd: yuruyenTekliStd,
    yuruyenIkiliStd: yuruyenIkiliStd,
    asansorStd: asansorStd,
    katBilgiGosterge: katBilgiGosterge,
    dijitalGosterge: dijitalGosterge,
    elemanEtiket: elemanEtiket,
    cardViewInputOutput: cardViewInputOutput,
    flatorStd: flatorStd,
    drenaj2MotorStd: drenaj2MotorStd,
    pompaEtiketStd: pompaEtiketStd,
    alarmEtiketStd: alarmEtiketStd,
    yatayEtiketStd: yatayEtiketStd,
    boxEtiketStd: boxEtiketStd,
    pushButtonModern: pushButtonModern,
    switchModern: switchModern,
    jeneratorPanel: jeneratorPanel,
    "custom-dialog-alert": CustomDialogAlert,
    "custom-snackbar": CustomSnackbar
  },
  props: {
    fieldItem: {
      type: Object,
      required: true
    },
    dynamicM: [Array, Object],
    actionType: String,
    modemID: Array,
    svgSave: Boolean
  },
  data() {
    return {
      svgFiles: [],
      svgFileList: [],
      svgNamespace: "http://www.w3.org/2000/svg",
      tab: null,
      layoutData: {},
      currentLayoutItem: {},

      svgGorunurIsim: "",
      svgEn: "",
      svgBoy: "",
      svgData: "",

      svgWrapperElement: null,
      svgChildElements: [],

      currentMovedSVG: null,

      //drag drop stuff
      impactX: 0,
      impactY: 0,
      offsetX: 0,
      offsetY: 0,
      itemX: 0,
      itemY: 0,
      itemScaleX: 0,
      itemScaleY: 0,

      dragging: false,
      snackbar: false,
      timeout: 10000,

      componentOBJ: { compName: "", compProp: null },

      fileSVGs: {},
      modemSVGRequestMessage: "",
      bannerModel: false
    };
  },
  computed: {
    getSVGWidth: {
      set: function(value) {
        this.layoutData["en"] = value.toString();
      },
      get: function() {
        if (this.layoutData !== null) return parseInt(this.layoutData["en"]);
        else return 800;
      }
    },
    getSVGHeight: {
      set: function(value) {
        this.layoutData["boy"] = value.toString();
      },
      get: function() {
        if (this.layoutData !== null) return parseInt(this.layoutData["boy"]);
        else return 380;
      }
    },
    //Cansu
    getSVGTagName: {
      set: function(value) {
        this.layoutData["etiket"] = value.toString();
      },
      get: function() {
        if (!this.layoutData["etiket"]) {
          console.log("Layout Yok etiket set ediliyor", this.modemID[1]);
          return this.modemID[1];
        } else {
          console.log(
            "Layout VAR etiket set ediliyor",
            this.layoutData["etiket"]
          );
          return this.layoutData["etiket"];
        }
      }
    },
    ...mapGetters({
      getterSocketMessage: "storeSocket/getterSocketMessage",
      getterIsSocketConnected: "storeSocket/getterIsSocketConnected"
    })
  },
  created() {
    if (this.dynamicM[this.fieldItem.Field] === "") {
      this.layoutData = {};
      this.getSVGHeight = 380;
      this.getSVGWidth = 800;
      this.getSVGTagName = this.modemID[1];

      //add empty svg with height & width values.
      this.addSvgBase();
    } else {
      this.layoutData = JSON.parse(
        decodeURIComponent(this.dynamicM[this.fieldItem.Field])
      );
      this.getSVGTagName = this.layoutData["etiket"];
      this.requestModemSVG();
    }

    this.getSvgFileList();
  },
  mounted() {
    console.log("MODEM ID", this.modemID);
  },
  watch: {
    getterSocketMessage: function(value) {
      let command = JSON.parse(value.data).command;
      if (command === "ScadaComponentsResponse") {
        this.svgFileList = JSON.parse(value.data).data;
        console.log("Dosyalar", this.svgFileList);
        this.getSvgFiles(this.svgFileList);
      }
    },
    /*CANSU EKLENDİ */
    layoutData: {
      deep: true,
      handler(value) {
        console.log("gelennnn", value);
        console.log(this.layoutData.comps);
        if (this.layoutData.comps === undefined) {
          console.log("comps boş");
        } else {
          console.log("comps dolu");
          if (this.layoutData.comps.length === 0) {
            this.dynamicM["modem_layout"] = "";
          } else {
            this.dynamicM["modem_layout"] = encodeURIComponent(
              JSON.stringify(value)
            );
          }
        }
      }
    }
  },
  methods: {
    svgKaydet: function() {
      let svgData = document.getElementById("svg_wrapper_id");
      console.log("SVGGG", svgData);

      console.log("svg data", svgData.innerHTML);
      let encoded = encodeURIComponent(svgData.innerHTML.toString()).toString();
      let request = {
        type: "saveSvgFile",
        payload: { Id: parseInt(this.modemID[0]), Data: encoded }
      };

      console.log("Encoded !", request);
      this.sendMessageToWS(request);
    },
    getSvgFiles: function(fileList) {
      console.log("Get Svg Files", fileList);
      fileList.forEach(scadaElement => {
        const { elemanlar } = scadaElement;
        elemanlar.forEach(async item => {
          const { class_name } = item;
          console.log("CLASS NAME", class_name);
          this.fileSVGs[class_name] = await getSvgPreview[class_name]();
        });
      });
    },
    getSvgFileList: function() {
      let request = { type: "getScadaComponents", payload: null };
      this.sendMessageToWS(request);
    },
    requestModemSVG: async function() {
      this.modemSVGRequestMessage = "Modem SVG alınıyor...";
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_MODEM_SVG_URL}modem_${this.modemID[0]}.svg`
        );
        this.svgData = await response.data;
        this.$nextTick(() => {
          if (this.svgData !== "") {
            this.layoutData.comps.forEach(item => {
              document.getElementById(item.id).addEventListener(
                "dblclick",
                function() {
                  this.elementDBLClick(item);
                }.bind(this),
                false
              );
              document.getElementById(item.id).addEventListener(
                "mousedown",
                function(e) {
                  this.elementMouseDown(item, e);
                }.bind(this),
                false
              );
              document
                .getElementById(item.id)
                .addEventListener("mouseup", this.elementMouseUp, false);
            });
          } else {
            //add empty svg with height & width values.
            this.addSvgBase();
          }
        });
      } catch (e) {
        console.log("Error getting modem svg", e);
        this.modemSVGRequestMessage = "Error";
      }
    },
    elementDBLClick: function(item) {
      this.tab = "tab-genel";
      console.log("CLICK", item);
      this.currentLayoutItem = item;
      this.snackbar = true;
      this.bannerModel = true;
    },
    elementMouseDown: function(item, e) {
      //get current item
      this.currentMovedSVG = item.id;

      let currSVG = document.getElementById(this.currentMovedSVG);
      //X Translate Position
      this.itemX = currSVG.getCTM().e;
      //Y Translate Position
      this.itemY = currSVG.getCTM().f;
      //Item scale percantage
      this.itemScaleX = currSVG.getCTM().a;
      this.itemScaleY = currSVG.getCTM().d;

      this.impactX = e.clientX;
      this.impactY = e.clientY;

      document
        .getElementById(this.currentMovedSVG)
        .addEventListener("mousemove", this.elementMouseMove, false);
    },
    elementMouseUp: function() {
      if (this.dragging) {
        this.dragging = false;
        console.log("Drag Event");
      } else {
        console.log("Click Event");
      }
      console.log("MOUSE UP");
      console.log(this.currentMovedSVG);

      document
        .getElementById(this.currentMovedSVG)
        .removeEventListener("mousemove", this.elementMouseMove);

      let currSVG = document.getElementById(this.currentMovedSVG);
      this.itemX = currSVG.getCTM().e;
      this.itemY = currSVG.getCTM().f;
      this.itemScaleX = currSVG.getCTM().a;
      this.itemScaleY = currSVG.getCTM().d;

      this.offsetX = 0;
      this.offsetY = 0;
    },
    elementMouseMove: function(e) {
      this.dragging = true;
      this.offsetX = this.offsetX + e.clientX - this.impactX;
      this.offsetY = this.offsetY + e.clientY - this.impactY;

      let x = this.offsetX + this.itemX;
      let y = this.offsetY + this.itemY;
      //console.log("MOUSE MOVE XY", x, y);
      //console.log("MOUSE MOVE OFFSET", this.offsetX, this.offsetY);
      document
        .getElementById(this.currentMovedSVG)
        .setAttribute(
          "transform",
          "translate(" + x + "," + y + ") scale(" + this.itemScaleX + ")"
        );

      this.impactX = e.clientX;
      this.impactY = e.clientY;
    },
    addSvgBase: function() {
      //Eğer svg dosyası yok ise bir base svg elemanı oluşturur.
      this.svgData =
        '<svg xmlns="http://www.w3.org/2000/svg" width="800" height="380" version="1.1">';
    },
    svgFormSave: function(v) {
      console.log("SAVE CLICKED", v);
      const { el, class_name } = v;
      this.componentOBJ.compProp.dialogModel = false;
      this.componentOBJ.compName = "";
      this.tab = "tab-genel";

      let groupElement = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "g"
      );
      console.log("ilk", groupElement);

      const id = generateRandomKey();
      groupElement.setAttribute("id", id);
      groupElement.setAttribute("class", "bilesen_" + id);
      groupElement.setAttribute("transform", "translate(0,0) scale(0.4)");
      groupElement.innerHTML = el;

      const svgDiv = document.getElementById("svg_wrapper_id");
      const svgWrapper = svgDiv.getElementsByTagName("svg");

      svgWrapper[0].appendChild(groupElement);

      console.log("son", groupElement);

      const itemData = getSvgPropertyData[class_name](id);
      console.log("itemdata", itemData);

      if (this.layoutData["comps"] === undefined) {
        this.layoutData = {
          en: this.getSVGWidth,
          boy: this.getSVGHeight,
          etiket: this.getSVGTagName,
          comps: []
        };
      }
      console.log(this.layoutData);

      this.layoutData["comps"].push(itemData);

      groupElement.addEventListener(
        "dblclick",
        function() {
          this.elementDBLClick(itemData);
        }.bind(this),
        false
      );

      groupElement.addEventListener(
        "mousedown",
        function(e) {
          this.elementMouseDown(itemData, e);
        }.bind(this),
        false
      );

      groupElement.addEventListener("mouseup", this.elementMouseUp, false);
    },
    svgFormCancel: function() {
      console.log("SAVE CANCELED");
      this.componentOBJ.compProp.dialogModel = false;
      this.componentOBJ.compName = "";
    },
    svgAddPreview: function(el, class_name) {
      this.componentOBJ.compName = "custom-dialog-alert";
      this.componentOBJ.compProp = constructAlertDialog(
        "SVG Ekleme İşlemi",
        "Bu elemanı eklemek istediğinize emin misiniz ?",
        "Evet, eminim !",
        "İptal"
      );
      this.componentOBJ.compProp["el"] = el;
      this.componentOBJ.compProp["class_name"] = class_name;
      this.componentOBJ.compProp["from"] = "ModemLayoutWizard";
    },
    changeBaseSVGDimensions: function() {
      //base wrapper svg yükseklik ve genişlik değiştirir.
      let svgBaseDiv = document.getElementById("svg_wrapper_id");
      let svgBase = svgBaseDiv.getElementsByTagName("svg");
      let height = this.getSVGHeight;
      let width = this.getSVGWidth;
      svgBase[0].setAttribute("height", height);
      svgBase[0].setAttribute("width", width);
    },
    finishEditingSVG: function() {
      this.bannerModel = false;
      this.snackbar = false;
      this.currentLayoutItem = {};
    },
    removeChildSVG: function() {
      console.log("Current SVG Item", this.currentLayoutItem);
      const { id } = this.currentLayoutItem;
      let svgBase = document.getElementById("svg_wrapper_id");
      let childSVG = svgBase.getElementsByTagName("svg");
      let itemToRemove = childSVG[0].getElementById(id);
      console.log(itemToRemove);
      childSVG[0].removeChild(itemToRemove);

      const index = this.layoutData.comps
        .map(x => {
          return x.id;
        })
        .indexOf(id);
      console.log("DATA TO REMOVE", this.layoutData.comps[index]);
      this.layoutData.comps.splice(index, 1);
      this.finishEditingSVG();
    }
  }
};
</script>

<style scoped>
.svg_wrapper {
  overflow: scroll !important;
}

.svg_preview_card {
  transition: 0.2s ease !important;
}

.svg_preview_card:hover {
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
  background-color: #f5f5f5 !important;
  transition: 0.2s ease;
}

.nonePointer {
  pointer-events: none !important;
}

.go_right {
  transform: translate(0, 0);
  transition: 0.2s ease;
}

#heightAdjuster {
  position: fixed;
  height: 20px;
  width: 20px;
  background: #3f51b5;
  bottom: 0;
  right: 0;
}

.svg_preview_card >>> svg {
  height: fit-content !important;
  width: 100% !important;
}
</style>
