<template>
  <div>
    <modem-dynamic-grid
        v-if="isDataAvailable"
        :selected-modems-layout="modemLayout"
    />
  </div>
</template>

<script>
import ModemDynamicGridComponent from "../components/GridComponent/ModemGridNew/ModemDynamicGridComponent";
import store from "@/vuex_store/store_wrapper";

export default {
  name: "Stations",
  components: {
    "modem-dynamic-grid": ModemDynamicGridComponent
  },
  props: {
    selectedModemsLayout: {
      type: Object,
      required: true
    },
    allModemGroups: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isWizardOpen: false,
      isDataAvailable: false,
      show_list_box: false,
      modemLayout: [],
      modemGroupData: [],
      modemUpdateTime: {},

      lastUpdate: null,
      customInterval: null,
      unsubscribe: null,
    };
  },
  computed: {
    getModemsLayout: function() {
      return this.selectedModemsLayout;
    },
    getModemGroups: function() {
      return this.allModemGroups;
    },
  },
  beforeDestroy() {
    // Need to unsubscribe from store mutations.
    this.unsubscribe && this.unsubscribe();
  },
  /*eslint-disable*/
  beforeRouteEnter: function(to, from, next) {
    console.log("Route Enter Before Stations");
    next(vm => {
      vm.sendRequest();
      console.log("Route Enter Before Stations next");
    });
  },
  created() {
    /*
    * Socket store mutation that we receive when on_socket_message mutation is applied.
    * */
    this.unsubscribe = store.subscribe(mutation => {
      const {type,payload} = mutation;
      if (type === 'storeSocket/on_socket_message') {
        const { command,layout,data } = JSON.parse(payload.data);
        if (command === 'ListOfModemLayouts') {
          this.isDataAvailable = true;
          layout !== null ? (this.modemLayout = layout) : (this.modemLayout = []);
        }else if (command === 'ModemUpdateTime' || command === 'ProcessData') {
          this.modemUpdateTime = data;
        }
      }
    });
  },
  methods: {
    /*
    * Send Modem Layout request for any preselected stations.
    * */
    sendRequest: function() {
      this.sendMessageToWS(this.getModemsLayout);
    }
  }
};
</script>

<style scoped></style>
