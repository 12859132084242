<template>
  <div>
    <v-dialog persistent max-width="300px" v-model="dialogTableModel">
      <v-card>
        <v-card-title>
          {{ dialogCardTitle }}
        </v-card-title>
        <v-card-text>
          {{ dialogCardText }}
        </v-card-text>
        <v-card-actions>
          <v-spacer> </v-spacer>
          <v-btn text class="green--text accent-4" @click="dialogOkEvent"
          >Evet</v-btn
          >
          <v-btn
              text
              class="red--text accent-4"
              @click="dialogTableModel = false"
          >İptal</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
        v-model="snackbarProps['model']"
        :color="snackbarProps['color']"
        :timeout="snackbarProps['duration']"
    >
      {{ snackbarProps["message"] }}
    </v-snackbar>
    <basic-dialog
        @dialogDismissed="basicDialogDismissed"
        :dialog-object="basicDialogObject"
    />
    <linked-list-popup
        v-if="showLinkedListPopup"
        :dialog-vis="showLinkedListPopup"
        :request="modalRequestProp"
        :source-table-name="getSourceTable"
        :master-table-row-id="idFilter"
        :master-table-name="getMasterId"
        @close-linked-dialog="closeDialogPopup"
    />
    <v-card class="my-4">
      <v-skeleton-loader
          v-if="!tableDataAvailable"
          v-bind="attrs"
          type="table"
          :loading="false"
      ></v-skeleton-loader>
      <template v-else>
        <!-- Linked Table App Bar -->
        <v-app-bar
            v-if="Object.keys(tableToolbarData).length > 0"
            height="auto"
            :extended="false"
            class="indigo darken-3"
        >
          <v-col class="col-12">
            <v-row no-gutters class="align-self-center justify-space-between">
              <div class="d-flex align-self-center">
                <v-toolbar-title class="pl-2 white--text">
                  <v-icon color="white">link</v-icon>
                  {{ getTableTitle }}</v-toolbar-title
                >
              </div>
              <v-spacer></v-spacer>
              <div class="d-flex align-self-center">
                <filter-menu-component
                    :custom-data="fieldItem['CustomData']"
                    :filter-data="getFilters"
                    :filter-event-name="fieldItem['CustomData']['TargetId']"
                    :default-filter="getDefaultFilter"
                    :id-filter="idFilter"
                    :badge-count="newFilter.length"
                    v-on="{
                    [fieldItem['CustomData']['TargetId']]: filterRequest
                  }"
                />
              </div>

              <div class="align-self-center">
                <toolbar-buttons-component
                    :button-props="getToolbar['Elements']"
                    :is-speed-dial="$vuetify.breakpoint.name !== 'lg'"
                    event-name="toolbarEventLL"
                    @toolbarEventLL="toolbarButtonClick"
                />
              </div>
            </v-row>
            <v-row no-gutters>
              <pagination-component
                  :page-number="pageNumber"
                  :total-page="computeTotalPage"
                  :event-names="[
                  'perPageLL',
                  'toFirstLL',
                  'toPrevLL',
                  'toNextLL',
                  'toLastLL'
                ]"
                  @perPageLL="perPageLL"
                  @toFirstLL="goToFirstPage"
                  @toPrevLL="goToPreviousPage"
                  @toNextLL="goToNextPage"
                  @toLastLL="goToLastPage"
              />
            </v-row>
            <v-row no-gutters class="pt-4">
              <span class="white--text"
              >Toplam {{ getTableRowCount }} kayıt bulundu
              </span>
            </v-row>
          </v-col>
        </v-app-bar>

        <chip-component
            v-if="newFilter.length > 0"
            :filters="newFilter"
            :filter-fields="getFilters"
            :chip-events="[eventNameRemoveFilter, eventNameRemoveAllFilters]"
            v-on="{
            [eventNameRemoveFilter]: removeFilter,
            [eventNameRemoveAllFilters]: removeAllFilters
          }"
        />

        <v-divider style="background: #3f51b5 !important;"></v-divider>

        <v-col
            class="simple_table_wrapper"
            sm="auto"
            md="auto"
            lg="auto"
            xl="auto"
        >
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th
                    v-for="(column, colIndex) in getColumns"
                    :key="column['Field']"
                    class="text-left"
                >
                  {{ column["Label"] }}
                  <slot>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                            v-on="on"
                            @click="sortTable(sortingProps[colIndex], colIndex)"
                            icon
                            color="black"
                            right
                        >
                          <v-icon>{{
                              sortingProps[colIndex]["Icon"]
                            }}</v-icon>
                        </v-btn>
                      </template>
                      <span>{{
                          column["Label"] +
                          " " +
                          sortingProps[colIndex]["Tooltip"]
                        }}</span>
                    </v-tooltip>
                  </slot>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(row, rowIndex) in getTableRows"
                  :key="rowIndex"
                  style="cursor:pointer;"
                  @click="clickRowEvent(row)"
                  @dblclick="dblClickRowEvent(row)"
              >
                <td v-for="(value, valueIndex) in row" :key="valueIndex">
                  <slot
                      v-if="getColumns[valueIndex]['ColumnType'] === 'checkbox'"
                  >
                    <v-checkbox
                        :value="row"
                        v-model="tableRowModel"
                        :checked="tableRowModel.includes(row)"
                        class="mx-2"
                    ></v-checkbox>
                  </slot>
                  <slot
                      v-else-if="
                        getColumns[valueIndex]['ColumnType'] === 'radio'
                      "
                  >
                    <v-radio-group multiple v-model="tableRowModel">
                      <v-radio class="mx-2" :value="row"></v-radio>
                    </v-radio-group>
                  </slot>
                  <slot v-else>
                    <span>{{ value }}</span>
                  </slot>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-col class="col-12">
          <v-row no-gutters>
            <v-slider
                hide-details
                class="ml-12 mr-12"
                v-model="sliderValue"
                ticks
                :max.sync="sliderTotal"
                min="1"
                thumb-label
                tick-size="8"
                @click="sliderClickEvent"
            ></v-slider>
          </v-row>
        </v-col>
      </template>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BasicDialogComponent from "@/components/DialogComponent/BasicDialogComponent";
import LinkedListPopupModal from "@/components/LinkedList/LinkedListPopupModal";
import ToolbarButtonsComponent from "@/components/AppbarUtilityComponents/ToolbarButtonsComponent";
import PaginationComponent from "@/components/AppbarUtilityComponents/PaginationComponent";
import FilterMenuComponent from "@/components/AppbarUtilityComponents/FilterMenuComponent";
import ChipComponent from "@/components/AppbarUtilityComponents/ChipComponent";

export default {
  name: "LinkedListTableComponent",
  components: {
    "basic-dialog": BasicDialogComponent,
    "linked-list-popup": LinkedListPopupModal,
    PaginationComponent,
    ToolbarButtonsComponent,
    FilterMenuComponent,
    ChipComponent
  },
  props: {
    fieldItem: {
      type: Object,
      required: false
    },
    masterTableSelectedRow: {
      type: Array,
      required: false
    },
    idFilter: {
      type: [Number, String],
      required: false
    }
  },
  data() {
    return {
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 2
      },
      tableDataAvailable: false,
      tableRequest: {},
      fab: false,
      tableRowData: {},
      tableToolbarData: {},
      paginationButtonStates: {
        firstDisabled: true,
        previousDisabled: true,
        lastDisabled: false,
        nextDisabled: false
      },
      pageLimit: 10,
      pageNumber: 1,
      tableRowModel: [],

      basicDialogObject: {
        show: false,
        message: "İşlem yapmak için yalnız bir seçim yapmalısınız."
      },

      showLinkedListPopup: false,
      modalRequestProp: {},

      snackbarProps: {
        model: false,
        message: "",
        color: "",
        duration: 4000
      },
      dialogTableModel: false,
      dialogCardTitle: "",
      dialogCardText: "",

      sliderValue: 1,
      sliderTotal: 1,

      unsubscribe: null,

      defaultTableFilterObject: {},

      sortingProps: [],
      newFilter: []
    };
  },
  computed: {
    eventNameRemoveFilter: function() {
      return `${this.getTargetId}RemoveFilter`;
    },
    eventNameRemoveAllFilters: function() {
      return `${this.getTargetId}RemoveAllFilters`;
    },
    getLinkedTableName: function() {
      return this.fieldItem["Field"];
    },
    getLinkedTableLabel: function() {
      return this.fieldItem["Label"];
    },
    getMasterId: function() {
      return this.fieldItem["CustomData"]["MasterId"];
    },
    getMasterTable: function() {
      return this.fieldItem["CustomData"]["MasterTable"];
    },
    getSourceTable: function() {
      return this.fieldItem["CustomData"]["SourceTable"];
    },
    getTargetId: function() {
      return this.fieldItem["CustomData"]["TargetId"];
    },
    getDefaultFilter: function() {
      return this.fieldItem["CustomData"]["DefaultFilter"];
    },
    getTableType: function() {
      return this.fieldItem["CustomData"]["Type"];
    },
    /*Table Toolbar Data*/
    getTableTitle: function() {
      return this.tableToolbarData["title"];
    },
    getTableIcon: function() {
      return this.tableToolbarData["icon"];
    },
    getToolbar: function() {
      return this.tableToolbarData["toolbar"];
    },
    getColumns: function() {
      return this.tableToolbarData["columns"];
    },
    getFilters: function() {
      return this.tableToolbarData["filters"];
    },
    /*Table Toolbar Data*/

    /* Table Row Data */
    getTableRowCount: function() {
      return this.tableRowData["RowCount"];
    },
    getTableRows: function() {
      return this.tableRowData["Rows"];
    },
    /* Table Row Data */

    computeTotalPage: function() {
      return this.tableRowData["RowCount"] !== 0
          ? Math.ceil(this.tableRowData["RowCount"] / this.pageLimit)
          : 0;
    },
    getSelectedLinkedId: function() {
      return Number(this.tableRowModel[0][0]);
    },
    /*Table WS Request (Pagination, Filter etc...)*/

    /*Table WS Request (Pagination, Filter etc...)*/
    ...mapGetters({
      getterSocketMessage: "storeSocket/getterSocketMessage"
    })
  },
  watch: {
    getterSocketMessage: function(response) {
      const { command, message, layout, data } = JSON.parse(response.data);

      if (command === "LinkedListResponse") {
        const { data: layout_data, target } = layout;
        const { Target } = data;

        //if sourcetablename === target
        if (
            this.getSourceTable === target &&
            Target === this.getLinkedTableName
        ) {
          console.log("Response From Linked List", message);
          console.log("Response From Linked List", layout_data);
          console.log("Response From Linked List", data);
          this.tableDataAvailable = true;

          this.tableRowData = data;
          this.tableToolbarData = layout_data;
          this.sortingProps = layout_data["columns"].map(col => {
            col["Dir"] = "Asc";
            col["Icon"] = "keyboard_arrow_up";
            col["Tooltip"] = "satırını artan şekilde sırala";
            return col;
          });
        }
      } else if (
          /*Table Limit, Pagination etc...*/
          command === "TableData" &&
          this.getSourceTable === layout["target"]
      ) {
        console.log("Table Data Linked List", layout, data);
        this.tableRowModel.splice(0, this.tableRowModel.length);
        this.tableRowData = data;
        this.tableToolbarData = layout["data"];
      } else if (command === "FormCommandResponse") {
        const { type, text } = message;
        if (type === "Success") {
          if (data["TargetID"] === this.getSourceTable) {
            this.snackbarProps.message = text;
            this.snackbarProps.color = "#41e06e";
            this.snackbarProps.model = true;
            this.dialogTableModel = false;
            this.showLinkedListPopup = false;
            //Send Update Table Request using TargetID
            this.tableRequest["payload"]["Data"]["TargetId"] = data["TargetID"];
            this.tableRequest["payload"]["Data"]["Page"] = 1;
            this.tableRequest["payload"]["Data"]["Limit"] = 10;
            this.pageNumber = 1;
            this.pageLimit = 10;
            this.sendMessageToWS(this.tableRequest);
          }
        } else if (type === "Error") {
          this.snackbarProps.message = text;
          this.snackbarProps.color = "#e33b51";
          this.snackbarProps.model = true;
          this.dialogTableModel = false;
        }
      }
    },
    computeTotalPage: function(pageNumber) {
      this.pageNumber = 1;
      this.sliderTotal = pageNumber;
      console.log("Page Number", pageNumber);
    },
    pageNumber: function(number) {
      console.log("Page Num", number);
      this.sliderValue = number;
      this.tableRequest["payload"]["Data"]["Page"] = number;
      this.sendMessageToWS(this.tableRequest);
    }
  },
  beforeDestroy() {
    //this.unsubscribe && this.unsubscribe();
  },
  created() {
    this.defaultTableFilterObject = {
      Field: this.getMasterId,
      Data: [this.idFilter]
    };
    //this.newFilter = [this.defaultTableFilterObject];
    //Table Pagination/Filter request.
    this.tableRequest = {
      type: "object",
      payload: {
        Target: this.getSourceTable,
        Action: "listele",
        Data: {
          Onlydata: false,
          TargetId: this.getSourceTable,
          Limit: this.pageLimit,
          Page: this.pageNumber,
          Filters: [this.defaultTableFilterObject]
        }
      }
    };
  },
  mounted() {
    this.$nextTick(() => {
      // Code that will run only after the
      // entire view has been rendered
      //send table requests.
      setTimeout(() => {
        const TABLE_REQUEST = {
          type: "getLinkedList",
          payload: {
            ReqData: this.idFilter,
            Target: this.getLinkedTableName,
            Config: {
              MasterTable: this.getMasterTable,
              SourceTable: this.getSourceTable,
              MasterId: this.getMasterId,
              TargetId: this.getTargetId,
              DefaultFilter: this.getDefaultFilter,
              Type: this.getTableType
            }
          }
        };
        console.log("Linked Table Request", TABLE_REQUEST);
        this.sendMessageToWS(TABLE_REQUEST);
      }, 150);
    });
  },
  methods: {
    toolbarButtonClick: function(data) {
      const { Task, Need_validation } = data;
      console.log("Utility Button Clicked", Task, Need_validation, data);
      if (Need_validation) {
        if (this.tableRowModel.length > 0) {
          if (Task === "sil") {
            this.deleteEntry();
          } else {
            this.modalRequestProp = {
              type: "object",
              payload: {
                Target: this.getSourceTable,
                Action: Task,
                Data: {
                  Id: this.getSelectedLinkedId,
                  TargetId: this.getSourceTable
                }
              }
            };
            this.showLinkedListPopup = true;
            console.log("Edit Or Inspect", this.modalRequestProp);
          }
        } else {
          // NO Row is selected show popup.
          this.basicDialogObject.show = true;
          console.log("No Row Selected");
        }
      } else {
        //yeni Task doesn't need validation
        this.showLinkedListPopup = true;
        this.modalRequestProp = {
          type: "object",
          payload: {
            Target: this.getSourceTable,
            Action: "yeni",
            Data: {
              MasterId: this.getMasterId,
              TargetId: this.getSourceTable
            }
          }
        };
      }
    },
    deleteEntry: function() {
      this.dialogTableModel = true;
      this.dialogCardTitle = "Silme işlemi?";
      this.dialogCardText = "Silme işlemi yapmak istediğinize emin misiniz?";
    },
    goToFirstPage: function() {
      this.pageNumber = 1;
    },
    goToPreviousPage: function() {
      this.pageNumber = this.pageNumber - 1;
    },
    goToNextPage: function() {
      this.pageNumber = this.pageNumber + 1;
    },
    goToLastPage: function() {
      this.pageNumber = this.computeTotalPage;
    },
    perPageLL: function(limit) {
      //this.tableRequest["payload"]["Data"]["Limit"] = limit;
      console.log("Limit changed For Linked List", limit);
      //this.sendMessageToWS(this.tableRequest);
    },
    clickRowEvent: function(row) {
      console.log("Row", row);
      if (this.tableRowModel.length > 0) {
        this.tableRowModel.splice(0, this.tableRowModel.length);
      }
      this.tableRowModel.push(row);
    },
    dblClickRowEvent: function(row) {
      //If edit task exists in linked list toolbar then continue.
      const isEditExists = this.getToolbar["Elements"].some(
          el => el["Task"] === "duzenle"
      );

      if (isEditExists) {
        const dataId = row[0];
        this.modalRequestProp = {
          type: "object",
          payload: {
            Target: this.getSourceTable,
            Action: "duzenle",
            Data: {
              Id: Number(dataId),
              TargetId: this.getSourceTable
            }
          }
        };
        this.showLinkedListPopup = true;
      }
    },
    sortTable: function(col) {
      console.log("Clicked Col", col);
      let { Dir, Field } = col;
      this.tableRequest["payload"]["Data"]["OrderBy"] = Field;
      this.tableRequest["payload"]["Data"]["Dir"] = Dir;
      if (Dir === "Asc") {
        console.log("Is Asc");
        col["Dir"] = "Desc";
        col["Icon"] = "keyboard_arrow_down";
        col["Tooltip"] = "satırını azalan şekilde sırala";
      } else if (Dir === "Desc") {
        col["Dir"] = "Asc";
        col["Icon"] = "keyboard_arrow_up";
        col["Tooltip"] = "satırını artan şekilde sırala";
      }
      this.sendMessageToWS(this.tableRequest);
    },
    sliderClickEvent: function() {
      this.pageNumber = this.sliderValue;
    },
    basicDialogDismissed: function(value) {
      this.basicDialogObject.show = value;
    },
    closeDialogPopup: function(prop) {
      this.showLinkedListPopup = false;
      console.log("Some Prop", prop);
    },
    dialogOkEvent: function() {
      //get Row Id and delete.
      const request = {
        type: "object",
        payload: {
          Target: this.getSourceTable,
          Action: "sil",
          Data: {
            Id: [this.getSelectedLinkedId],
            TargetId: this.getSourceTable
          }
        }
      };
      console.log("Delete Request", request);
      this.sendMessageToWS(request);
    },
    filterRequest: function(filters) {
      console.log("Filter Request Received for", filters);
      this.newFilter = filters;
      let newFilter = [this.defaultTableFilterObject];
      newFilter = [...newFilter, ...filters];
      this.tableRequest["payload"]["Data"]["Filters"] = newFilter;
      console.log("UPDATED REQUEST RECEIVED", newFilter);
      this.sendMessageToWS(this.tableRequest);
    },
    removeFilter: function(attrs) {
      console.log("Remove Filters Linked", attrs);
      let { chip, i } = attrs;
      console.log("Chip Remove Event Before", chip);
      chip["Data"].splice(i, 1);
      console.log("Chip Remove Event After", chip);
      if (chip["Data"].length === 0) {
        this.tableRequest["payload"]["Data"]["Filters"] = this.tableRequest[
            "payload"
            ]["Data"]["Filters"].filter(f => {
          if (f["Field"] !== chip["Field"]) {
            return f;
          }
        });
      } else {
        this.tableRequest["payload"]["Data"]["Filters"].forEach(m => {
          if (m["Field"] === chip["Field"]) {
            m["Data"] = chip["Data"];
          }
        });
      }
      this.newFilter = this.tableRequest["payload"]["Data"]["Filters"].filter(
          f => {
            if (f["Field"] !== this.getDefaultFilter) {
              return f;
            }
          }
      );
      console.log("Filters", this.tableRequest);
      this.sendMessageToWS(this.tableRequest);
    },
    removeAllFilters: function() {
      console.log("Remove All Filters Linked");
      console.log("Default Filter", this.defaultTableFilterObject);
      this.tableRequest["payload"]["Data"]["Filters"] = [
        this.defaultTableFilterObject
      ];
      this.newFilter = [];
      this.sendMessageToWS(this.tableRequest);
    }
  }
};
</script>

<style scoped>
.back_button_class.material-icons.icon:before,
.back_button_class.mi.icon:before {
  content: "\e14a";
}

.edit_button_class.material-icons.icon:before,
.edit_button_class.mi.icon:before {
  content: "\e3c9";
}

.delete_button_class.material-icons.icon:before,
.delete_button_class.mi.icon:before {
  content: "\e872";
}

.view_button_class.material-icons.icon:before,
.view_button_class.mi.icon:before {
  content: "\e8b6";
}

.new_button_class.material-icons.icon:before,
.new_button_class.mi.icon:before {
  content: "\e145";
}
</style>
