<template>
  <div>
    <v-dialog
        class="red"
        v-model="dialogObject.show"
        width="500"
        @click:outside="$emit('dialogDismissed')"
    >
      <v-flex>
        <v-card>
          <v-alert text icon="error" prominent class="mb-0" type="error">
            <v-col>
              <v-row>{{ dialogObject.message }}</v-row>
            </v-col>
          </v-alert>
        </v-card>
      </v-flex>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "BasicDialogComponent",
  props: {
    dialogObject: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialog: false
    };
  }
};
</script>

<style scoped></style>
