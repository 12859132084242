var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('grid-layout',{attrs:{"layout":_vm.getListSetupFields,"col-num":Number(_vm.getCols),"auto-size":true,"use-css-transforms":true,"row-height":24,"margin":[8, 8]}},_vm._l((_vm.getListSetupFields),function(item){return _c('grid-item',{key:item.id,attrs:{"x":item.x,"y":item.y,"w":item.w,"h":item.h,"maxH":item['input_ui']==='3' ? 12 : item.h,"i":item.i,"is-resizable":item['input_ui']==='3',"is-draggable":item['input_ui']==='3',"id":item.id}},[_c('v-card',{staticClass:"align-center",attrs:{"outlined":"","height":"100%"}},[(item['tip'] === '1')?_c('v-col',{staticClass:"col-12"},[_c('input-indicator',{attrs:{"indicator-data":item,"input-data":_vm.dataPropComputed[((item['tip']) + "_" + (item['master_id']))]}})],1):_vm._e(),(item['tip'] === '2' && item['birim'] === '0')?_c('v-col',{staticClass:"col-12"},[_c('slide-switch',{attrs:{"switch-data":item,"output-data":_vm.dataPropComputed[((item['tip']) + "_" + (item['master_id']))],"modem-id":_vm.getDataProperty['Id']}})],1):_vm._e(),(item['tip'] === '2' && item['birim'] !== '0')?_c('v-col',{staticClass:"col-12"},[_c('push-button',{attrs:{"modem-id":_vm.getDataProperty['Id'],"pb-data":item}})],1):_vm._e(),(item['tip'] === '3')?_c('v-col',{staticClass:"col-12"},[_c('input-value-indicator',{attrs:{"indicator-data":item,"input-data":isNaN(_vm.dataPropComputed[((item['tip']) + "_" + (item['master_id']))])
                ? _vm.dataPropComputed[((item['tip']) + "_" + (item['master_id']))]
                : Number(
                    _vm.dataPropComputed[((item['tip']) + "_" + (item['master_id']))]
                  ).toFixed(3)}})],1):_vm._e(),(item['tip'] === '4')?_c('v-col',{staticClass:"col-12"},[_c('input-value-indicator',{attrs:{"indicator-data":item,"input-data":isNaN(_vm.dataPropComputed[((item['tip']) + "_" + (item['master_id']))])
                ? _vm.dataPropComputed[((item['tip']) + "_" + (item['master_id']))]
                : Number(
                    _vm.dataPropComputed[((item['tip']) + "_" + (item['master_id']))]
                  ).toFixed(3)}})],1):_vm._e(),(item['tip'] === '8' && item['birim'] === '')?_c('v-col',{staticClass:"col-12"},[(item['input_ui'] === '3')?_c('modbus-item',{attrs:{"input-data":_vm.dataPropComputed[((item['tip']) + "_" + (item['master_id']) + "_")],"indicator-data":item}}):(
              _vm.dataPropComputed.hasOwnProperty(
                ((item['tip']) + "_" + (item['master_id']) + "_")
              )
            )?_c('input-value-indicator',{attrs:{"indicator-data":item,"input-data":isNaN(_vm.dataPropComputed[((item['tip']) + "_" + (item['master_id']) + "_")])
                ? _vm.dataPropComputed[((item['tip']) + "_" + (item['master_id']) + "_")]
                : Number(
                    _vm.dataPropComputed[((item['tip']) + "_" + (item['master_id']) + "_")]
                  ).toFixed(3)}}):(
              _vm.dataPropComputed.hasOwnProperty(
                ((item['tip']) + "_" + (item['master_id']) + "_" + (item['birim']))
              )
            )?_c('input-value-indicator',{attrs:{"indicator-data":item,"input-data":isNaN(
                _vm.dataPropComputed[
                  ((item['tip']) + "_" + (item['master_id']) + "_" + (item['birim']))
                ]
              )
                ? _vm.dataPropComputed[
                    ((item['tip']) + "_" + (item['master_id']) + "_" + (item['birim']))
                  ]
                : Number(
                    _vm.dataPropComputed[
                      ((item['tip']) + "_" + (item['master_id']) + "_" + (item['birim']))
                    ]
                  ).toFixed(3)}}):_vm._e()],1):_vm._e(),(item['tip'] === '8' && item['birim'] !== '')?_c('v-col',{staticClass:"col-12"},[_c('input-value-indicator',{attrs:{"indicator-data":item,"input-data":isNaN(
                _vm.dataPropComputed[
                  ((item['tip']) + "_" + (item['master_id']) + "_" + (item['birim']))
                ]
              )
                ? _vm.dataPropComputed[
                    ((item['tip']) + "_" + (item['master_id']) + "_" + (item['birim']))
                  ]
                : Number(
                    _vm.dataPropComputed[
                      ((item['tip']) + "_" + (item['master_id']) + "_" + (item['birim']))
                    ]
                  ).toFixed(3)}})],1):_vm._e()],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }