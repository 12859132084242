<template>
    <v-footer color="footerBackground" absolute class="font-weight-medium">
        <v-flex class="white--text" text-center xs12>
            {{ footer_date }} —
            <strong>{{ footer_info }}</strong>
        </v-flex>
    </v-footer>
</template>

<script>
    export default {
        name: "LoginFooter",
        data(){
            return {
                footer_date: new Date().getFullYear(),
                footer_info: "Telix Telemetry Systems",
            }
        }
    }
</script>

<style scoped>

</style>
