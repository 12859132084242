<template>
  <v-card elevation="0">
    <v-snackbar
        class="datetime_snackbar"
        :timeout="snackbar_timeout"
        v-model="snackbar"
        color="error"
    >
      {{ snackbar_text }}
      <v-btn color="white" text @click="snackbar = false">
        Kapat
      </v-btn>
    </v-snackbar>
    <v-row no-gutters>
      <!-- TAB -->
      <v-tabs
          v-model="tab"
          background-color="blue darken-2"
          centered
          dark
          grow
          icons-and-text
      >
        <v-tabs-slider></v-tabs-slider>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-tab class="text-capitalize" href="#date_tab" v-on="on">
              Tarih seçimi
              <v-icon>date_range</v-icon>
            </v-tab>
          </template>
          <span>Tarih seçimi</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-tab
                class="text-capitalize"
                v-if="getPickerObject.isTimeActive"
                href="#time_tab"
                v-on="on"
            >
              Zaman seçimi
              <v-icon>access_time</v-icon>
            </v-tab>
          </template>
          <span>Zaman seçimi</span>
        </v-tooltip>
      </v-tabs>
    </v-row>

    <v-row no-gutters>
      <v-tabs-items v-model="tab" style="width: 100% !important;">
        <v-tab-item value="date_tab">
          <v-date-picker
              @click:date="dateClick"
              v-model="dateData"
              scrollable
              locale="tr-tr"
          >
            <v-spacer></v-spacer>
          </v-date-picker>
        </v-tab-item>

        <v-tab-item v-if="getPickerObject.isTimeActive" value="time_tab">
          <v-time-picker
              v-model="timeData"
              :use-seconds="true"
              format="24hr"
          ></v-time-picker>
        </v-tab-item>
      </v-tabs-items>
    </v-row>

    <v-row no-gutters>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="closeDialog">Kapat</v-btn>
      <v-btn text color="primary" @click="saveDate">Seç</v-btn>
    </v-row>
  </v-card>
</template>

<script>

export default {
  name: "CustomDateTimePickerComponent",
  data() {
    return {
      tab: null,
      dateData: new Date().toISOString().substr(0, 10),
      timeData: "10:00:00",
      snackbar: false,
      snackbar_text: "",
      snackbar_timeout: 3000
    };
  },
  props: {
    pickerObject: {
      type: Object,
      required: true
    }
  },
  computed: {
    defTime: function() {
      let hours = String(new Date().getHours());
      let min =
          String(new Date().getMinutes()).length === 1
              ? String(new Date().getMinutes()).concat("0")
              : String(new Date().getMinutes());
      let sec =
          String(new Date().getSeconds()).length === 1
              ? String(new Date().getSeconds()).concat("0")
              : String(new Date().getSeconds());

      return `${hours}:${min}:${sec}`;
    },
    getPickerObject: function() {
      return this.pickerObject;
    }
  },
  methods: {
    dateClick: function() {
      this.tab = "time_tab";
    },
    saveDate: function() {
      console.log(this.dateData);
      console.log(this.timeData);

      if (this.dateData !== null && this.timeData !== null) {
        let formatted = "";

        if (this.getPickerObject.EventName === "AlarmRaporEvent") {
          formatted = new Date(this.dateData).toISOString().slice(0, 10);
        } else if (this.getPickerObject.EventName === "tarihEvent") {
          formatted = new Date(this.dateData).toISOString().slice(0, 10);
        } else {
          formatted = new Date(this.dateData)
              .toLocaleDateString("tr-TR")
              .replace(/\./g, "-");
        }

        this.$emit(this.getPickerObject.EventName, {
          date: formatted.concat(" " + this.timeData),
          Field: this.getPickerObject.Field,
          DialogModel: this.getPickerObject.DialogModel
        });
      } else if (
          this.timeData === null &&
          this.getPickerObject.isTimeActive === true
      ) {
        console.log("Zaman seçimi yapmadınız.");
        this.snackbar_text = "Zaman seçimi yapınız.";
        this.snackbar = true;
      }
    },
    closeDialog: function() {
      console.log("Close Event", this.getPickerObject.Field);
      this.$emit('closeDatePickerEvent', this.getPickerObject.Field);
    }
  }
};
</script>

<style>
.v-picker.v-card {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  box-shadow: none !important;
}

@media only screen and (min-width: 600px) {
  .datetime_snackbar .v-snack__wrapper {
    min-width: 100% !important;
  }
}
</style>
