//storeDistributeSvgData

const state = {
  Datas: {},
  binaryData: []
};

const getters = {
  getBinaryData: state => {
    return state.binaryData;
  },
  getDatas: state => {
    return state.Datas;
  },

  getDatasFromLocalStorage: () => {
    return localStorage.getItem("DATAS_UPDATED")
  }
};

const mutations = {
  setBinaryData: (state,payload) => {
    state.binaryData = payload;
  },
  /*
  * Set Datas object updates
  * */
  setDatasUpdate: (state,Datas) => {
    state.Datas = Datas;
  }
};

const actions = {
  actionSetBinaryData({commit},payload) {
    commit("setBinaryData",payload);
  },

  actionDatasUpdate({commit},payload) {
    localStorage.setItem("DATAS_UPDATED",JSON.stringify(payload));
    commit("setDatasUpdate",payload);
  },

  actionDatasClear({commit}) {
    localStorage.removeItem("DATAS_UPDATED");
    commit("setDatasUpdate",{});
  }

};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
