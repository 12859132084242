<template>
  <v-row no-gutters>
    <v-col>
      <v-autocomplete
          v-if="actionType === 'duzenle' || actionType === 'incele'"
          :label="field['Label']"
          :items="interactiveListItems"
          item-value="Id"
          item-text="Text"
          :value="Number(model[field.Field])"
          @change="setValue(field, $event)"
          :rules="setRule(field, model[field.Field])"
          :readonly="actionType === 'incele'"
      />
      <v-autocomplete
          v-else
          :label="field['Label']"
          :items="interactiveListItems"
          item-value="Id"
          item-text="Text"
          v-model="model[field.Field]"
          :rules="setRule(field, model[field.Field])"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { setRuleData } from "@/form_stuff/set_form_rules";

export default {
  name: "InteractiveListComponent",
  props: {
    field: {
      type: Object,
      required: false
    },
    model: {
      type: Object,
      required: false
    },
    /*interactive parent value*/
    interactiveTargetValue: {
      type: Number,
      required: false,
      default: 0
    },
    actionType: {
      type: String,
      required: false,
      default: () => ""
    }
  },
  data() {
    return {
      interactiveListItems: []
    };
  },
  computed: {
    ...mapGetters({
      getterSocketMessage: "storeSocket/getterSocketMessage"
    })
  },
  created() {
    if (this.actionType === "duzenle" || this.actionType === "incele") {
      this.getInteractiveList();
    }
  },
  watch: {
    interactiveTargetValue: function(value) {
      console.log("Interactive Element selected", value);
      this.getInteractiveList();
    },
    getterSocketMessage: function(value) {
      const { command, data } = JSON.parse(value.data);
      if (command === "InteractiveListResponse") {
        let { Source, List } = data;
        console.log(Source);
        console.log(List);
        if (Source === this.field.Field) {
          let { Elements } = List;
          this.interactiveListItems = Elements;
        }
      }
    }
  },
  methods: {
    getInteractiveList: function() {
      this.sendMessageToWS({
        type: "getInteractiveList",
        payload: {
          Object: this.field.CustomData.object,
          Source: this.field.Field,
          Filters: [
            {
              FieldName: this.field.CustomData.filterField,
              Value: this.interactiveTargetValue.toString()
            }
          ]
        }
      });
    },
    setValue: function(v, e) {
      const { Field } = v;
      this.model[Field] = e;
    },
    setRule: function(v, fV) {
      return setRuleData(v, fV);
    }
  }
};
</script>

<style scoped></style>
