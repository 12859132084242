<template>
  <div>
    <stream-component
      @close="dialog = false"
      v-if="dialog"
      :dialog-data="{ dialog, confData }"
    />

    <v-card elevation="0">
      <v-row class="align-center justify-space-between" no-gutters>
        <v-col class="col-auto">
          <v-card-text
            v-text="
              'Kamera: ' +
                confData['camera_name'] +
                ' : ' +
                (this.defaultIndex + 1) +
                '/' +
                +this.localMediaData['Files'].length
            "
          >
          </v-card-text>
        </v-col>

        <v-col class="col-auto mr-4">
          <v-btn
            @click="dialog = true"
            v-if="confData['havelivestream'] === 1"
            icon
          >
            <v-icon>live_tv</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <div>
        <div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                @click="prevMedia"
                class="arrow_left_btn ml-4"
                icon
              >
                <v-icon color="rgba(255,255,255,0.5)" large>mdi-arrow-left-drop-circle</v-icon>
              </v-btn>
            </template>
            <span>{{ tooltipPrevious }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                @click="nextMedia"
                class="arrow_right_btn mr-4"
                icon
                :disabled="disableNextButton"
              >
                <v-icon color="rgba(255,255,255,0.5)" large>mdi-arrow-right-drop-circle</v-icon>
              </v-btn>
            </template>
            <span>{{ tooltipNext }}</span>
          </v-tooltip>
          <v-img
            v-if="localMediaData['Files'][defaultIndex]['MType'] === 0"
            :src="
              'https://telixconnect.net:3443/mediafiles/M' +
                localMediaData['Files'][defaultIndex]['Id'] +
                '.jpg'
            "
            contain
          ></v-img>
          <video
            width="auto"
            height="auto"
            v-if="localMediaData['Files'][defaultIndex]['MType'] === 1"
            controls
          >
            <source
              type="video/mp4"
              :src="
                'https://telixconnect.net:3443/mediafiles/M' +
                  localMediaData['Files'][defaultIndex]['Id'] +
                  '.mp4'
              "
            />
          </video>
          <p class="img_timestamp mb-0 text-center">
            {{
              convertTimestamp(
                localMediaData["Files"][defaultIndex]["TimeStamp"]
              )
            }}
          </p>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import StreamComponent from "@/components/GridComponent/ModemGridNew/ModemGridTabLayoutComponents/CameraChildComponents/StreamComponent";
import { mapGetters } from "vuex";
export default {
  name: "PhysicalCamera",
  components: { StreamComponent },
  props: {
    confData: {
      type: Object,
      required: true
    },
    mediaData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      activeImage: "",
      defaultIndex: 0,
      defaultElement: null,
      dialog: false,
      disableNextButton: true,

      localMediaData: [],
      tooltipNext: "İleri",
      tooltipPrevious: "Geri"
    };
  },
  computed: {
    ...mapGetters({
      getterSocketMessage: "storeSocket/getterSocketMessage"
    })
  },
  created() {
    this.localMediaData = this.mediaData;
    this.defaultIndex = this.localMediaData["Files"].length - 1;
  },
  watch: {
    getterSocketMessage: function(response) {
      const { command, data } = JSON.parse(response.data);
      if (
        command === "CameraMediaResponse" &&
        data.Target === this.confData.camera_name
      ) {
        console.log("Query Res", data);
        this.localMediaData["Files"] = [
          ...data["Files"],
          ...this.localMediaData["Files"]
        ];
        this.defaultIndex = this.defaultIndex + data["Files"].length;
      }
    }
  },
  mounted() {},
  methods: {
    nextMedia: function() {
      if (this.defaultIndex < this.localMediaData["Files"].length - 1) {
        this.defaultIndex++;
        console.log("Last Page", this.defaultIndex);
        if (this.defaultIndex === this.localMediaData["Files"].length - 1) {
          this.disableNextButton = true;
          console.log("LastPageDisable");
        }
      }
    },
    prevMedia: function() {
      if (this.defaultIndex === 1) {
        console.log("Make Request");
        const { camera_id, camera_name } = this.confData;
        const timestamp = this.localMediaData["Files"][this.defaultIndex - 1][
          "TimeStamp"
        ];
        const query = {
          type: "queryMoreMedia",
          payload: { Id: camera_id, Target: camera_name, TimeStamp: timestamp }
        };
        this.sendMessageToWS(query);
      } else {
        this.defaultIndex--;
        console.log("Previous", this.defaultIndex);
      }
      this.disableNextButton = false;
    },
    convertTimestamp: function(timestamp) {
      return new Date(timestamp * 1000).toLocaleString("tr-TR");
    }
  }
};
</script>

<style scoped>
.active_thumbnail {
  border: 2px solid cornflowerblue;
}

.arrow_left_btn,
.arrow_right_btn {
  position: absolute;
  z-index: 9;
  top: 50%;
  transform: translateY(-50%);
}

.arrow_right_btn {
  right: 0;
}
</style>
