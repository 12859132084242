<template>
  <div v-if="parentName !== 'ModemTabLayout'">
    <v-row no-gutters>
      <v-text-field
          label="Genişlik"
          @input="changeSizeOfSVG"
          v-model="currentItem['genislik']"
      />
    </v-row>

    <v-row no-gutters>
      <v-col class="col-6 pr-1">
        <v-text-field
            label="Push Button Label"
            v-model="currentItem['fixedLabel']"
            @input="changeFixedLabel"
        />
      </v-col>
      <v-col class="col-6 pl-1">
        <v-menu
            ref="cpRef"
            v-model="colorMenuFixedLabel"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-x
            min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
                label="Fixed Label Renk"
                prepend-icon="event"
                readonly
                v-on="on"
                return-object
                v-model="currentItem['fixedLabelColor']"
            >
              <v-icon
                  :color="`#${currentItem['fixedLabelColor']}`"
                  slot="prepend"
                  class="oneLabelIcon"
              >
                mdi-invert-colors
              </v-icon>
            </v-text-field>
          </template>
          <v-color-picker
              v-model="currentItem['fixedLabelColor']"
              @input="changePushButtonFixedLabelColor"
          ></v-color-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col class="col-12 pl-1">
        <v-menu
            v-model="colorMenuButtonOn"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-x
            min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
                label="Buton 1 Renk"
                prepend-icon="event"
                readonly
                v-on="on"
                return-object
                v-model="currentItem['buttonOnColor']"
            >
              <v-icon
                  :color="`#${currentItem['buttonOnColor']}`"
                  slot="prepend"
                  class="oneLabelIcon"
              >
                mdi-invert-colors
              </v-icon>
            </v-text-field>
          </template>
          <v-color-picker
              v-model="currentItem['buttonOnColor']"
              @input="changePushButtonOnColor"
          ></v-color-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col class="col-12 pl-1">
        <v-menu
            v-model="colorMenuButtonStroke"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-x
            min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
                label="Buton Çerçeve Renk"
                prepend-icon="event"
                readonly
                v-on="on"
                return-object
                v-model="currentItem['buttonOnStrokeColor']"
            >
              <v-icon
                  :color="`#${currentItem['buttonOnStrokeColor']}`"
                  slot="prepend"
                  class="oneLabelIcon"
              >
                mdi-invert-colors
              </v-icon>
            </v-text-field>
          </template>
          <v-color-picker
              v-model="currentItem['buttonOnStrokeColor']"
              @input="changeCircleStrokeColor"
          ></v-color-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col class="col-12 pl-1">
        <v-menu
            v-model="colorMenuButtonOff"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-x
            min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
                label="Buton 0 Renk"
                prepend-icon="event"
                readonly
                v-on="on"
                return-object
                v-model="currentItem['buttonOffColor']"
            >
              <v-icon
                  :color="`#${currentItem['buttonOffColor']}`"
                  slot="prepend"
                  class="oneLabelIcon"
              >
                mdi-invert-colors
              </v-icon>
            </v-text-field>
          </template>
          <v-color-picker
              v-model="currentItem['buttonOffColor']"
              @input="changePushButtonOffColor"
          ></v-color-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col class="col-12 pr-1">
        <v-select
            :items="ioListData"
            v-model="currentItem['bagli_alan']"
            label="Bağlı Alan"
        />
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col class="col-6 pr-1">
        <v-text-field label="0 Değer" v-model="currentItem['offValue']" />
      </v-col>
      <v-col class="col-6 pl-1">
        <v-text-field label="1 Değer" v-model="currentItem['onValue']" />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-text-field
          label="Timeout Süre (ms)"
          v-model="currentItem['timeoutMS']"
      />
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { changeSvgDimension } from "./svgComponentUtils/svgUtilFunctions";

export default {
  name: "pushButton",
  props: {
    currentItem: {
      type: Object,
      required: false
    },
    modemID: {
      type: [Array, Number],
      required: false
    },
    //from modem tab layout
    parentName: String,
    svgItemObject: {
      type: Object,
      required: false
    },
    listSetup: Object,
    dataProperty: Object
  },
  data() {
    return {
      colorMenuFixedLabel: false,
      colorMenuButtonOn: false,
      colorMenuButtonOff: false,
      colorMenuButtonStroke: false,
      ioListData: [],
      pushButtonDOMElements: {},
      isPressed: false,
      pushButtonTimeout: null,
      pbValue: "0"
    };
  },
  computed: {
    ...mapGetters({
      getterSocketMessage: "storeSocket/getterSocketMessage",
      getterIsSocketConnected: "storeSocket/getterIsSocketConnected"
    }),
    getListSetupComps: function() {
      return this.listSetup["comps"];
    },
    getDataProperty: function() {
      return this.dataProperty;
    }
  },
  beforeDestroy() {
    if (this.parentName === "ModemTabLayout") {
      const { pbEventGroup } = this.pushButtonDOMElements;
      pbEventGroup.removeEventListener("click", this.clickPushButtonEvent);
      this.pushButtonTimeout && clearTimeout(this.pushButtonTimeout);
    }
  },
  created() {
    if (this.parentName !== "ModemTabLayout") {
      let requestIOList = {
        type: "getModemIOList",
        payload: {
          TargetID: this.currentItem["id"],
          ModemId: parseInt(this.modemID[0]),
          Secim: "2@3@4@5"
        }
      };
      this.sendMessageToWS(requestIOList);
    }
  },
  mounted() {
    this.pushButtonDOMElements = this.registerSVGElements();
    if (this.parentName === "ModemTabLayout") {
      this.binaryConversion(this.getDataProperty);
    }
  },
  watch: {
    getterSocketMessage: function(value) {
      const { command, data } = JSON.parse(value.data);
      if (command === "ModemIOList") {
        const { TargetID, Elements } = data;
        if (TargetID === this.currentItem["id"]) {
          this.ioListData = [];
          this.ioListData = Object.keys(Elements).map(key => {
            return {
              value: key,
              text: Elements[key]
            };
          });
          console.log("PUSH BUTTON IO LIST", this.ioListData);
        }
      } else if (command === "ProcessData") {
        const { ModemId } = data;
        if (ModemId === this.modemID) {
          //process here.
          this.binaryConversion(data);
        }
      }
    }
  },
  methods: {
    registerSVGElements: function() {
      const { id } =
          this.parentName === "ModemTabLayout"
              ? this.svgItemObject
              : this.currentItem;
      const svgBase = document.getElementById(id);
      const pbGroup = svgBase.querySelector("#push_button_full");
      const fixedLabel = pbGroup.querySelector("#push_button_fixed_label");
      const pbEventGroup = pbGroup.querySelector("#pushbutton_event_group");
      this.parentName === "ModemTabLayout" &&
      pbEventGroup.addEventListener("click", this.clickPushButtonEvent);

      const pbOverlay = pbEventGroup.querySelector(
          "#pushbutton_pressed_overlay"
      );
      const pbOnOff = pbEventGroup.querySelector("#pushbutton_onoff_button");
      const pbCircle = pbEventGroup.querySelector("#pushbutton_ellipse");
      return {
        fixedLabel,
        pbOnOff,
        pbCircle,
        pbOverlay,
        pbEventGroup
      };
    },
    /*
     * Editor functions
     * */
    changeCircleStrokeColor: function(value) {
      const substrColor = value.substring(1, value.length - 2);
      this.currentItem["buttonOnStrokeColor"] = substrColor;
      const { pbOnOff } = this.pushButtonDOMElements;
      pbOnOff.setAttribute("stroke", `#${substrColor}`);
    },
    changePushButtonOnColor: function(value) {
      const substrColor = value.substring(1, value.length - 2);
      this.currentItem["buttonOnColor"] = substrColor;
      const { pbOnOff } = this.pushButtonDOMElements;
      pbOnOff.setAttribute("fill", `#${substrColor}`);
    },
    changePushButtonOffColor: function(value) {
      this.currentItem["buttonOffColor"] = value.substring(1, value.length - 2);
    },
    changeFixedLabel: function(value) {
      const { fixedLabel } = this.pushButtonDOMElements;
      const tspan = fixedLabel.getElementsByTagName("tspan")[0];
      tspan.innerHTML = value;
    },
    changePushButtonFixedLabelColor: function(value) {
      const substrColor = value.substring(1, value.length - 2);
      this.currentItem["fixedLabelColor"] = substrColor;
      const { fixedLabel } = this.pushButtonDOMElements;
      fixedLabel.setAttribute("fill", `#${substrColor}`);
    },
    changeSizeOfSVG: function() {
      changeSvgDimension(this.currentItem);
    },
    findBinaryValue: function(value, bit) {
      const binaryArray = Number(value)
          .toString(2)
          .split("");
      const b = new Array(8 - binaryArray.length).fill("0").concat(binaryArray);
      return b[b.length - Number(bit)];
    },
    /*
     * Scada Events & SVG animations.  Runs according to ProcessData response from WebSocket.
     * */
    binaryConversion: function(dataParam) {
      const { Datas } = dataParam;
      const [tip, master_id, bit] = this.svgItemObject["bagli_alan"].split("_");

      if (Datas.hasOwnProperty(`${tip}_${master_id}`)) {
        this.pbValue = this.findBinaryValue(Datas[`${tip}_${master_id}`], bit);
        this.isPressed = false;
        const { pbOverlay } = this.pushButtonDOMElements;
        pbOverlay.setAttribute(
            "style",
            "transition: all .2s ease; opacity: 0;"
        );
        this.pushButtonTimeout && clearTimeout(this.pushButtonTimeout);
      }
      if (this.pbValue === "1") {
        this.pushButtonSetPressed();
      } else if (this.pbValue === "0") {
        this.pushButtonSetUnpressed();
      }
    },
    clickPushButtonEvent: function() {
      if (!this.isPressed) {
        this.isPressed = true;
        //send output request here.
        const { pbOverlay } = this.pushButtonDOMElements;
        pbOverlay.setAttribute(
            "style",
            "transition: all .2s ease; opacity: 1;"
        );
        const { bagli_alan, timeoutMS } = this.svgItemObject;
        const [tip,master,bit] = bagli_alan.split("_");

        //Push Button Command => Value is always 1 !
        const setOutput = {
          type: "setModemOutput",
          payload: {
            Target: `${tip}_${master}_${bit}`,
            Value: 1,
            ModemId: this.modemID
          }
        };
        this.sendMessageToWS(setOutput);
        this.pushButtonTimeout = setTimeout(this.pbTimeout, Number(timeoutMS));
        console.log("Clicked Push Button.", setOutput);
      }
    },
    pbTimeout: function() {
      this.isPressed = false;
      const { pbOverlay } = this.pushButtonDOMElements;
      pbOverlay.setAttribute("style", "transition: all .2s ease; opacity: 0;");
      console.log("PushButton:: Removed Overlay !!");
    },
    pushButtonSetPressed: function() {
      const { pbOnOff } = this.pushButtonDOMElements;
      const { buttonOnColor } = this.svgItemObject;
      pbOnOff.setAttribute(
          "style",
          `fill:#${buttonOnColor}; transition: .2s ease-in-out;`
      );
    },
    pushButtonSetUnpressed: function() {
      const { pbOnOff } = this.pushButtonDOMElements;
      const { buttonOffColor } = this.svgItemObject;
      pbOnOff.setAttribute(
          "style",
          `fill:#${buttonOffColor}; transition: .2s ease-in-out;`
      );
    }
  }
};
</script>

<style scoped>
.pushButtonPressed {
}
</style>
