import Vue from "vue";
import Vuetify from "vuetify/lib";
//import colors from 'vuetify/lib'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi"
  },
  theme: {
    themes: {
      light: {
        primary: "#1976D2",
        secondary: "#fff",
        accent: "#82B1FF",
        error: "#ed561b",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        pageBackground: "#fff",
        gridItemBackground: "#fff",
        gridItemText: "#000",
        toolbarBackground: "#3949AB",
        toolbarIndicator: "#3949AB",
        toolbarIcons: "#fff",
        drawerBackground: "#fff",
        drawerText: "#424242",

        darkDef: "#0e0b2a",
        lightDef: "#d1d6fa",

        loginButton: "#303F9F",
        footerBackground: "#283593",

        /*Toolbar Username Chip Colors*/
        toolbarUsernameColor: "#000000",
        toolbarUsernameBg: "#ebefff",
        /*Toolbar Username Chip Colors*/

        /*Navigation Drawer Colors*/
        drawerActive: "#1b1a30",
        /*Navigation Drawer Colors*/

        /*Toolbar Background in Dashboard & Stations Grid Items*/
        dynamicTableToolbar: "#283593",
        /*Toolbar Background in Dashboard & Stations Grid Items*/

        /*Modem Tab layout colors*/
        modemTabColor: "#ffffff",
        modemTabToolbarTitleColor: "#ffffff",
        "v-tabs-items": "#fff",
        /*Modem Tab layout colors*/

        /*Modem Group Component Colors*/
        modemGrupCheckboxColor: "#3569ec",
        modemGrupSelectColor: "#3569ec",
        /*Modem Group Component Colors*/

        /*Popup Menu Colors*/
        popupMenuColor: "#ffffff",
        /*Popup Menu Colors*/

        /*Dashboard Grid Item Table*/
        simpleTableClass: "#ffffff"
        /*Dashboard Grid Item Table*/
      },
      dark: {
        primary: "#3f4fab",
        secondary: "#476f94",
        accent: "#8c9eff",
        error: "#ed561b",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00",
        pageBackground: "#2e323c",
        gridItemBackground: "#191927",
        gridItemText: "#fff",
        toolbarBackground: "#1f222b",
        toolbarIndicator: "#fff",
        toolbarIcons: "#d3d3d3",
        drawerBackground: "#0c0e16",
        drawerText: "#d3d3d3",

        darkDef: "#d1d6fa",
        lightDef: "#0e0b2a",

        loginButton: "#536DFE",
        footerBackground: "#1b202e",

        /*Toolbar Username Chip Colors*/
        toolbarUsernameBg: "#3569ec",
        toolbarUsernameColor: "#ebefff",
        /*Toolbar Username Chip Colors*/

        /*Navigation Drawer Colors*/
        drawerActive: "#b9cbff",
        /*Navigation Drawer Colors*/

        /*Toolbar Background in Dashboard & Stations Grid Items*/
        dynamicTableToolbar: "#1d1f29",
        /*Toolbar Background in Dashboard & Stations Grid Items*/

        /*Modem Tab layout colors*/
        modemTabColor: "#71e086",
        modemTabToolbarTitleColor: "#e3e3e3",
        "v-tabs-items": "#191927",
        /*Modem Tab layout colors*/

        /*Modem Group Component Colors*/
        modemGrupCheckboxColor: "#3569ec",
        modemGrupSelectColor: "#c2d5ff",
        /*Modem Group Component Colors*/

        /*Popup Menu Colors*/
        popupMenuColor: "#1f222b",
        /*Popup Menu Colors*/

        /*Dashboard Grid Item Table*/
        simpleTableClass: "#191926"
        /*Dashboard Grid Item Table*/
      }
    }
  }
});
