import Vue from "vue";
import Vuex from "vuex";
import store_login_events from "./store_files/store_login_events";
import store_socket from "./store_files/store_socket";
import store_grid_layout_events from "./store_files/store_grid_layout_events";
import store_scale_svg from "./store_files/store_scale_svg";
import store_distribute_svg_data from "./store_files/store_distribute_svg_data";

Vue.use(Vuex);

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  modules: {
    storeDistributeSvgData: store_distribute_svg_data,
    storeLoginEvents: store_login_events,
    storeSocket: store_socket,
    storeGridEvents: store_grid_layout_events,
    storeSVGScale: store_scale_svg
  }
});

export default store;
