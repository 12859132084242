var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.dialog)?_c('stream-component',{attrs:{"dialog-data":{ dialog: _vm.dialog, confData: _vm.confData }},on:{"close":function($event){_vm.dialog = false}}}):_vm._e(),_c('v-card',{attrs:{"elevation":"0"}},[_c('v-row',{staticClass:"align-center justify-space-between",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"col-auto"},[_c('v-card-text',{domProps:{"textContent":_vm._s(
            'Kamera: ' +
              _vm.confData['camera_name'] +
              ' : ' +
              (this.defaultIndex + 1) +
              '/' +
              +this.localMediaData['Files'].length
          )}})],1),_c('v-col',{staticClass:"col-auto mr-4"},[(_vm.confData['havelivestream'] === 1)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = true}}},[_c('v-icon',[_vm._v("live_tv")])],1):_vm._e()],1)],1),_c('div',[_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"arrow_left_btn ml-4",attrs:{"icon":""},on:{"click":_vm.prevMedia}},on),[_c('v-icon',{attrs:{"color":"rgba(255,255,255,0.5)","large":""}},[_vm._v("mdi-arrow-left-drop-circle")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.tooltipPrevious))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"arrow_right_btn mr-4",attrs:{"icon":"","disabled":_vm.disableNextButton},on:{"click":_vm.nextMedia}},on),[_c('v-icon',{attrs:{"color":"rgba(255,255,255,0.5)","large":""}},[_vm._v("mdi-arrow-right-drop-circle")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.tooltipNext))])]),(_vm.localMediaData['Files'][_vm.defaultIndex]['MType'] === 0)?_c('v-img',{attrs:{"src":'https://telixconnect.net:3443/mediafiles/M' +
              _vm.localMediaData['Files'][_vm.defaultIndex]['Id'] +
              '.jpg',"contain":""}}):_vm._e(),(_vm.localMediaData['Files'][_vm.defaultIndex]['MType'] === 1)?_c('video',{attrs:{"width":"auto","height":"auto","controls":""}},[_c('source',{attrs:{"type":"video/mp4","src":'https://telixconnect.net:3443/mediafiles/M' +
                _vm.localMediaData['Files'][_vm.defaultIndex]['Id'] +
                '.mp4'}})]):_vm._e(),_c('p',{staticClass:"img_timestamp mb-0 text-center"},[_vm._v(" "+_vm._s(_vm.convertTimestamp( _vm.localMediaData["Files"][_vm.defaultIndex]["TimeStamp"] ))+" ")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }