<template>
  <v-card flat color="gridItemBackground">
    <div class="ml-2 mt-2" v-html="modemInfo"></div>
  </v-card>
</template>

<script>
export default {
  name: "InfoView",
  props: {
    modemInfo: String
  },
  computed: {
    getModemYazi: function () {
      return this.modemInfo;
    }
  }
}
</script>

<style scoped>

</style>
