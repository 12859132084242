<template>
  <v-sheet elevation="6" class="py-2 px-2">
    <v-row align="center" no-gutters>
      <v-col class="col-auto">
        <span class="mr-1">Filtreler:</span>
      </v-col>

      <v-col class="col-auto">
        <v-sheet elevation="0">
          <v-chip-group
              :column="false"
              :dark="false"
              color="purple"
              :center-active="true"
              :show-arrows="true"
          >
            <div v-for="(chip, i) in filters" :key="i">
              <v-chip
                  v-for="(chipValue, j) in chip['Data']"
                  :key="j"
                  class="mx-1"
                  text-color="white"
                  :light="true"
                  :close="true"
                  color="green darken-1"
                  @click:close="
                  $emit(chipEvents[0], {
                    chip,
                    chipValue,
                    i
                  })
                "
              >
                <span class="white--text"
                >{{ getFieldName(chip)[0].Label }}:{{
                    getValue(chip, chipValue)
                  }}</span
                >
              </v-chip>
            </div>
          </v-chip-group>
        </v-sheet>
      </v-col>

      <v-spacer></v-spacer>

      <v-col class="col-auto">
        <v-chip @click="$emit(chipEvents[1])" color="red darken-2">
          <span class="white--text">Tümünü sil</span>
          <v-icon class="white--text" right>mdi-delete</v-icon>
        </v-chip>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  name: "ChipComponent",
  props: {
    filters: {
      type: Array,
      required: false,
      default: () => []
    },
    chipEvents: {
      type: Array,
      required: false,
      default: () => ["removeFilter", "removeAllFilters"]
    },
    filterFields: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      chipData: [],
      unsubscribe: null
    };
  },
  created() {},
  methods: {
    getValue: function(chip, value) {
      console.log("Chip Get Vlaue", chip, this.filterFields);
      let filteredField = "";
      let filteredValue = "";
      filteredField = this.filterFields.filter(f => f.Field === chip.Field)[0];
      if (filteredField["Type"] === "text") {
        return value;
      }
      if (filteredField.Conf.ComboItems.Elements !== null) {
        filteredValue = filteredField.Conf.ComboItems.Elements.filter(f => {
          if (f.Id === value) {
            return f;
          }
        });
      }
      console.log("F Field", filteredField);
      console.log("F Value", filteredValue);
      return filteredValue[0].Text;
    },
    getFieldName: function(chip) {
      console.log("CHIP", chip);
      return this.filterFields.filter(m => chip["Field"] === m["Field"]);
    }
  }
};
</script>

<style scoped></style>
