<template>
  <div class="my-4">
    <v-dialog v-model="saveDialog" width="300" persistent>
      <v-card>
        <v-card-title class="pb-6 p text-center">
          Değişiklikler kaydediliyor...
        </v-card-title>
        <v-progress-linear
            :active="saveDialog"
            :indeterminate="saveDialog"
            absolute
            bottom
            color="indigo"
            height="10"
        ></v-progress-linear>
      </v-card>
    </v-dialog>

    <div class="row no-gutters">
      <div v-if="!readOnly" class="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-2">
        <v-app-bar height="60" color="teal lighten-1" dark>
          <p class="mb-0">Eklenebilecek modemler ({{izinsizModemler.length}} modem)</p>
          <v-spacer></v-spacer>
          <v-select
              :readonly="readOnly"
              dense
              hide-details
              label="Grup Seçiniz"
              :items="[
              { group_id: -1, group_name: 'Tüm Modemler' },
              ...modemKategorileri
            ]"
              v-model="modemKategoriModel"
              item-text="group_name"
              item-value="group_id"
              @input="selectModemGroup"
          />

          <v-fade-transition>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    @click="addModemPermissions"
                    class="ml-4"
                    v-show="!hiddenX"
                    icon
                    dark
                    small
                    v-on="on"
                    v-if="!readOnly"
                >
                  <v-icon>redo</v-icon>
                </v-btn>
              </template>
              <span>Seçilen modemleri yetki listesine ekle.</span>
            </v-tooltip>
          </v-fade-transition>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  @click="clearAllSelections('L')"
                  :disabled="hiddenX"
                  icon
                  v-on="on"
                  v-if="!readOnly"
              >
                <v-icon>clear_all</v-icon>
              </v-btn>
            </template>
            <span>Tüm seçimleri kaldır</span>
          </v-tooltip>
        </v-app-bar>
        <v-card style="height: 500px;" class="overflow-y-auto">
          <v-list-item-group v-model="permNoModel" multiple>
            <v-list-item
                v-for="item in izinsizModemler"
                :key="item['modem_id']"
                :value="item['modem_id']"
            >
              <template v-slot:default="{ active }">
                <v-list-item-action
                    v-if="!readOnly">
                  <v-checkbox
                      :input-value="active"
                      color="primary"
                  ></v-checkbox>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>{{
                      item["modem_name"]
                    }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-card>
      </div>

      <div :class=" !readOnly ? 'col-xl-6 col-lg-6 col-md-6 col-sm-12 px-2' : 'col-12'">
        <v-app-bar height="60" color="teal lighten-1" dark>
          <p class="mb-0">
            Yetkisi olduğu modemler ({{ izinliModemler.length }} modem)
          </p>
          <v-spacer></v-spacer>

          <v-fade-transition>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    @click="removeModemPermissions"
                    class="ml-4"
                    v-show="!hiddenY"
                    icon
                    dark
                    small
                    v-on="on"
                    v-if="!readOnly"
                >
                  <v-icon>undo</v-icon>
                </v-btn>
              </template>
              <span>Seçilen modemlerin yetkisini kaldır.</span>
            </v-tooltip>
          </v-fade-transition>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-if="!readOnly"
                     @click="clearAllSelections('R')"
                     :disabled="hiddenY"
                     icon
                     v-on="on"
              >
                <v-icon>clear_all</v-icon>
              </v-btn>
            </template>
            <span>Tüm seçimleri kaldır</span>
          </v-tooltip>
        </v-app-bar>

        <v-card style="height: 500px;" class="overflow-y-auto">
          <v-list-item-group v-model="permYesModel" multiple>
            <v-list-item
                v-for="item in izinliModemler"
                :key="item['modem_id']"
                :value="item['modem_id']"
            >
              <template v-slot:default="{ active }">
                <v-list-item-action v-if="!readOnly">
                  <v-checkbox
                      :input-value="active"
                      color="primary"
                  ></v-checkbox>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>{{
                      item["modem_name"]
                    }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ModemPermissionsComp",
  props: {
    idProperty: {
      type: String,
      required: true
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      saveDialog: false,
      gruptakiModemler: [],
      modemKategorileri: [],
      modemKategoriModel: {},
      izinsizModemler: [],
      izinsizFixed: [],
      izinliModemler: [],
      tumModemler: [],
      permYesModel: [],
      permNoModel: [],
      hiddenY: true,
      hiddenX: true,

      saveModemPermissions: {
        type: "saveKullaniciModemPermissions",
        payload: {
          Id: Number(this.idProperty),
          Modems: []
        }
      },

      requestModemPermission: {
        type: "getKullaniciModemPermissions",
        payload: {
          Id: Number(this.idProperty)
        }
      },
      requestAllModems: {
        type: "getAllModems",
        payload: {
          Id: Number(this.idProperty)
        }
      },
      requestModemCategories: {
        type: "modemCategories",
        payload: {
          Id: Number(this.idProperty)
        }
      },
      requestModemsOnCategory: {
        type: "allModemsOnCategory",
        payload: { GrupId: -1, Id: Number(this.idProperty) }
      }
    };
  },
  computed: {
    ...mapGetters({
      getterSocketMessage: "storeSocket/getterSocketMessage",
      getterIsSocketConnected: "storeSocket/getterIsSocketConnected"
    })
  },
  watch: {
    permYesModel: function(val) {
      this.hiddenY = val.length <= 0;
    },
    permNoModel: function(val) {
      this.hiddenX = val.length <= 0;
    },
    getterSocketMessage: function(response) {
      const { command, data } = JSON.parse(response.data);

      if (command === "ModemComboList") {
        this.tumModemler = data;
      } else if (command === "ModemGroups") {
        this.modemKategoriModel = -1;
        this.modemKategorileri = data;
      } else if (command === "KullaniciModemPermissions") {
        console.log("Modem Perms Data", data);
        this.tumModemler = this.tumModemler.map(m => {
          return {
            durum: 2,
            modem_id: m["modem_id"],
            modem_name: m["modem_name"]
          };
        });
        if (data !== null) {
          this.tumModemler.forEach(t => {
            const { modem_id } = t;
            data.forEach(a => {
              const { id, durum } = a;
              if (id === modem_id) {
                if (durum === 1) {
                  t["durum"] = 1;
                }
              }
            });
          });

          this.izinliModemler = this.tumModemler.filter(m => {
            return m.durum === 1 && m;
          });

          this.izinsizModemler = this.tumModemler.filter(m => {
            return m.durum === 2 && m;
          });
          this.izinsizFixed = this.izinsizModemler;

          console.log("Izinsiz modemler", this.izinsizModemler);
          console.log("Izinli modemler", this.izinliModemler);
        } else {
          this.izinsizModemler = this.tumModemler;
          this.izinsizFixed = this.izinsizModemler;
          this.izinliModemler = [];
        }
      } else if (command === "Modems") {
        if (data !== null) {
          this.gruptakiModemler = data;
          this.gruptakiModemler = this.gruptakiModemler.map(m => {
            const { modem_id, modem_name } = m;
            return {
              modem_id,
              modem_name,
              durum: 2
            };
          });

          /*eslint-disable*/
          this.tumModemler.forEach(m => {
            const { modem_id, durum } = m;
            this.gruptakiModemler.forEach(g => {
              const { modem_id: g_id } = g;
              if (modem_id === g_id) {
                if (durum === 1) {
                  g['durum'] = 1;
                }
              }
            });
          });

          this.izinsizModemler = this.gruptakiModemler.filter(m => {
            return m.durum !== 1 && m;
          });
        } else {
          this.izinsizModemler = [];
        }
      } else if (command === "SavePermissionResult") {
        this.sendMessageToWS(this.requestAllModems);
        this.sendMessageToWS(this.requestModemPermission);
        this.saveDialog = false;
        this.modemKategoriModel = [];
      }
    }
  },
  created() {
    // Send Requests here
    this.sendMessageToWS(this.requestAllModems);
    this.sendMessageToWS(this.requestModemCategories);
    this.sendMessageToWS(this.requestModemPermission);
  },
  methods: {
    selectModemGroup: function(val) {
      console.log("Selected", val);
      if (this.modemKategoriModel !== -1) {
        this.requestModemsOnCategory["payload"]["GrupId"] = val;
        this.sendMessageToWS(this.requestModemsOnCategory);
      } else {
        this.izinsizModemler = this.izinsizFixed;
      }
    },
    clearAllSelections: function(p) {
      if (p === "R") {
        this.permYesModel = [];
      } else {
        this.permNoModel = [];
      }
    },
    removeModemPermissions: function() {
      console.log("Perm Yes", this.permYesModel);
      const data = this.permYesModel.map(v => {
        return {
          durum: 2,
          id: v
        }
      });
      this.saveModemPermissions["payload"]["Id"] = Number(this.idProperty);
      this.saveModemPermissions["payload"]["Modems"] = data;
      this.sendMessageToWS(this.saveModemPermissions);
      this.saveDialog = true;
    },
    addModemPermissions: function() {
      //Id, Durum
      console.log("Perm No Model", this.permNoModel, this.tumModemler);
      const data = this.permNoModel.map(v => {
        return {
          durum: 1,
          id: v
        }
      });

      this.saveModemPermissions["payload"]["Id"] = Number(this.idProperty);
      this.saveModemPermissions["payload"]["Modems"] = data;
      this.sendMessageToWS(this.saveModemPermissions);
      this.saveDialog = true;

      console.log("Fİltered", data);
    }
  }
};
</script>

<style scoped></style>
