import Cookies from "js-cookie";
const state = {
  socket: {
    isConnected: false,
    socketMessage: "",
    errorMessage: "",
    closeCode: -1
  },
  dashboardAlarms: []
};

const getters = {
  getterIsSocketConnected: state => {
    return state.socket.isConnected;
  },
  getterSocketMessage: state => {
    return state.socket.socketMessage;
  },
  getterErrorMessage: state => {
    return state.socket.errorMessage;
  },
  getterCloseCode: () => {
    return state.socket.closeCode;
  },
  getDashboardAlarms: () => {
    return state.dashboardAlarms;
  }
};

const mutations = {
  on_socket_open: state => {
    state.socket.isConnected = true;
  },
  on_socket_close: (state, closeCode) => {
    Cookies.remove("ACCESS_TOKEN");
    state.socket.isConnected = false;
    state.closeCode = closeCode;
  },
  on_socket_error: (state, event) => {
    Cookies.remove("ACCESS_TOKEN");
    state.socket.isConnected = false;
    state.socket.errorMessage = event;
  },
  on_socket_message: (state, message) => {
    state.socket.socketMessage = message;
  },
  on_set_dashboard_alarms: (state, data) => {
    state.dashboardAlarms = data;
  }
};

const actions = {
  actionSocketOpened({ commit }) {
    /*eslint-disable*/
    return new Promise((resolve, reject) => {
      console.log("action called: socket opened");
      commit("on_socket_open");
      resolve();
    });
  },
  actionSocketClosed({ commit }, code) {
    commit("on_socket_close", code);
  },
  actionSocketError({ commit }, event) {
    commit("on_socket_error", event);
  },
  actionSocketMessage({ commit }, event) {
    commit("on_socket_message", event);
  },
  actionSetDashboardAlarms({ commit }, event) {
    commit("on_set_dashboard_alarms", event);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
