<template>
  <div v-if="parentName !== 'ModemTabLayout'">
    <v-row no-gutters>
      <v-text-field
          label="Genişlik"
          v-model="currentItem['genislik']"
          @input="svgDimen"
      />
    </v-row>

    <v-row no-gutters>
      <v-select
          label="Bağlı Alan"
          v-model="currentItem['bagli_alan']"
          :items="bagliAlanItems"
          item-text="Text"
          item-value="Id"
      />
    </v-row>
  </div>
</template>

<script>
import {changeSvgDimension} from "@/components/ModemLayoutWizard/ModemLayoutSettings/svgComponentUtils/svgUtilFunctions";
import {mapGetters} from "vuex";

export default {
  name: "katBilgiGosterge",
  props: {
    currentItem: {
      type: Object,
      required: false
    },
    modemID: {
      type: [Array, Number],
      required: false
    },
    //from modem tab layout
    parentName: String,
    svgItemObject: {
      type: Object,
      required: false
    },
    listSetup: Object,
    dataProperty: Object
  },
  data () {
    return {
      bagliAlanItems: []
    }
  },
  computed: {
    ...mapGetters({
      getterSocketMessage: "storeSocket/getterSocketMessage",
      getterIsSocketConnected: "storeSocket/getterIsSocketConnected",
      getterSVGScaleInfo: "storeSVGScale/getterSVGScaleInfo"
    }),
    svgDimen: function() {
      return changeSvgDimension(this.currentItem);
    }
  },
  watch: {
    getterSocketMessage: function(response) {
      const { command, data } = JSON.parse(response.data);
      if (command === "ModemInputList") {
        this.bagliAlanItems = Object.keys(data).map(key => {
          return {
            Text: data[key],
            Id: key
          };
        });
      }
    }
  },
  created() {
    if (this.parentName !== "ModemTabLayout") {
      this.getBagliAlanInputs();
    }
  },
  mounted() {
    let a = document.querySelector('#kat_bilgi_std');
    a.addEventListener("click", this.aClicked)
  },
  methods: {
    aClicked: function () {
      let setModemOutputs = {
        type: "setModemOutput",
        payload: {ModemId: 601,  Target: "2_58_4", Value: 1 }
      }
      this.sendMessageToWS(setModemOutputs);
    },
    getBagliAlanInputs: function () {
      const req = {
        type: "getModemInputs",
        payload: { ModemId: Number(this.modemID[0]) }
      };
      this.sendMessageToWS(req);
    },
  }
}
</script>

<style scoped>

</style>
