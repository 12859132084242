<template>
  <div v-if="parentName !== 'ModemTabLayout'">
    <v-row no-gutters>
      <v-text-field
          label="Genişlik"
          v-model="currentItem['genislik']"
          @input="changeSizeOfSVG"
      />
    </v-row>
    <v-row no-gutters>
      <v-autocomplete
          label="Bağlı Alan"
          :items="bagliAlanItems"
          v-model="currentItem['bagli_alan']"
          item-text="Text"
          item-value="Id"
      />
    </v-row>
    <v-row no-gutters>
      <v-col class="col-6">
        <v-text-field
            label="Açık Etiket"
            v-model="currentItem['oneLabel']"
            @input="changeOnLabel"
        />
      </v-col>

      <v-col class="col-6">
        <v-menu
            ref="cpRef"
            v-model="colorMenuOne"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-x
            min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
                label="Açık Renk"
                prepend-icon="event"
                readonly
                v-on="on"
                return-object
                v-model="currentItem['oneColor']"
            >
              <v-icon slot="prepend">
                mdi-invert-colors
              </v-icon>
            </v-text-field>
          </template>
          <v-color-picker
              v-model="currentItem['oneColor']"
              @input="changeColorOn"
          ></v-color-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col class="col-6">
        <v-text-field
            label="Kapalı Etiket"
            v-model="currentItem['zeroLabel']"
        />
      </v-col>

      <v-col class="col-6">
        <v-menu
            ref="cpRef"
            v-model="colorMenuZero"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-x
            min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
                label="Kapalı Renk"
                prepend-icon="event"
                readonly
                v-on="on"
                return-object
                v-model="currentItem['zeroColor']"
            >
              <v-icon slot="prepend">
                mdi-invert-colors
              </v-icon>
            </v-text-field>
          </template>
          <v-color-picker
              v-model="currentItem['zeroColor']"
              @input="changeColorOff"
          ></v-color-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-menu
          ref="cpRef"
          v-model="colorMenuLabel"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-x
          min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
              label="Etiket Renk"
              prepend-icon="event"
              readonly
              v-on="on"
              return-object
              v-model="currentItem['labelColor']"
          >
            <v-icon slot="prepend">
              mdi-invert-colors
            </v-icon>
          </v-text-field>
        </template>
        <v-color-picker
            v-model="currentItem['labelColor']"
            @input="changeLabelColor"
        ></v-color-picker>
      </v-menu>
    </v-row>
    <v-row no-gutters>
      <v-text-field
          label="Timeout Süre (ms)"
          v-model="currentItem['timeoutMS']"
      />
    </v-row>
  </div>
</template>

<script>
import { changeSvgDimension } from "@/components/ModemLayoutWizard/ModemLayoutSettings/svgComponentUtils/svgUtilFunctions";
import { mapGetters } from "vuex";

export default {
  name: "slideSwitchOffset",
  data() {
    return {
      bagliAlanItems: [],
      colorMenuOne: false,
      colorMenuZero: false,
      colorMenuLabel: false,
      svgDOMElements: {},
      outputBinary: [],
      onTimeout: false,
      swTimeout: null,
      switchValue: "0"
    };
  },
  props: {
    currentItem: {
      type: Object,
      required: false
    },
    modemID: {
      type: [Array, Number],
      required: false
    },
    //from modem tab layout
    parentName: String,
    svgItemObject: {
      type: Object,
      required: false
    },
    listSetup: Object,
    dataProperty: Object
  },
  computed: {
    ...mapGetters({
      getterSocketMessage: "storeSocket/getterSocketMessage",
      getterIsSocketConnected: "storeSocket/getterIsSocketConnected"
    })
  },
  watch: {
    getterSocketMessage: function(response) {
      const { command, data } = JSON.parse(response.data);
      if (command === "ModemIOList") {
        const { TargetID, Elements } = data;
        const { id } = this.currentItem;
        if (id === TargetID) {
          console.log("Modem Inputlar", data);
          this.bagliAlanItems = Object.keys(Elements).map(key => {
            return {
              Text: Elements[key],
              Id: key
            };
          });
        }
      } else if (command === "ProcessData") {
        const { ModemId, Datas } = data;
        if (ModemId === this.modemID) {
          if (Object.keys(Datas).length > 0) {
            this.dataProperty["Datas"] = {
              ...this.dataProperty["Datas"],
              Datas
            };
            this.setScadaData(ModemId, Datas);
          }
        }
      }
    }
  },
  beforeDestroy() {
    if (this.parentName === "ModemTabLayout") {
      const { baseG } = this.svgDOMElements;
      baseG.removeEventListener("click", this.switchClicked);
      this.swTimeout && clearTimeout(this.swTimeout);
    }
  },
  created() {
    if (this.parentName !== "ModemTabLayout") {
      this.getBagliAlans();
    }
  },
  mounted() {
    this.registerSVGObject();
    if (this.parentName === "ModemTabLayout") {
      this.setScadaData(this.dataProperty["Id"], this.dataProperty["Datas"]);
    }
  },
  methods: {
    registerSVGObject: function() {
      const { id } =
          this.parentName === "ModemTabLayout"
              ? this.svgItemObject
              : this.currentItem;
      const svgBase = document.getElementById(id);
      const svgG = svgBase.querySelector("#switch-group-offset-label");
      const textG = svgG.querySelector("#switch-offset-text-group");
      const baseG = svgG.querySelector("#switch-offset-group");

      this.parentName === "ModemTabLayout" &&
      baseG.addEventListener("click", this.switchClicked);

      const textWait = textG.querySelector("#switch-wait-text");
      const textOnOff = textG.querySelector("#switch-onoff-text");

      const baseOnOff = baseG.querySelector("#switch-onoff-base");
      const baseCircle = baseG.querySelector("#switch-onoff-circle");
      const baseWait = baseG.querySelector("#switch-wait-base");

      this.svgDOMElements = {
        baseG,
        textWait,
        textOnOff,
        baseOnOff,
        baseCircle,
        baseWait
      };
    },
    findBinaryValue: function(value, bit) {
      const binaryArray = Number(value)
          .toString(2)
          .split("");
      const b = new Array(8 - binaryArray.length).fill("0").concat(binaryArray);
      return b[b.length - Number(bit)];
    },
    setScadaData: function(ModemId, Datas) {
      const [tip, master, bit] = this.svgItemObject["bagli_alan"].split("_");
      if (Datas.hasOwnProperty(`${tip}_${master}`)) {
        console.log("SWITCH SCADA HAS DATA => SETTING...");
        this.switchValue = this.findBinaryValue(Datas[`${tip}_${master}`], bit);
        this.setupScadaData();
        this.onTimeout = false;
        this.resetTimeoutLayout();
        this.swTimeout && clearTimeout(this.swTimeout);
      }
    },
    setupScadaData: function() {
      let {
        bagli_alan,
        oneColor,
        oneLabel,
        zeroColor,
        zeroLabel
      } = this.svgItemObject;
      console.log("Bagli Alan Slide Switch Offset", bagli_alan);
      console.log("Switch Offset", this.svgItemObject);

      if (this.switchValue === "1") {
        this.setSwitchOn(oneColor, oneLabel);
        console.log("Slide Switch Offset Bagli Değeri", this.switchValue);
      } else if (this.switchValue === "0") {
        this.setSwitchOff(zeroColor, zeroLabel);
        console.log("Slide Switch Offset Bagli Değeri", this.switchValue);
      }
    },
    setSwitchOn: function(color, label) {
      console.log("Switch is on");
      const { baseOnOff, textOnOff, baseCircle } = this.svgDOMElements;
      baseOnOff.setAttribute(
          "style",
          `fill:#${color}; transition: all .5s ease-in-out;`
      );
      baseCircle.setAttribute(
          "style",
          "transform: translateX(0); transition: all .5s ease;"
      );
      const tspan = textOnOff.getElementsByTagName("tspan")[0];
      tspan.innerHTML = label;
    },
    setSwitchOff: function(color, label) {
      const { baseOnOff, textOnOff, baseCircle } = this.svgDOMElements;
      console.log("BaseOnOFF", baseOnOff);
      console.log("SwitchOff Params", color, label);
      baseOnOff.setAttribute(
          "style",
          `fill: #${color}; transition: all .5s ease-in-out;`
      );
      baseCircle.setAttribute(
          "style",
          "transform: translateX(-41px); transition: all .5s ease;"
      );
      const tspan = textOnOff.getElementsByTagName("tspan")[0];
      tspan.innerHTML = label;
    },
    switchClicked: function() {
      if (!this.onTimeout) {
        this.onTimeout = true;
        console.log("Switch Clicked", this.svgItemObject);
        const { bagli_alan, timeoutMS } = this.svgItemObject;
        const [tip, master_id, bit] = bagli_alan.split("_");
        console.log("Switch Value", this.switchValue);
        console.log("Switch DATAS", this.dataProperty);
        const { baseWait, textWait, textOnOff } = this.svgDOMElements;
        baseWait.setAttribute(
            "style",
            "opacity:0.6;transition: all .2s ease-in;"
        );
        textWait.setAttribute(
            "style",
            "opacity:0.6;transition: all .2s ease-in;"
        );
        textOnOff.setAttribute(
            "style",
            "opacity:0;transition: all .2s ease-in;"
        );
        const setOutput = {
          type: "setModemOutput",
          payload: {
            ModemId: this.modemID,
            Target: `${tip}_${master_id}_${bit}`,
            Value: this.switchValue === "1" ? 0 : 1
          }
        };
        console.log("Offset Switch Clicked Command", setOutput);
        this.sendMessageToWS(setOutput);
        this.swTimeout = setTimeout(this.switchTimeout, Number(timeoutMS));
      } else {
        console.log("Timeoutta click yapılamaz");
      }
    },
    resetTimeoutLayout: function() {
      const { baseWait, textWait, textOnOff } = this.svgDOMElements;
      baseWait.setAttribute("style", "opacity:0;transition: all .2s ease-in;");
      textWait.setAttribute("style", "opacity:0;transition: all .2s ease-in;");
      textOnOff.setAttribute("style", "opacity:1;transition: all .2s ease-in;");
    },
    switchTimeout: function() {
      const { oneColor, zeroColor, zeroLabel, oneLabel } = this.svgItemObject;
      const { baseWait, textWait, textOnOff } = this.svgDOMElements;
      baseWait.setAttribute("style", "opacity:0;transition: all .2s ease-in;");
      textWait.setAttribute("style", "opacity:0;transition: all .2s ease-in;");
      textOnOff.setAttribute("style", "opacity:1;transition: all .2s ease-in;");
      if (this.switchValue === "1") {
        this.setSwitchOn(oneColor, oneLabel);
      } else if (this.switchValue === "0") {
        this.setSwitchOff(zeroColor, zeroLabel);
      }
      this.onTimeout = false;
    },
    getBagliAlans: function() {
      const requestIOList = {
        type: "getModemIOList",
        payload: {
          TargetID: this.currentItem["id"],
          ModemId: parseInt(this.modemID[0]),
          Secim: "2@4"
        }
      };
      this.sendMessageToWS(requestIOList);
    },
    changeColorOn: function(value) {
      const { baseOnOff } = this.svgDOMElements;
      console.log("SVG DOM", this.svgDOMElements);
      const substrColor = value.substring(1, value.length - 2);
      baseOnOff.setAttribute("fill", `#${substrColor}`);
      this.currentItem["oneColor"] = substrColor;
    },
    changeOnLabel: function() {
      const { id } = this.currentItem;
      const svgBase = document.getElementById(id);
      const svgG = svgBase.querySelector("#switch-group-offset-label");
      const textG = svgG.querySelector("#switch-offset-text-group");
      const onOffText = textG.querySelector("#switch-onoff-text");
      const tspan = onOffText.getElementsByTagName("tspan")[0];
      const { oneLabel } = this.currentItem;
      tspan.innerHTML = oneLabel;
    },
    changeColorOff: function(value) {
      this.currentItem["zeroColor"] = value.substring(1, value.length - 2);
    },
    changeLabelColor: function(value) {
      const { textWait, textOnOff } = this.svgDOMElements;
      const substrColor = value.substring(1, value.length - 2);
      textWait.setAttribute("fill", `#${substrColor}`);
      textOnOff.setAttribute("fill", `#${substrColor}`);
      this.currentItem["labelColor"] = substrColor;
    },
    changeSizeOfSVG: function() {
      changeSvgDimension(this.currentItem);
    }
  }
};
</script>

<style scoped></style>
