import Vue from "vue";
import App from "./App.vue";
//Globally registered components
import LinkedListTableComponent from "@/components/LinkedList/LinkedListTableComponent";
import FixedToolbar from "./components/Toolbar";
//Globally registered components

import "./assets/globalStyles.css";
import router from "./router";
import store from "./vuex_store/store_wrapper";
import wsPlugin from "../global_socket";
import svgAnimationPlugin from "./components/ModemLayoutWizard/svgAnimation/svgAnimationPlugin";
import vuetify from "./plugins/vuetify";
import VueJsonPretty from "vue-json-pretty";
import CKEditor from "ckeditor4-vue";
import "../static/animations.css";
import "../static/style.css";
export const bus = new Vue();

Vue.component("fixed-toolbar", FixedToolbar);
Vue.component("linked-list-table", LinkedListTableComponent);
Vue.component("vue-json-pretty", VueJsonPretty);

let wsConfig = {
  wssUrl: process.env.VUE_APP_WSS_URL,
  connectManually: true,
  //kaç ms sonra tekrar bağlansın
  maxReconnectInterval: 10000
};

//ws bağlantısı için custom vue plugin.
Vue.use(wsPlugin, wsConfig);
Vue.use(svgAnimationPlugin);

Vue.use(CKEditor);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
