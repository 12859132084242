<template>
  <v-row no-gutters>
    <v-col class="col-12">
      <span>{{ indicatorData["label"] }}</span>
    </v-col>
    <v-col class="col-12">
      <span class="body-1 grey--text darken-1" v-if="getInputData.length === 0">{{indicatorData['label']}}
        verisi
        yok</span>
      <v-row v-else class="no-gutters" v-for="(item,i) in getInputData" :key="i">
        <span>- {{item}}</span>
      </v-row>
    </v-col>
<!--<div>Modbus List Item Example {{indicatorData}}
</div>-->
  </v-row>
</template>

<script>
export default {
  name: "modbusListItem.vue",
  props: {
    /* Data as in 0 or 1
     * This prop is going to be the initial data that we receive from ListSetup
     * */
    inputData: {
      type: String,
      required: true
    },
    /*Contains object info*/
    indicatorData: {
      type: Object,
      required: true
    }
  },
  computed: {
    getInputData: function () {
      return JSON.parse(this.inputData)
    }
  },

}
</script>

<style scoped>

</style>
