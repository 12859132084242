<template>
  <v-row no-gutters>
    <v-form class="col-12" ref="single-form" v-model="valid">
      <v-row no-gutters>
        <v-col
            class="pa-2"
            :class="
            $vuetify.breakpoint.name === 'sm' ||
            $vuetify.breakpoint.name === 'xs'
              ? 'col-12'
              : `col-${12 / fields['ColumnCount']}`
          "
            v-for="(fieldsData, fieldIndex) in fields['Fields']"
            :key="fieldIndex"
        >
          <template v-for="field in fieldsData">
            <v-text-field
                v-if="field['ElementType'] === 'text'"
                :key="field['Field']"
                :label="field['Label']"
                :rules="setRule(field, stickyData[field['Field']])"
                v-model="stickyData[field['Field']]"
            />
            <v-text-field
                v-if="field['ElementType'] === 'password'"
                :key="field['Field']"
                :label="field['Label']"
                :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show_password ? 'text' : 'password'"
                @click:append="show_password = !show_password"
                :rules="setRule(field, stickyData[field['Field']])"
                v-model="stickyData[field['Field']]"
            />

            <v-textarea
                v-if="field['ElementType'] === 'textarea'"
                :key="field['Field']"
                :label="field['Label']"
                :rules="setRule(field, stickyData[field['Field']])"
                v-model="stickyData[field['Field']]"
            />

            <v-autocomplete
                v-if="
                field['ElementType'] === 'combo'
              "
                :label="field['Label']"
                :key="field['Field']"
                :items="field['ComboItems']['Elements']"
                item-value="Id"
                item-text="Text"
                :rules="setRule(field, stickyData[field['Field']])"
                :value="Number(stickyData[field['Field']])"
                @change="setValue(field, $event)"
            />

            <interactive-list-component
                :field="field"
                :model="stickyData"
                :interactive-target-value="
                parseInt(stickyData[field['CustomData']['filterField']])
              "
                action-type="duzenle"
                :key="field['Field']"
                v-if="field['ElementType'] === 'interactiveList'"
            />
            <!--            <v-autocomplete
                          v-if="
                            field['ElementType'] === 'combo' &&
                              field['CustomData'].hasOwnProperty('check_scope')
                          "
                          :label="field['Label']"
                          :key="field['Field']"
                          :items="field['ComboItems']['Elements']"
                          item-value="Id"
                          item-text="Text"
                          :rules="setRule(field, stickyData[field['Field']])"
                          readonly
                          :value="stickyData !== null && Number(stickyData[field['Field']])"
                        />-->

            <label-edit-text-component
                :key="field['Field']"
                v-if="field['ElementType'] === 'labelEditText'"
                :field="field"
                :data-label-model="stickyData['data_labels']"
                :data-value-model="stickyData"
                :read-only="false"
                action-type="duzenle"
            />

            <!-- Text Editor -->
            <text-editor-component
                :key="field['Field']"
                v-if="field['ElementType'] === 'editor'"
                :dynamic-m="stickyData"
                :field="field"
            />
            <!-- Text Editor -->

            <file-upload-comp
                :key="field['Field']"
                v-if="field['ElementType'] === 'fileSelectFromServer'"
                :field-item="field"
                :dynamic-m="stickyData"
                v-model="stickyData[field['Field']]"
            ></file-upload-comp>

            <modem-field-selector
                :key="field['Field']"
                v-if="field['ElementType'] === 'modemFieldSelector'"
                action-type="duzenle"
                :field-item="field"
                :dynamic-m="stickyData"
                v-model="stickyData[field['Field']]"
            />

            <p-id-field-selector-component
                action-type="duzenle"
                :key="field['Field']"
                v-if="field['ElementType'] === 'pidFieldSelector'"
                :field-item="field"
                :dynamic-m="stickyData"
            />

            <watch-field-selector-component
                action-type="duzenle"
                :key="field['Field']"
                v-if="field['ElementType'] === 'watchFieldSelector'"
                :field-item="field"
                :dynamic-m="stickyData"
            />

            <i-o-list-component
                :key="field['Field']"
                v-if="field['ElementType'] === 'listConfigWizard'"
                action-type="duzenle"
                :dynamic-m="stickyData"
                :field-item="field"
                :modem-i-d="stickyData['modem_id']"
            />

            <settings-component
                :key="field['Field']"
                v-if="field['ElementType'] === 'setting_config_wizard'"
                action-type="duzenle"
                :dynamic-m="stickyData"
                :field-item="field"
                :modem-i-d="stickyData['modem_id']"
            />

            <modem-layout-wizard-component
                ref="modemLayout"
                :key="field['Field']"
                v-if="field['ElementType'] === 'modemLayoutWizard'"
                :field-item="field"
                action-type="duzenle"
                :dynamic-m="stickyData"
                :modem-i-d="[
                stickyData['modem_id'],
                stickyData[field['CustomData']['label']]
              ]"
            />

            <modem-permissions-comp
                :key="field['Field']"
                v-if="field['ElementType'] === 'ModemSelectList'"
                :id-property="stickyData[field['Field']]"
            />

            <linked-list-table
                v-if="field['ElementType'] === 'linkedTable'"
                :field-item="field"
                :id-filter="stickyData[field['CustomData']['DefaultFilter']]"
                :key="field['CustomData']['SourceTable']"
            />

            <alarm-wizard
                ref="alarmWiz"
                :key="field['Field']"
                v-if="field['ElementType'] === 'alarmwizard'"
                :field-name="field['Field']"
                :sticky-object="stickyData"
                :config-object="JSON.parse(stickyData[field['Field']])"
                action-type="duzenle"
            />

            <v-row
                no-gutters
                :key="field['Field']"
                v-if="field['ElementType'] === 'onlyView'"
            >
              <v-col class="col-12">
                <span>{{ field["Label"] }}</span>
              </v-col>
              <v-col class="col-12">
                <span>-----</span>
              </v-col>
            </v-row>
          </template>
        </v-col>
      </v-row>
    </v-form>
  </v-row>
</template>

<script>
import { setRuleData } from "@/form_stuff/set_form_rules";
import InteractiveListComponent from "@/components/InteractiveList/InteractiveListComponent";
import LabelEditTextComponent from "@/components/LabelEditText/LabelEditTextComponent";
import TextEditorComponent from "@/components/TextEditorComponent/TextEditorComponent";
import FileUploadComp from "@/components/FileSelector/FileUploadComp";
import ModemFieldSelector from "@/components/ModemFieldSelector/ModemFieldSelector";
import pIdFieldSelectorComponent from "@/components/pIdFieldSelector/pIdFieldSelectorComponent";
import watchFieldSelectorComponent from "@/components/watchFieldSelector/watchFieldSelectorComponent";
import IOListComponent from "@/components/EditorUtilityComponents/IOListComponent";
import SettingsComponent from "@/components/EditorUtilityComponents/SettingsComponent";
import ModemLayoutWizardComponent from "@/components/ModemLayoutWizard/ModemLayoutWizardComponent";
import ModemPermissionsComp from "@/components/ErisilebilirModemler/ModemPermissionsComp";
import AlarmWizard from "@/components/AlarmWizard/AlarmWizard";

export default {
  name: "DuzenleForm",
  components: {
    TextEditorComponent,
    InteractiveListComponent,
    LabelEditTextComponent,
    FileUploadComp,
    ModemFieldSelector,
    pIdFieldSelectorComponent,
    watchFieldSelectorComponent,
    IOListComponent,
    SettingsComponent,
    ModemLayoutWizardComponent,
    ModemPermissionsComp,
    AlarmWizard
  },
  data() {
    return {
      valid: false,
      show_password: false,
      fieldValues: {},
      textAlert: "alanına erişmek için önce formu kaydetmelisiniz.",
      dataLabels: {}
    };
  },
  props: {
    fieldsData: {
      type: Array,
      required: false,
      default: () => []
    },
    stickyData: {
      type: Object,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    fields: {
      type: Object,
      required: false
    }
  },
  methods: {
    setValue: function(v, e) {
      const { Field } = v;
      this.stickyData[Field] = e;
    },
    setRule: function(v, fV) {
      return setRuleData(v, fV);
    },
    /*
     * This functions is going to check if form is valid or not.
     * if form is valid then we add two objects that contains sticky values
     * and form values using spread operator. Lastly we are going to emit an
     * event with those values as parameters.
     * */
    validateForm: function() {
      if (this.$refs.hasOwnProperty("alarmWiz")) {
        return (
            this.$refs["single-form"].validate() &&
            this.$refs["alarmWiz"][0].checkWizardValidation()
        );
      }
      return this.$refs["single-form"].validate();
    },
    getFormValues: function() {
      //İnit alarm wizard fields data
      this.$refs.hasOwnProperty("modemLayout") &&
      this.$refs["modemLayout"][0].svgKaydet(true);
      this.$refs.hasOwnProperty("alarmWiz") &&
      this.$refs["alarmWiz"][0].getWizardData();
      //this.stickyData['modem_layout'] = "";
      return {
        ...this.stickyData,
        ...(this.stickyData.hasOwnProperty("data_labels") &&
            Object.keys(this.stickyData["data_labels"]).length > 0 && {
              data_labels: encodeURIComponent(
                  JSON.stringify(this.stickyData["data_labels"])
              ).toString()
            })
      };
    }
  }
};
</script>

<style scoped></style>
