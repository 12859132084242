<template>
  <div>
    <v-dialog
        v-model="dialogLoader"
        width="500"
        persistent
        :hide-overlay="hideOverlay"
    >
      <v-card color="black" dark>
        <v-card-text color="white">
          Yükleniyor...
          <v-progress-linear
              indeterminate
              color="teal"
              class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <dynamic-grid-view
        v-if="Object.keys(gridDataObject).length === 5"
        :grid-object="gridDataObject"
    />
    <StationsNoData
        title="Ekran verilerini bulamadık. 😕"
        subtitle="Tekrar denemek için "
        v-if="Object.keys(gridDataObject).length === 0 && !dialogLoader"
    >
      <template v-slot:custom-component>
        <v-btn @click="sendRequests" fab color="red accent-2">
          <v-icon>refresh</v-icon>
        </v-btn>
      </template>
    </StationsNoData>
  </div>
</template>

<script>
import DynamicGridView from "../components/GridComponent/DynamicGridView";
import store from "@/vuex_store/store_wrapper";
import { mapGetters } from "vuex";
import StationsNoData from "@/components/NoDataViews/NoDataWithSlot";

export default {
  name: "Dashboard",
  components: {
    StationsNoData,
    "dynamic-grid-view": DynamicGridView
  },
  props: {
    /*
     * Consists of AlarmList, ClosedAlarmList, LowAntenna, DisconnectedModems and DisconnectedCards
     * These are the request types we use on Dashboard component.
     * */
    generalManagement: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      unsubscribe: null,
      gridDataObject: {},
      hideOverlay: false,
      dialogLoader: false
    };
  },
  computed: {
    getGeneralManagementRequests: function() {
      return this.generalManagement;
    },
    ...mapGetters({
      getterIsSocketConnected: "storeSocket/getterIsSocketConnected",
      getterSocketMessage: "storeSocket/getterSocketMessage"
    })
  },
  //Pre-fetch data before page loads with beforeRouteEnter navigation guard
  //We want to send the all request beforeRouteEnter so it can get layout data beforehand
  beforeRouteEnter: function(to, from, next) {
    console.log("Route Enter Before");
    next(vm => {
      vm.sendRequests();
    });
  },
  beforeDestroy () {
    this.unsubscribe && this.unsubscribe();
  },
  created() {
    this.unsubscribe = store.subscribe(this.dashboardVuexStoreHandler);
  },
  mounted() {
    console.log(this.gridDataObject);
    //set a default timeout or create a new dynamic component for loading stuff.
    setTimeout(() => {
      this.dialogLoader = false;
      this.hideOverlay = true;
      console.log("Hide Overlay");
    }, 5000);
  },
  methods: {
    dashboardVuexStoreHandler: function(mutation) {
      const { payload, type } = mutation;
      if (type === "storeSocket/on_socket_message") {
        const { command, data } = JSON.parse(payload.data);
        if (
            command === "AlarmList" ||
            command === "ClosedAlarmList" ||
            command === "LowAntenna" ||
            command === "DisconnectedModems" ||
            command === "DisconnectedCards"
        ) {
          this.gridDataObject[command] = data;
          console.log("Grid Data Object => ", this.gridDataObject);
          if (Object.keys(this.gridDataObject).length === 5) {
            this.dialogLoader = false;
            this.hideOverlay = true;
          }
        }
      }
    },
    sendRequests: function() {
      //If socket is connected, send requests.
      //We are not going to check if socket is not connected status in this function because we are doing
      //that check in global beforeEach router hook already.
      if (this.getterIsSocketConnected) {
        this.dialogLoader = true;
        this.getGeneralManagementRequests.forEach(request => {
          this.sendMessageToWS(request);
        });
      }
    },
  }
};
</script>

<style>
#unique_circle {
  transition: fill 1s ease;
}

.style_1 {
  background: red !important;
}

.style_2 {
  background: green !important;
}

.default_lamb_class {
  fill: #565656;
  transition: fill 0.2s ease;
}

.generator_green_lamb {
  fill: #00ca4e;
  transition: fill 0.2s ease;
}

.generator_red_lamb {
  fill: #ff605c;
  transition: fill 0.2s ease;
}

.generator_yellow_lamb {
  fill: #ffbd44;
  transition: fill 0.2s ease;
}

.fixed_button {
  display: inline-block;
  height: 100vh;
  z-index: 99;
  text-decoration: none;
  transition: background-color 0.2s ease;
  background: #3f51b5;
}

.fixed_button:hover {
  background: #2aa960;
  transition: all 0.2s ease;
}

.fixed_button i {
  height: 100%;
  vertical-align: middle;
  transition: rotate 0.2s ease;
}

.margin_right_for_button {
  margin-right: 50px;
}

.right_menu {
  position: fixed;
  height: 100vh;
  right: 0;
  width: 32px;
  background: coral;
  transition: width 0.2s ease;
}

.vue-grid-item {
  /*background: rgb(247, 155, 255);*/
}

#grid_item_card_content {
  height: 100% !important;
}

.custom_button {
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  width: 50px;
  right: 0;
  padding: 5px 0;
  background-color: #ff605c;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border: 2px solid rgba(0, 0, 0, 0.5);

  transform: translateX(20px);
  transition: 0.2s all ease;
  z-index: 2;
}

.custom_button_icon {
  margin-left: 5px;
}

.custom_button:hover {
  transform: translateX(5px);
  transition: 0.2s all ease;
}

.rotate_icon_open {
  transform: rotate(0deg);
  transition: 0.8s all ease;
}

.rotate_icon_close {
  transform: rotate(180deg);
  transition: 0.8s all ease;
}

.modem_group_temp {
  right: 0;
  top: 150px;
  position: fixed;
  z-index: 2;
}

.hide_box {
  transition: 0.8s ease-in;
  transform: translateX(425px);
}

.show_box {
  transition: 0.8s ease-in;
  transform: translateX(0px);
}
</style>
