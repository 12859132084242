<template>
  <div>
    <v-simple-table v-if="dataType.length !== 0" class="simpleTableClass">
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left" v-for="(name, index) in Headers" :key="index">
            {{ name }}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in tableData" :key="i">
          <td>{{ item["modem_name"] }}</td>
          <td>{{ item["card_name"] }}</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <slot v-else name="no-data-view"></slot>
  </div>
</template>

<script>
export default {
  name: "DisconnectedCards",
  props: {
    tableData: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      Headers: ["İstasyon Adı", "Kart Numarası"]
    };
  },
  computed: {
    dataType: function() {
      return this.tableData === null ? [] : this.tableData;
    }
  },
  methods: {
    setAutoSize: function () {
      this.$nextTick(() => {
        this.$parent.autoSize();
      });
    }
  }
};
</script>

<style scoped></style>
