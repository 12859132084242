var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-0 align-self-center",attrs:{"cols":"6"}},[_c('span',[_vm._v(_vm._s(_vm.indicatorData["label"]))])]),(_vm.indicatorData['input_ui'] === '1')?_c('v-col',{staticClass:"pa-0",attrs:{"cols":"6"}},[_c('div',{staticClass:"tip1Indicator",style:({
        backgroundColor:
          _vm.getInputValue === '1'
            ? ("#" + (_vm.indicatorData['one_color']))
            : ("#" + (_vm.indicatorData['zero_color'])),
        transition: 'background-color .4s ease-in-out'
      })},[_c('div',{staticClass:"indicatorOverlay"})])]):(_vm.indicatorData['input_ui'] === '0')?_c('v-col',{staticClass:"pa-0",attrs:{"cols":"6"}},[_c('p',{staticClass:"mb-0",style:({
        color:
          _vm.getInputValue === '1'
            ? ("#" + (_vm.indicatorData['one_color']))
            : ("#" + (_vm.indicatorData['zero_color']))
      })},[_vm._v(" "+_vm._s(_vm.getInputValue)+" ")])]):(_vm.indicatorData['input_ui'] === '2')?_c('v-col',{staticClass:"pa-0",attrs:{"cols":"6"}},[_c('v-chip',{staticClass:"body-1 font-weight-medium",attrs:{"color":_vm.getInputValue === '1'
            ? ("#" + (_vm.indicatorData['one_color']))
            : ("#" + (_vm.indicatorData['zero_color'])),"text-color":"white"}},[_vm._v(" "+_vm._s(_vm.getInputValue === "1" ? _vm.indicatorData['one_label'] : _vm.indicatorData['zero_label'])+" ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }