<template>
  <div ref="tabWrapper">
    <v-flex class="toolbar_bar_sticky">
      <v-toolbar dense>
        <v-tabs
          hide-slider
          :mobile-break-point="800"
          show-arrows
          v-model="tab"
          :right="true"
          background-color="dynamicTableToolbar"
          color="modemTabColor"
          active-class="modemTabActive"
          dark
          icons
          @change="
            $emit('tabChanged', {
              modem_id: getModemInformation.modem_id,
              tab
            })
          "
        >
          <v-btn
            v-if="hasCloseButton"
            icon
            dark
            class="close_btn"
            @click="closeDialog"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title
            class="modemTabToolbarTitleColor--text"
            style="margin:7px;"
            >Modem İsmi:
            {{ getModemInformation["modem_name"] }}</v-toolbar-title
          >
          <v-divider vertical></v-divider>

          <v-chip
            class="ma-2 subtitle-1 font-weight-medium"
            :color="modemUpdateColor"
            label
            text-color="white"
          >
            <v-icon left>
              schedule
            </v-icon>
            {{ modemUpdateTime }}
          </v-chip>

          <v-spacer></v-spacer>
          <v-tabs-slider></v-tabs-slider>

          <!-- tab scada svg -->
          <v-tab
            v-if="getTabLayoutInformation['haveScada']"
            :href="'#tab_scada' + getModemInformation['modem_name']"
          >
            <v-icon>select_all</v-icon>
          </v-tab>
          <!-- tab scada svg -->

          <!-- tab list setup -->
          <v-tab
            :href="`#tab_list_${getModemInformation['modem_id']}`"
            v-if="getTabLayoutInformation['listsetup'] !== ''"
          >
            <v-icon>list</v-icon>
          </v-tab>
          <!-- tab list setup -->

          <!-- tab info -->
          <v-tab href="#tab_info" v-if="getTabLayoutInformation['haveInfo']">
            <v-icon>info</v-icon>
          </v-tab>
          <!-- tab info -->

          <!-- tab alarm -->
          <v-tab href="#tab_alarm">
            <!--            <v-badge
                            color="pink"
                            dot
                        >
                          Item One
                        </v-badge>-->
            <v-badge
              color="error"
              :content="updateSingleAlarmData.length"
              :value="updateSingleAlarmData.length"
              :overlap="true"
            >
              <template v-if="updateSingleAlarmData.length > 0" v-slot:badge>{{
                updateSingleAlarmData.length
              }}</template>
              <template v-slot:default>
                <v-icon>notification_important</v-icon>
              </template>
            </v-badge>
          </v-tab>
          <!-- tab alarm -->

          <!-- tab settings -->
          <v-tab
            href="#tab_config"
            v-if="getTabLayoutInformation['haveConfig']"
          >
            <v-icon>settings</v-icon>
          </v-tab>
          <!-- tab settings -->

          <!-- tab camera -->
          <v-tab
            href="#tab_camera"
            v-if="getTabLayoutInformation['cameraCount'] > 0"
          >
            <v-icon>camera</v-icon>
          </v-tab>
          <!-- tab camera -->
        </v-tabs>
      </v-toolbar>
    </v-flex>
    <v-tabs-items active-class="gridItemBackground" v-model="tab">
      <!-- tab scada svg -->
      <v-tab-item
        v-if="getTabLayoutInformation['svglayout'] !== ''"
        :value="'tab_scada' + getModemInformation['modem_name']"
      >
        <scada-tab
          v-if="svgHTML !== null"
          :modem-id="modemData['modem_id']"
          :data-property="getDatas"
          :svg-components="getSVGLayout"
          :svg-html="svgHTML"
          parent-name="ModemTabLayout"
        />
      </v-tab-item>
      <!-- tab scada svg -->

      <!-- tab list setup -->
      <v-tab-item
        v-if="getTabLayoutInformation['listsetup'] !== ''"
        :value="`tab_list_${getModemInformation['modem_id']}`"
      >
        <list-setup
          :updated-data-property="updatedProcessData"
          :data-property="getDatas"
          :list-setup="getModemListSetup"
          :genislikWidth="genislik_w"
        />
      </v-tab-item>
      <!-- tab list setup -->

      <!-- tab info -->
      <v-tab-item value="tab_info">
        <info-view :modemInfo="getTabLayoutInformation['modemyazi']" />
      </v-tab-item>
      <!-- tab info -->

      <!-- tab alarm -->
      <v-tab-item value="tab_alarm">
        <single-alarm :updated-alarm-data="updateSingleAlarmData" />
      </v-tab-item>
      <!-- tab alarm -->

      <!-- tab settings -->
      <v-tab-item value="tab_config">
        <v-card flat>
          <v-card-text>Settings</v-card-text>
        </v-card>
      </v-tab-item>
      <!-- tab settings -->

      <!-- Tab Camera -->
      <v-tab-item
        style="overflow-y: scroll !important;
    height: 100% !important;"
        value="tab_camera"
        v-if="getTabLayoutInformation['cameraCount'] > 0"
      >
        <v-card flat>
          <camera-modem
            :camera-count="getTabLayoutInformation['cameraCount']"
            :camera-config="getTabLayoutInformation['cameraconfig']"
            :cameras-data="getCameras"
          />
        </v-card>
      </v-tab-item>
      <!-- Tab Camera -->
    </v-tabs-items>
  </div>
</template>

<script>
import store from "@/vuex_store/store_wrapper";
import ScadaTab from "@/components/GridComponent/ModemGridNew/ModemGridTabLayoutComponents/ScadaTab";
import ListSetup from "./ModemGridTabLayoutComponents/ListSetup";
import InfoView from "./ModemGridTabLayoutComponents/InfoView";
import SingleAlarm from "./ModemGridTabLayoutComponents/SingleAlarm";
import CameraModem from "@/components/GridComponent/ModemGridNew/ModemGridTabLayoutComponents/CameraModem";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import { svgContainer } from "@/custom_directives/custom_directive";

export default {
  name: "ModemTabLayout",
  directives: {
    svgContainer
  },
  components: {
    "scada-tab": ScadaTab,
    "list-setup": ListSetup,
    "info-view": InfoView,
    "single-alarm": SingleAlarm,
    "camera-modem": CameraModem
  },
  props: {
    modemData: {
      type: Object,
      required: true
    },
    genislik_w: {
      type: Number,
      required: true
    },
    hasCloseButton: Boolean
  },
  data() {
    return {
      tab: null,
      svgHTML: null,
      updatedProcessData: {},
      updateSingleAlarmData: [],
      unsubscribe: null,
      modemUpdateTime: null,
      customInterval: null,
      modemUpdateColor: "green darken-1"
    };
  },
  computed: {
    ...mapGetters({
      getterSVGScaleInfo: "storeSVGScale/getterSVGScaleInfo",
      getDashboardAlarms: "storeSocket/getDashboardAlarms"
    }),
    /*
     * Grid height, width, y, x
     * */
    getGridItemProperties: function() {
      const { x: gridX, y: gridY, h: gridH, w: gridW } = this.modemData;
      return { gridX, gridY, gridH, gridW };
    },
    /*
     * modem information => modem_id , modem_name , son_guncelleme
     * */
    getModemInformation: function() {
      const { modem_id, modem_name, son_guncelleme } = this.modemData;
      return { modem_id, modem_name, son_guncelleme };
    },
    /*
     * Data for ListSetup.vue
     * */
    getModemListSetup: function() {
      const { list_setup } = this.modemData;
      if (list_setup === "") {
        return {};
      } else {
        return JSON.parse(decodeURIComponent(list_setup));
      }
    },
    /*
     * Tab Information such as haveScada, haveInfo, haveConfig....
     * */
    getTabLayoutInformation: function() {
      const { Layout } = this.modemData;
      return Layout;
    },
    getAlarms: function() {
      const { Layout } = this.modemData;
      const { alarms } = Layout;
      return alarms;
    },
    /*
     * SVG layout for dynamic scada component
     * */
    getSVGLayout: function() {
      const { Layout } = this.modemData;
      const { svglayout } = Layout;
      return JSON.parse(decodeURIComponent(svglayout));
    },
    /*
     * Get Datas.Data for List Setup and SVG initial update
     * */
    getDatas: function() {
      const { Data } = this.modemData;
      return Data;
    },
    /*
     * Get Cameras Object
     * */
    getCameras: function() {
      const { Data } = this.modemData;
      const { Cameras } = Data;
      return Cameras;
    },
    /*
     * Get & Set SVG HTML
     * */
    svgHTMLGetSet: {
      get: function() {
        return this.svgHTML;
      },
      set: function(data) {
        this.svgHTML = data;
      }
    }
  },
  watch: {
    getTabLayoutInformation: function(value) {
      //set scada tab if svg layout exists
      if (value["svglayout"] !== "") {
        this.tab = "tab_scada" + this.getModemInformation["modem_name"];
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe && this.unsubscribe();
  },
  created() {
    if (this.getAlarms !== null) {
      this.updateSingleAlarmData = this.getAlarms;
    } else {
      //console.log("Error öncesi ", this.getDashboardAlarms);
      if (this.getDashboardAlarms !== null) {
        this.updateSingleAlarmData = this.getDashboardAlarms.filter(v => {
          if (v.modem_id === this.getModemInformation.modem_id) {
            return v;
          }
        });
      } else {
        this.updateSingleAlarmData = [];
      }
    }
    this.unsubscribe = store.subscribe(this.storeHandler);
    // if haveScada = true; get svg by modem_id
    if (this.getTabLayoutInformation["haveScada"]) {
      this.getSVGFromStations();
    }
    // dirty fix, change later.
    if (this.hasCloseButton) {
      this.assignSVGScale({
        itemHeight: window.innerHeight - 200,
        itemWidth: window.innerWidth
      });
    }
  },
  mounted() {
    this.modemUpdateTime = this.getModemInformation.son_guncelleme;
    this.startInterval();
  },
  methods: {
    setModemUpdateTime: function(TimeStamp) {
      this.customInterval !== null && clearInterval(this.customInterval);
      this.modemUpdateTime = new Date(TimeStamp * 1000).toLocaleString("tr-TR");
      this.modemUpdateColor = "green darken-1";
      this.startInterval();
    },
    startInterval: function() {
      const timeNow = new Date();
      this.customInterval = setInterval(() => {
        const diff = Math.abs(new Date() - timeNow);
        console.log("DIFF", diff);
        const min = Math.floor(diff / 1000 / 60);
        console.log("MIN", min);
        if (min > 1) {
          console.log("Modem Did Not Get Any Updates Yet");
          this.modemUpdateColor = "red";
        }
      }, 60 * 5000);
    },
    ...mapActions({
      assignSVGScale: "storeSVGScale/assignSVGScale"
    }),
    /*
     * This store handler is responsible for distribution of ProcessData response
     * to ListSetup & Scada Components. The data that we received in this handler
     * is going to trickle down to every single child component that has correct property bindings
     * */
    storeHandler: function(mutation) {
      const { type, payload } = mutation;
      if (type === "storeSocket/on_socket_message") {
        const { data, command } = JSON.parse(payload.data);
        if (command === "ProcessData") {
          const { ModemId, Datas, TimeStamp } = data;
          const { modem_id } = this.getModemInformation;
          // check for modem id and update
          if (modem_id === ModemId) {
            console.log("Store Process Data Received Modem Tab Layout", Datas);
            if (Object.keys(Datas).length > 0) {
              this.updatedProcessData = {
                ModemId,
                Datas
              };
            }
            this.setModemUpdateTime(TimeStamp);
          }
        } else if (command === "ModemUpdateTime") {
          const { ModemId, TimeStamp } = data;
          const { modem_id } = this.getModemInformation;
          if (modem_id === ModemId) {
            console.log(
              "Modem Update Time in Modem Tab Layout",
              ModemId,
              TimeStamp
            );
            this.setModemUpdateTime(TimeStamp);
          }
        }
      }else if (type === 'storeSocket/on_set_dashboard_alarms') {
        //console.log("ALARM LIST PAYLOAD IS", payload)
        if (payload.length > 0) {
          this.updateSingleAlarmData =  payload.filter(v => v.modem_id === this.getModemInformation.modem_id);
        }
      }
    },
    closeDialog: function() {
      this.$emit("mapDialogStatus", false);
    },
    getSVGFromStations: async function() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_MODEM_BOUND_SVG_URL}modem_${this.getModemInformation["modem_id"]}.svg`
        );
        this.svgHTML = await response.data;
      } catch (e) {
        console.warn("There is an error getting svg html !", e);
      }
    }
  }
};
</script>

<style scoped>
.modem_title {
  color: white;
  position: absolute;
}
.toolbar_bar_sticky {
  position: sticky !important;
  position: -webkit-sticky !important;
  top: 0 !important;
  z-index: 1 !important;
}

#scadaSvgElement {
  cursor: pointer;
}

.modemTabActive {
  background-color: rgba(255, 255, 255, 0.15) !important;
}
</style>
