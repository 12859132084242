<template>
  <div :style="'height:' + (getterSVGScaleInfo.itemHeight + 20) + 'px;' ">
    <v-row no-gutters>
      <v-col v-for="cnf in cameraConfig" :key="cnf['camera_id']" class="col-6">
        <div v-if="cnf['type'] === 0">
            <physical-camera :conf-data="cnf" :media-data="camerasData[cnf['camera_id']]" />
        </div>
        <div v-if="cnf['type'] === 1">
            <iframe-component :conf-data="cnf" :media-data="camerasData[cnf['camera_id']]"/>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PhysicalCamera from "@/components/GridComponent/ModemGridNew/ModemGridTabLayoutComponents/CameraChildComponents/PhysicalCamera";
import IFrameComponent from "@/components/GridComponent/ModemGridNew/ModemGridTabLayoutComponents/CameraChildComponents/IFrameComponent";
import {mapGetters} from "vuex";

export default {
  name: "CameraModem",
  components: {
    "physical-camera": PhysicalCamera,
    "iframe-component": IFrameComponent
  },
  props: {
    cameraCount: {
      type: Number,
      required: true
    },
    cameraConfig: {
      type: Array,
      required: true
    },
    camerasData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getterSVGScaleInfo: "storeSVGScale/getterSVGScaleInfo"
    }),
  },
  created() {
  },
  methods: {}
};
</script>

<style scoped></style>
