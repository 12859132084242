<template>
  <div>
    <form-toolbar
      @toolbarEvent="toolbarEvent"
      :form-toolbar="getToolbar"
      :icon-and-title="getIconAndTitle"
    />
    <v-row no-gutters>
      <v-tabs
        show-arrows
        v-model="tab"
        background-color="indigo darken-3 "
        class="elevation-2"
        dark
      >
        <v-tabs-slider></v-tabs-slider>

        <v-tab
          v-for="(tab, i) in getTabs"
          :key="`key_${i}}`"
          :href="`#tab${i}`"
        >
          {{ tab.Label === "" ? getIconAndTitle.title : tab.Label }}
        </v-tab>

        <v-tab-item
          v-for="(tab, i) in getTabs"
          :key="`tab_item_${tab['Label']}`"
          :value="`tab${i}`"
        >
          <incele-form
            :sticky-data="formData"
            :ref="`form_tab${i}`"
            :fields-data="tab"
          />
        </v-tab-item>
      </v-tabs>
    </v-row>
  </div>
</template>

<script>
import FormToolbar from "@/components/AppbarUtilityComponents/Forms/FormToolbar";
import InceleForm from "@/components/AppbarUtilityComponents/Forms/InceleForm";
export default {
  components: {
    FormToolbar,
    InceleForm
  },
  name: "incele",
  props: {
    formLayout: {
      type: Object,
      required: false
    },
    formData: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      tab: null
    };
  },
  computed: {
    getTarget: function() {
      return this.formLayout.target;
    },
    getToolbar: function() {
      return this.formLayout.data.toolbar;
    },
    getIconAndTitle: function() {
      return {
        icon: this.formLayout.data.icon,
        title: this.formLayout.data.title
      };
    },
    getTabs: function() {
      return this.formLayout.data.tabs;
    }
  },
  methods: {
    toolbarEvent: function(ev) {
      console.log("Button Clicked", ev);
      this.$emit("backPressed");
    }
  }
};
</script>

<style scoped></style>
