<template>
  <div id="app">
    <ckeditor :disabled="readOnly" :editorData="dynamicM[field['Field']]" v-model="dynamicM[field['Field']]"></ckeditor>
  </div>
</template>

<script>
export default {
  name: "TextEditorComponent",
  data() {
    return {
      textEditor:"",
      dynamicModel:{}
    };
  },
  props:{
    dynamicM: {
      type: Object,
      required: false
    },
    field: {
      type: Object,
      required: false
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
};
</script>

<style scoped></style>
