var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('custom-dialog-alert',{attrs:{"eventPositive":"dtFormSave","eventNegative":"dtFormCancel","dynamic-prop":_vm.formDialogProp},on:{"dtFormSave":_vm.dtFormSave,"dtFormCancel":_vm.dtFormCancel}}),(_vm.createSnackbar.visible)?_c('v-snackbar',{attrs:{"color":_vm.createSnackbar.color,"timeout":5000},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":"","fab":"","color":"white","text":""},on:{"click":function($event){_vm.createSnackbar.visible = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("clear")])],1)]}}],null,false,3569827562),model:{value:(_vm.createSnackbar.visible),callback:function ($$v) {_vm.$set(_vm.createSnackbar, "visible", $$v)},expression:"createSnackbar.visible"}},[_vm._v(" "+_vm._s(_vm.createSnackbar.message)+" ")]):_vm._e(),_c('basic-dialog',{attrs:{"dialog-object":_vm.basicDialogObject},on:{"dialogDismissed":_vm.basicDialogDismissed}}),(_vm.showTableForm)?_c('div',[_c(_vm.actionType,{tag:"component",attrs:{"form-layout":_vm.formResponse.layout,"form-data":_vm.formResponse.data,"socket-request":_vm.socketRequest['payload']},on:{"backPressed":_vm.formBackPressed}})],1):_c('div',[_c('v-app-bar',{staticClass:"table_toolbar",attrs:{"height":"auto","extended":false,"color":"dynamicTableToolbar"}},[_c('v-col',{staticClass:"col-12"},[_c('v-row',{staticClass:"align-self-center justify-space-between",attrs:{"no-gutters":""}},[_c('div',{staticClass:"d-flex align-self-center"},[_c('v-toolbar-title',{staticClass:"pl-2 white--text"},[_c('v-icon',{staticClass:"pr-2",attrs:{"color":"white"}},[_vm._v("icon-icon-"+_vm._s(_vm.tableLayoutData.icon))]),_vm._v(" "+_vm._s(_vm.tableLayoutData.title))],1)],1),_c('v-spacer'),_c('div',{staticClass:"d-flex align-self-center"},[_c('filter-component',{attrs:{"filter-data":_vm.tableLayoutData.filters,"filter-target":_vm.socketRequest.payload.Target,"badge-count":_vm.socketRequest.payload.Data.Filters.length},on:{"filterEvent":_vm.filterEvent}})],1),_c('div',{staticClass:"align-self-center"},[(_vm.toolbarButtons !== undefined)?_c('toolbar-buttons',{attrs:{"button-props":_vm.toolbarButtons,"is-speed-dial":_vm.$vuetify.breakpoint.name !== 'lg' &&
                  _vm.$vuetify.breakpoint.name !== 'xl'},on:{"toolbarEvent":_vm.tableUtilityEvent}}):_vm._e()],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('pagination',{attrs:{"total-page":_vm.computeTotalPage,"page-number":_vm.pageNumber},on:{"toFirst":_vm.toFirst,"toLast":_vm.toLast,"toPrevious":_vm.toPrevious,"toNext":_vm.toNext,"limitChanged":_vm.limitChanged}})],1)],1)],1),(_vm.socketRequest.payload.Data.Filters.length > 0)?_c('chips-filter',{attrs:{"filters":_vm.socketRequest.payload.Data.Filters,"filter-fields":_vm.tableLayoutData.filters},on:{"removeAllFilters":_vm.removeAllFilters,"removeFilter":_vm.removeFilter}}):_vm._e(),_c('v-col',{staticClass:"col-12"},[(!_vm.tableDataAvailable)?_c('v-skeleton-loader',_vm._b({attrs:{"type":"table","loading":""}},'v-skeleton-loader',_vm.attrs,false)):_vm._e()],1),(_vm.tableDataAvailable)?_c('v-col',{staticClass:"simple_table_wrapper",attrs:{"sm":"auto","md":"auto","lg":"auto","xl":"auto"}},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',_vm._l((_vm.tableLayoutData['columns']),function(column,colIndex){return _c('th',{key:column['Field'],staticClass:"text-left"},[_vm._v(" "+_vm._s(column["Label"])+" "),_vm._t("default",[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"black","right":""},on:{"click":function($event){return _vm.sortTable(_vm.sortingProps[colIndex], colIndex)}}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.sortingProps[colIndex]["Icon"]))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(column["Label"] + " " + _vm.sortingProps[colIndex]["Tooltip"]))])])])],2)}),0)]),_c('tbody',_vm._l((_vm.tableRowData),function(row,rowIndex){return _c('tr',{key:rowIndex,staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.clickRowEvent(row)},"dblclick":function($event){return _vm.dblClickRowEvent(row)}}},_vm._l((row),function(value,valueIndex){return _c('td',{key:valueIndex},[(
                    _vm.tableLayoutData['columns'][valueIndex]['ColumnType'] ===
                      'checkbox'
                  )?_vm._t("default",[_c('v-checkbox',{staticClass:"mx-2",attrs:{"value":row,"checked":_vm.tableRowModel.includes(row)},model:{value:(_vm.tableRowModel),callback:function ($$v) {_vm.tableRowModel=$$v},expression:"tableRowModel"}})]):(
                    _vm.tableLayoutData['columns'][valueIndex]['ColumnType'] ===
                      'radio'
                  )?_vm._t("default",[_c('v-radio-group',{attrs:{"multiple":""},model:{value:(_vm.tableRowModel),callback:function ($$v) {_vm.tableRowModel=$$v},expression:"tableRowModel"}},[_c('v-radio',{staticClass:"mx-2",attrs:{"value":row}})],1)]):_vm._t("default",[_c('span',[_vm._v(_vm._s(value))])])],2)}),0)}),0)]},proxy:true}],null,true)})],1):_vm._e(),_c('v-row',{staticClass:"no-gutters",staticStyle:{"width":"100%"}},[(_vm.computeTotalPage !== 1)?_c('v-slider',{staticClass:"pl-12 pr-12 col-12",attrs:{"hide-details":"","value":_vm.sliderNumber,"step":_vm.stepInterval,"ticks":"","max":_vm.sliderTotal,"min":"1","thumb-label":"","tick-size":"8"},on:{"click":_vm.sliderClickEvent},model:{value:(_vm.sliderNumber),callback:function ($$v) {_vm.sliderNumber=$$v},expression:"sliderNumber"}}):_vm._e()],1),_c('div',{staticClass:"bottomFilterDefaultClass",class:_vm.defaultBottomFilter},[_c('v-card',{attrs:{"color":"indigo px-2"}},[_c('v-row',{staticClass:"align-center"},[_c('filter-component',{staticClass:"ml-2 mr-2",attrs:{"filter-data":_vm.tableLayoutData.filters,"filter-target":_vm.socketRequest.payload.Target}}),_c('v-divider',{attrs:{"dark":"","vertical":"","inset":""}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }