import Vue from "vue";

export var dirColumn = Vue.directive("columnMode", {
  bind(el, binding) {
    if (binding.value === 0) {
      el.className = "col-12 col-sm-12";
    } else if (binding.value === 2) {
      el.className = "col-12 col-md-6";
    }
  }
});

const setSVGAttributes = (val, el) => {
  let { svgViewboxH, svgViewboxW, getterSVGScaleInfo } = val;
  let { itemHeight, itemWidth } = getterSVGScaleInfo;

  const svgElement = el.getElementsByTagName("svg")[0];
  svgElement.setAttribute("width", `${itemWidth}`);
  svgElement.setAttribute("height", `${itemHeight}`);
  svgElement.setAttribute("viewBox", `0 0 ${svgViewboxW} ${svgViewboxH}`);
  svgElement.setAttribute("preserveAspectRatio", "xMidYMid meet");
};

//directive for setting svg height width and viewBox
export const svgContainer = Vue.directive("svgDimens", {
  bind(el, binding) {
    console.log("DIMEN", binding.value);
    setSVGAttributes(binding.value, el);
  },
  update(el, binding) {
    setSVGAttributes(binding.value, el);
  }
});
