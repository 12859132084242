<template>
  <div>
    <v-menu
        ref="cpRef"
        v-model="colorMenu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-x
        min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
            :label="label"
            prepend-icon="event"
            readonly
            v-on="on"
            return-object
            v-model="colorLocal"
        >
          <v-icon :color="colorLocal" slot="prepend">
            mdi-invert-colors
          </v-icon>
        </v-text-field>
      </template>
      <v-color-picker
          v-model="colorLocal"
          @input="changeColor"
      ></v-color-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "ColorPicker",
  data() {
    return {
      colorMenu: false,
      colorLocal: ""
    }
  },
  props: {
    colorData: {
      type: String,
      required: true,
    },
    field: {
      type: Object,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: ""
    },
    coloringType: {
      type: String,
      required: false,
      default: 'fill'
    }
  },
  created () {
    this.colorLocal = this.colorData;
  },
  methods: {
    changeColor: function (value) {
      console.log("Color Changed", value);
      //const substrColor = value.substring(1, value.length - 2);
      this.$emit('colorSelected',{
        value,
        field: this.field,
        type: this.coloringType
      })
    }
  }
}
</script>

<style scoped>

</style>
