<template>
  <v-dialog persistent width="100%" v-model="dialogData.dialog">
    <v-card>
      {{ getConfig }}
      <div class="video_wrapper">
        <video ref="streamVideoRef" width="100%" height="400" autoplay></video>
        <canvas id="canvas" width="100%" height="400"></canvas>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="closeModal">
          Kapat
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "StreamComponent",
  props: {
    dialogData: {
      type: Object,
      required: true
    }
  },
  computed: {
    getConfig: function() {
      const { confData } = this.dialogData;
      return confData;
    }
  },
  data() {
    return {};
  },
  mounted() {
    this.startStream();
  },
  methods: {
    startStream: function() {
      const videoEl = this.$refs["streamVideoRef"];
      /*eslint-disable*/
      const vUrl = window.URL || window.webkitURL;

      navigator.getMedia =
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia;

      navigator.getMedia(
        {
          video: true,
          audio: false
        },
        (stream) => {
          console.log("Stream", stream)
          videoEl.src = vUrl.createObjectURL(stream);
          videoEl.play();
        },
        (err) => {
          console.log("err",err)
        }
      );

      console.log("Ref Video", videoEl);
    },
    closeModal: function() {
      console.log("close modal");
      this.$emit("close");
    }
  }
};
</script>

<style scoped>
.video_wrapper {
  width: 100%;
  height: 400px;
  border: 2px solid silver;
}
</style>
