<template
  ><p>depo std</p>
</template>

<script>

export default {
  name: "depoStd",
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {
  }
};
</script>

<style scoped></style>
