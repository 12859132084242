<template>
    <v-overlay v-model="dynamicProp" :value="dynamicProp">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
</template>

<script>
    export default {
        name: "OverlayComponent",
        props: {
            dynamicProp: Boolean
        },
        data() {
            return {
                overlay:false
            }
        },
        watch: {
            dynamicProp: function (value) {
                console.log("VALUEE OVEFLAY",value);
                this.overlay = value;
            }
        }
    }
</script>

<style scoped>

</style>