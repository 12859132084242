<template>
  <div v-if="parentName !== 'ModemTabLayout'">
    <v-row no-gutters>
      <v-text-field
        label="Genişlik"
        v-model="currentItem['genislik']"
        @input="changeSizeOfSVG"
      />
    </v-row>
    <v-row no-gutters>
      <v-text-field
        label="Etiket"
        v-model="currentItem['etiketText']"
        @input="changeLabel"
      />
    </v-row>
    <v-row no-gutters>
      <v-menu
        ref="cpRef"
        v-model="colorMenuEtiket"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-x
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            label="Etiket Renk"
            prepend-icon="event"
            readonly
            v-on="on"
            return-object
            v-model="currentItem['etiketColor']"
          >
            <v-icon :color="`#${currentItem['etiketColor']}`" slot="prepend">
              mdi-invert-colors
            </v-icon>
          </v-text-field>
        </template>
        <v-color-picker
          v-model="currentItem['etiketColor']"
          @input="changeColorEtiket"
        ></v-color-picker>
      </v-menu>
    </v-row>
    <v-row no-gutters>
      <v-menu
          ref="cpRef"
          v-model="colorMenuBase"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-x
          min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
              label="Arkaplan Renk"
              prepend-icon="event"
              readonly
              v-on="on"
              return-object
              v-model="currentItem['baseBgColor']"
          >
            <v-icon :color="`#${currentItem['baseBgColor']}`" slot="prepend">
              mdi-invert-colors
            </v-icon>
          </v-text-field>
        </template>
        <v-color-picker
            v-model="currentItem['baseBgColor']"
            @input="changeColorBaseBg"
        ></v-color-picker>
      </v-menu>
    </v-row>
    <v-row no-gutters>
      <v-menu
          ref="cpRef"
          v-model="colorMenuBaseStroke"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-x
          min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
              label="Çerçeve Renk"
              prepend-icon="event"
              readonly
              v-on="on"
              return-object
              v-model="currentItem['baseStrokeColor']"
          >
            <v-icon :color="`#${currentItem['baseStrokeColor']}`" slot="prepend">
              mdi-invert-colors
            </v-icon>
          </v-text-field>
        </template>
        <v-color-picker
            v-model="currentItem['baseStrokeColor']"
            @input="changeColorBaseStroke"
        ></v-color-picker>
      </v-menu>
    </v-row>
  </div>
</template>

<script>
import { changeSvgDimension } from "@/components/ModemLayoutWizard/ModemLayoutSettings/svgComponentUtils/svgUtilFunctions";

export default {
  name: "elemanEtiket",
  props: {
    currentItem: {
      type: Object,
      required: false
    },
    modemID: {
      type: [Array, Number],
      required: false
    },
    //from modem tab layout
    parentName: String,
    svgItemObject: {
      type: Object,
      required: false
    },
    listSetup: Object,
    dataProperty: Object
  },
  data() {
    return {
      colorMenuEtiket: false,
      colorMenuBase: false,
      colorMenuBaseStroke: false,
      svgDOMElements: {}
    };
  },
  //const { width } = baslikTSpan.getBBox();
  mounted() {
    this.svgDOMElements = this.registerSVGObject();
  },
  methods: {
    registerSVGObject: function() {
      const { id } =
        this.parentName === "ModemTabLayout"
          ? this.svgItemObject
          : this.currentItem;
      const svgBase = document.getElementById(id);
      const elemanG = svgBase.querySelector("#eleman_etiket");
      const elemanBase = elemanG.querySelector("#eleman_etiket_base");
      const elemanLabel = elemanG.querySelector("#eleman_etiket_label");

      return {
        svgBase,
        elemanG,
        elemanBase,
        elemanLabel
      };
    },
    changeLabel: function() {
      const { svgBase, elemanLabel, elemanBase } = this.svgDOMElements;
      const tspan = elemanLabel.getElementsByTagName("tspan")[0];
      const svg = svgBase.getElementsByTagName("svg")[0];
      tspan.innerHTML = this.currentItem["etiketText"];
      const { width } = tspan.getBBox();
      elemanBase.setAttribute("width", width + 23);
      // +4 for stroke of base rectangle
      svg.setAttribute("viewBox", `0 0 ${width + 27} 65`);
      svg.setAttribute("enable-background", `0 0 ${width + 27} 65`);
    },
    changeColorEtiket: function (value) {
      const { elemanLabel } = this.svgDOMElements;
      const substrColor = value.substring(1, value.length - 2);
      elemanLabel.setAttribute('fill', `#${substrColor}`)
      this.currentItem['etiketColor'] = substrColor;
    },
    changeColorBaseBg: function (value) {
      const { elemanBase } = this.svgDOMElements;
      const substrColor = value.substring(1, value.length - 2);
      elemanBase.setAttribute('fill', `#${substrColor}`)
      this.currentItem['baseBgColor'] = substrColor;
    },
    changeColorBaseStroke: function (value) {
      const { elemanBase } = this.svgDOMElements;
      const substrColor = value.substring(1, value.length - 2);
      elemanBase.setAttribute('stroke', `#${substrColor}`)
      this.currentItem['baseStrokeColor'] = substrColor;
    },
    changeSizeOfSVG: function() {
      changeSvgDimension(this.currentItem);
    }
  }
};
</script>

<style scoped></style>
