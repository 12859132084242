<template>
  <v-row class="justify-space-between mx-4 mb-4" no-gutters>
    <v-btn @click="$emit('panelEvent', 0)" class="white--text" color="success"
      ><v-icon class="mr-1">mdi-format-list-bulleted-square</v-icon>Liste
    </v-btn>
    <v-btn @click="$emit('panelEvent', 1)" class="white--text" color="blue"
      ><v-icon class="mr-1">mdi-file-excel</v-icon>Dosya
    </v-btn>
    <v-btn @click="$emit('clearForm')" class="white--text" color="red accent-2"
      ><v-icon class="mr-1">mdi-broom</v-icon>Temizle
    </v-btn>
  </v-row>
</template>

<script>
export default {
  name: "PanelButtonsAlarm"
};
</script>

<style scoped></style>
