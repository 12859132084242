<template>
  <v-form class="mx-4" ref="alarmPanelForm" v-model="validForm">
    <v-row no-gutters>
      <v-autocomplete
        class="mx-4"
        label="Bir istasyon seçimi yapınız"
        :items="stationsArray"
        item-text="Text"
        item-value="Id"
        v-model="alarmModel['station']"
        :rules="requiredRules"
      />
    </v-row>
    <v-row no-gutters>
      <v-col class="col-12">
        <v-dialog
          width="290px"
          transition="scale-transition"
          :close-on-content-click="false"
          v-model="dialogModel['Baslangic']"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Başlangıç tarihi seçiniz"
              v-model="alarmModel['Baslangic']"
              v-on="on"
              readonly
              prepend-icon="event"
              :rules="requiredRules"
            />
          </template>
          <date-time
            @AlarmRaporEvent="dateReceivedEvent"
            @closeDatePickerEvent="closeDatePickerEvent"
            :picker-object="{
              isTimeActive: true,
              DialogModel: false,
              Field: 'Baslangic',
              EventName: 'AlarmRaporEvent'
            }"
          />
        </v-dialog>
      </v-col>
      <v-col class="col-12">
        <v-dialog
          width="290px"
          transition="scale-transition"
          :close-on-content-click="false"
          v-model="dialogModel['Bitis']"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Bitiş tarihini seçiniz"
              v-model="alarmModel['Bitis']"
              v-on="on"
              readonly
              prepend-icon="event"
              :rules="requiredRules"
            />
          </template>
          <date-time
            @AlarmRaporEvent="dateReceivedEvent"
            @closeDatePickerEvent="closeDatePickerEvent"
            :picker-object="{
              isTimeActive: true,
              DialogModel: false,
              Field: 'Bitis',
              EventName: 'AlarmRaporEvent'
            }"
          />
        </v-dialog>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <panel-buttons @panelEvent="panelButtonEvents" @clearForm="clearForm" />
    </v-row>
  </v-form>
</template>

<script>
import CustomDateTimePickerComponent from "../../CustomDateTimePicker/CustomDateTimePickerComponent";
import PanelButtons from "@/components/ReportsBottomPanel/PanelButtons/PanelButtonsAlarm";

export default {
  name: "AlarmRaporlarPanel",
  components: {
    "date-time": CustomDateTimePickerComponent,
    "panel-buttons": PanelButtons
  },
  props: {
    stationsArray: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      alarmModel: {},
      dialogModel: {},
      validForm: true,
      requiredRules: [v => !!v || "Boş bırakılamaz"]
    };
  },
  computed: {},
  beforeDestroy() {},
  created() {},
  methods: {
    panelButtonEvents: function(tip) {
      const isValid = this.$refs["alarmPanelForm"].validate();
      if (isValid) {
        console.log("Valid - Tip: ", tip);
        let getAlarmList = {
          type: "getAlarmList",
          payload: {
            Id: this.alarmModel["station"],
            Tip: tip,
            Baslangic: this.alarmModel["Baslangic"],
            Bitis: this.alarmModel["Bitis"]
          }
        };
        console.log("Request", getAlarmList);
        this.sendMessageToWS(getAlarmList);
      }
    },
    dateReceivedEvent: function(payload) {
      const { date, Field, DialogModel } = payload;
      this.alarmModel[Field] = date;
      this.dialogModel[Field] = DialogModel;
      console.log("Dialog Model", this.dialogModel);
      console.log("Date Model", this.alarmModel);
    },
    closeDatePickerEvent: function(payload) {
      console.log("Alarm Close Event Received", payload);
      this.dialogModel[payload] = false;
    },
    clearForm: function() {
      this.$refs["alarmPanelForm"].reset();
    }
  }
};
</script>

<style scoped></style>
