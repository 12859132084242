<template>
  <v-dialog v-model="modelDialog" persistent max-width="290">
    <v-card>
      <v-card-title v-text="getDialogTitle"/>
      <v-card-text v-text="getDialogMessage" />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            class="btn_ngt"
            color="red darken-1"
            text
            @click="buttonEvents('negative')"
        >
          {{ getNegativeButton }}</v-btn
        >
        <v-btn
            class="btn_pos"
            color="green darken-1"
            text
            @click="buttonEvents('positive')"
        >{{ getPositiveButton }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "CustomDialogAlert",
  data() {
    return {
      dialogConfig: {},
      modelDialog: false
    };
  },
  props: {
    dynamicProp: Object,
    eventPositive: {
      type: String,
      required: false
    },
    eventNegative: {
      type: String,
      required: false
    }
  },
  computed: {
    getDialogModel: function() {
      return this.dynamicProp.dialogModel;
    },
    getDialogTitle: function() {
      return this.dynamicProp.dialogTitle;
    },
    getDialogMessage: function() {
      return this.dynamicProp.dialogMessage;
    },
    getPositiveButton: function() {
      return this.dynamicProp.textPButton;
    },
    getNegativeButton: function() {
      return this.dynamicProp.textNButton;
    },
    getSVGEl: function() {
      return this.dynamicProp.el;
    },
    getSVGClassName: function() {
      return this.dynamicProp.class_name;
    },
    getCustomRequest: function() {
      return this.dynamicProp.customRequest;
    },
    from: function() {
      return this.dynamicProp.from;
    }
  },
  created() {
    console.log("DIALOG PROPS: ", this.dynamicProp);
    this.modelDialog = this.getDialogModel;
  },
  watch: {
    dynamicProp: function(value) {
      this.modelDialog = value.dialogModel;
    }
  },
  methods: {
    buttonEvents: function(btnType) {
      this.modelDialog = false;
      if (btnType === "positive") {
        //bus.$emit("formSave","FORM_SAVE_CONFIRM");
        if (this.from === "ModemLayoutWizard") {
          this.$emit(this.eventPositive, {
            el: this.getSVGEl,
            class_name: this.getSVGClassName
          });
        } else if (
            this.from === "DynamicTableComponent" ||
            this.from === "yeni" ||
            this.from === "duzenle"
        ) {
          this.$emit(this.eventPositive, {
            customRequest: this.getCustomRequest
          });
        } else {
          this.$emit(this.eventPositive);
        }
      } else {
        this.$emit(this.eventNegative);
      }
    }
  }
};
</script>

<style scoped></style>
