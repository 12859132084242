<template>
  <div class="mainWrap">
    <v-container class="mainContainer">
      <v-img
          class="my-6"
          width="100%"
          :aspect-ratio="16 / 9"
          contain
          style=" opacity: .8;"
          :src="require(`@/assets/skeletons/noStationSkeleton.svg`)"
      />
    </v-container>
    <h3 class="text-center font-weight-black h3Class">
      {{title}}
    </h3>
    <h5 class="text-center font-weight-medium pt-2 h5Class">
      {{ subtitle }}<span class="pl-2 pr-2">
        <slot name="custom-component"></slot></span
    >'ya tıklayabilirsiniz
    </h5>
  </div>
</template>

<script>
export default {
  name: "StationsNoData",
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped>
.mainWrap {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.mainContainer {
  max-width: 500px !important;
}

.h3Class {
  font-size: 36px;
  color: #c4c4c4;
}

.h5Class {
  font-size: 18px;
  color: #c4c4c4;
}
</style>
