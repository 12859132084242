<template>
  <div>
    <h2 class="ml-4 mt-4 mb-4">Rapor Listesi</h2>
    <v-simple-table>
      <template v-slot:default>
      <thead>
        <tr>
          <th></th>
          <th class="text-center"
            v-for="(fHeader, fIndex) in createTableData['legends']"
            :key="fIndex"
          >
            {{ fHeader }}
          </th>
        </tr>
      </thead>
      <thead>
        <tr>
          <th>Tarih</th>
          <th v-for="(h, hIndex) in createTableData['headers']" :key="hIndex">
            <table style="width: 100%">
              <thead>
                <tr>
                  <th :style="'width:' + 100 / h.length + '%;'" class="text-center" v-for="(c, cIndex) in h" :key="cIndex">{{ c }}</th>
                </tr>
              </thead>
            </table>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="bodyTr"
          v-for="(item, key) in createTableData['ordData']"
          :key="key"
        >
          <td>{{ key }}</td>
          <td v-for="(ch, chI) in item" :key="chI">
            <table style="width: 100%;">
              <tr>
                <td :style="'width:' + 100 / ch['header'].length + '%;'" class="text-center" v-for="(v, vF) in ch['datas']" :key="vF">{{ v }}</td>
              </tr>
            </table>
          </td>
        </tr>
      </tbody>
      </template>
    </v-simple-table>
    <!--<p>{{ tableObjectData }}</p>-->
  </div>
</template>

<script>
export default {
  name: "AlarmReportsList",
  props: {
    componentData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      lineHeaders: ["Max", "Min", "Ortalama"],
      barHeaders: ["Açılma(0->1)", "Kapanma(1->0)"],

      tableMap: new Map(),
      tableObjectData: {},
      headers: [],
      tableLegends: []
    };
  },
  computed: {
    createTableData: function() {
      let valueHeaders = [];
      let normalHeaders = [];
      let tableData = {};

      const { Datasets } = this.componentData;

      Datasets.forEach((set, setIndex) => {
        const { Legend, Values, ChartType } = set;
        valueHeaders.push(Legend);
        if (ChartType === "Line") {
          normalHeaders.push(this.lineHeaders);
        } else if (ChartType === "Bar") {
          normalHeaders.push(this.barHeaders);
        }

        Values.forEach(value => {
          const { X, Y1, Y2, Y3 } = value;
          const convDate = new Date(
            Number(String(X).substring(0, 13))
          ).toLocaleString("tr-TR");

          if (!tableData.hasOwnProperty(convDate))
            tableData[convDate] = new Array(Datasets.length).fill({});
          if (ChartType === "Line") {
            tableData[convDate][setIndex] = {
              Legend: Legend,
              type: ChartType,
              dX: X,
              date: convDate,
              header: this.lineHeaders,
              datas: [Y1.toFixed(3), Y2.toFixed(3), Y3.toFixed(3)]
            };
          } else if (ChartType === "Bar") {
            tableData[convDate][setIndex] = {
              Legend: Legend,
              type: ChartType,
              dX: X,
              date: convDate,
              header: this.barHeaders,
              datas: [Y1.toFixed(3), Y2.toFixed(3)]
            };
          }
        });
      });

      const ordered = {};
      Object.keys(tableData)
        .sort()
        .forEach(function(key) {
          ordered[key] = tableData[key];
        });

      return {
        legends: valueHeaders,
        headers: normalHeaders,
        ordData: ordered
      };
    }
  },
  created() {
    this.componentData["Datasets"].forEach(set => {
      const { Legend, Values, ChartType } = set;
      this.tableLegends.push(Legend);
      if (ChartType === "Line") {
        this.headers.push(this.lineHeaders);
      } else if (ChartType === "Bar") {
        this.headers.push(this.barHeaders);
      }

      Values.forEach(value => {
        const { X, Y1, Y2, Y3 } = value;
        if (!this.tableObjectData.hasOwnProperty(X)) {
          this.tableObjectData[X] = [];
        }

        const max = Y1.toFixed(3);
        const min = Y2.toFixed(3);
        const ort = Y3.toFixed(3);

        this.tableObjectData[X].push({
          K: Legend,
          V: { X, max, min, ort }
        });
      });
    });

    console.log("Table Object Data", this.tableObjectData);
  },
  methods: {
    convertToDate: function(X) {
      return new Date(Number(String(X).substring(0, 13))).toLocaleString(
        "tr-TR"
      );
    }
  }
};
</script>

<style scoped>

.bodyTr:nth-child(odd) {
  background-color: #f8f6ff;
}

.bodyTr {
  border: thin solid black
}

</style>
