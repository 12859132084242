<template>
  <div>
    <component
        :is="componentOBJ.compName"
        :dynamicProp="componentOBJ.compProp"
    ></component>
    <v-row no-gutters>
      <!-- io-comp -->
      <v-col :class="readOnly ? 'col-12' : 'col-8'" class="pa-0 ma-0">
        <v-card style="background-color: #eef0f5;" class="pl-2 pr-2">
          <v-col class="ma-0 pa-0">
            <v-card-title class="title ma-0 pa-2">I/O Elemanları</v-card-title>
          </v-col>
          <v-row no-gutters class="io_editor">
            <v-col
                :class="`col-${12/parseInt(layoutData.cols)}`"
                v-for="(item, i) in layoutData.comps"
                :key="item['id']"
            >
              <v-card
                  class="my-2 mx-2 item-draggable"
                  :id="item.id"
                  @dragstart="!readOnly && dragStart(i, $event)"
                  @dragover.prevent
                  @dragenter="!readOnly && dragEnter(i, $event)"
                  @dragleave="!readOnly && dragLeave"
                  @dragend="!readOnly && dragEnd"
                  @drop="!readOnly && dragFinish(i, $event)"
                  draggable="true"
                  @mouseover="showDynamicButtons(i)"
                  @mouseleave="hideDynamicButtons(i)"
              >
                <v-row align="center" no-gutters>
                  <v-col class="col-4 mx-2">
                    <v-text-field
                        :readonly="readOnly"
                        dense
                        v-model="item.label"
                    ></v-text-field>
                  </v-col>
                  <v-col align="center" class="col-4 mx-2"
                  ><span :style="'color:#' + item.one_color">{{
                      item.one_label
                    }}</span></v-col
                  >
                  <v-spacer></v-spacer>
                  <v-col
                      class="col-1"
                      v-if="isActionButtonsVisible[i].actionEdit"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                            :disabled="readOnly"
                            v-on="on"
                            icon
                            color="indigo darken-1
"
                            @click="editItem(item, i)"
                        >
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </template>
                      <span>Bu elemanı düzenle</span>
                    </v-tooltip>
                  </v-col>

                  <v-col
                      class="col-1"
                      v-if="isActionButtonsVisible[i].actionDelete"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                            :disabled="readOnly"
                            @click="removeItemAt(i)"
                            v-on="on"
                            icon
                            color="red darken-2"
                        >
                          <v-icon>delete_forever</v-icon>
                        </v-btn>
                      </template>
                      <span>Bu elemanı sil</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <!-- io-comp -->

      <!-- config part -->
      <v-col v-if="!readOnly" class="col-4 pl-4 pr-4">
        <v-card class="pa-4">
          <v-select
              class="col-auto"
              label="Sütun sayısı"
              :items="['1', '2', '3','4']"
              v-model="layoutData['cols']"
              hide-details
          />
          <v-form
              ref="form"
              v-model="valid"
              v-show="showEditForm"
              lazy-validation
          >
            <v-col class="ma-0 pa-0">
              <v-card-title class="title ma-0 pa-2"
              >I/O Eleman Düzenleme</v-card-title
              >
            </v-col>

            <v-text-field
                class="col-auto"
                label="Etiket"
                v-model="rightSettingsModel['label']"
                :rules="[v => !!v || 'Etiket Alanın Doldurulması Gerekmektedir']"
            />
            <v-autocomplete
                class="col-auto"
                label="Bağlı alan"
                :items="modemIOItems"
                item-value="Id"
                item-text="Text"
                v-model="modemIOModel"
                @input="modemIOSelect"
                :rules="[v => !!v || 'Bağlı Alanın Doldurulması Gerekmektedir']"
            />

            <!-- İşleme şekli / Bit ya da word seçimi -->
            <v-autocomplete
                class="col-auto"
                label="İşleme şekli"
                :items="bitOrWord"
                v-model="bitOrWordModel"
                item-value="Id"
                item-text="Text"
                @input="selectBitOrWordType"
                v-if="showProcessCh"
            />
            <!-- İşleme şekli / Bit ya da word seçimi -->

            <!-- Bit seçimi -->
            <v-autocomplete
                class="col-auto"
                label="Bit"
                :items="bitItems"
                v-model="rightSettingsModel['bit']"
                :disabled="isBitDisabled"
                v-if="showBitCh"
            />
            <!-- Bit seçimi -->

            <!-- Input ise grafik/lamba seçimi -->
            <v-autocomplete
                class="col-auto"
                v-if="rightSettingsModel['tip'] === '1'"
                label="Gösterim Şekli"
                :items="[
                { Id: '0', Text: 'Grafik' },
                { Id: '1', Text: 'Lamba' },
                { Id: '2', Text: 'Custom' }
              ]"
                item-text="Text"
                item-value="Id"
                v-model="rightSettingsModel['input_ui']"
                :rules="[v => !!v || 'Grafik mi lamba mı seçimi yapınız !']"
            />
            <!-- Input ise grafik/lamba seçimi -->

            <!-- 0 değeri etiket ve renk değiştirme -->
            <v-row no-gutters>
              <v-row class="align-center mb-2 justify-space-between" no-gutters>
                <v-col class="ma-2 col-auto">
                  <span class="indigo--text">0 değeri etiket ve renk</span>
                  <v-spacer></v-spacer>
                </v-col>
                <v-col class="col-auto">
                  <v-btn @click="swapZeroOne" small class="justify-end indigo white--text">Swap</v-btn>
                </v-col>
              </v-row>
              <v-col class="col-6">
                <v-text-field
                    v-model="rightSettingsModel['zero_label']"
                    label="0 değeri etiket"
                >
                  <v-icon
                      :color="'#' + rightSettingsModel['zero_color']"
                      slot="prepend"
                      class="zeroLabelIcon"
                  >
                    mdi-label
                  </v-icon>
                </v-text-field>
              </v-col>
              <v-col class="col-6">
                <v-menu
                    ref="cpRef"
                    v-model="colorMenuValue0"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-x
                    min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                        label="0 değeri renk"
                        readonly
                        v-on="on"
                        v-model="rightSettingsModel['zero_color']"
                    >
                      <v-icon
                          :color="'#' + rightSettingsModel['zero_color']"
                          slot="prepend"
                          class="zeroLabelIcon"
                      >
                        mdi-invert-colors
                      </v-icon>
                    </v-text-field>
                  </template>
                  <v-color-picker
                      v-model="rightSettingsModel['zero_color']"
                      @input="change0Color"
                  ></v-color-picker>
                </v-menu>
              </v-col>
            </v-row>
            <!-- 0 değeri etiket ve renk değiştirme -->

            <!-- 1 değeri etiket ve renk değiştirme -->
            <v-row no-gutters>
              <v-col class="ma-2 col-12">
                <span class="indigo--text">1 değeri etiket ve renk</span>
              </v-col>
              <v-col class="col-6">
                <v-text-field
                    v-model="rightSettingsModel['one_label']"
                    label="1 değeri etiket"
                >
                  <v-icon
                      :color="'#' + rightSettingsModel['one_color']"
                      slot="prepend"
                      class="oneLabelIcon"
                  >
                    mdi-label
                  </v-icon>
                </v-text-field>
              </v-col>
              <v-col class="col-6">
                <v-menu
                    ref="cpRef"
                    v-model="colorMenuValue1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-x
                    min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                        label="1 değeri renk"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                        v-model="rightSettingsModel['one_color']"
                    >
                      <v-icon
                          :color="'#' + rightSettingsModel['one_color']"
                          slot="prepend"
                          class="oneLabelIcon"
                      >
                        mdi-invert-colors
                      </v-icon>
                    </v-text-field>
                  </template>
                  <v-color-picker
                      v-model="rightSettingsModel['one_color']"
                      @input="change1Color"
                  ></v-color-picker>
                </v-menu>
              </v-col>
            </v-row>
            <!-- 1 değeri etiket ve renk değiştirme -->
          </v-form>
          <v-row justify="end" no-gutters>
            <v-col class="col-auto ma-2">
              <v-btn
                  :disabled="!!showEditForm"
                  @click="addNewItem"
                  class="text-capitalize white--text"
                  small
                  color="green darken-1"
              >
                <v-icon left>mdi-plus-circle</v-icon> Yeni Ekle
              </v-btn>
            </v-col>
            <v-col class="col-auto ma-2">
              <v-btn
                  class="text-capitalize white--text"
                  small
                  color="red darken-2"
                  @click="saveChanges"
              >
                <v-icon left>mdi-content-save</v-icon> Değişiklikleri kaydet
              </v-btn>
            </v-col>
            <v-col class="col-auto ma-2">
              <v-btn
                  class="text-capitalize white--text"
                  small
                  color="indigo darken-2"
                  @click="printAll"
              >
                <v-icon left>playlist_add</v-icon> Tamamını Ekle
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <!-- config part -->
    </v-row>
    <vue-json-pretty
        :highlightMouseoverNode="true"
        :showLength="true"
        :showLine="true"
        :showSelectController="true"
        :data="rightSettingsModel"
    ></vue-json-pretty>
    <vue-json-pretty
        :highlightMouseoverNode="true"
        :showLength="true"
        :showLine="true"
        :showSelectController="true"
        :data="layoutData"
    ></vue-json-pretty>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { mapGetters } from "vuex";
import { generateRandomKey } from "../AlarmWizard/alarmwiz_events";
import OverlayComponent from "../DialogComponent/OverlayLoader/OverlayComponent";

export default {
  name: "IOComponent",
  props: {
    fieldItem: {
      type: Object,
      required: true
    },
    dynamicM: [Array, Object],
    actionType: String,
    modemID: {
      type: [Number, String],
      required: true
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    "overlay-loader": OverlayComponent
  },
  data() {
    return {
      //Layout data object
      layoutData: {},
      //v-model object for right settings menu
      rightSettingsModel: {
        id: "",
        tip: "",
        label: "",
        birim: "",
        master_id: "",
        bit: "",
        zero_label: "0",
        zero_color: "EE4343",
        one_label: "1",
        one_color: "44C74E",
        input_ui: "0"
      },

      componentOBJ: { compName: "", compProp: null },
      //show field => "İşleme şekli" type: v-select
      showProcessCh: true,
      //show field => "Bit" type: v-select
      showBitCh: true,
      valid: true,
      modemIOModel: null,
      modemIOItems: [],
      itemEdited: null,
      bitItems: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16"
      ],
      isBitDisabled: false,
      bitOrWord: [
        { Id: 0, Text: "Bit" },
        { Id: 1, Text: "Word" }
      ],
      bitOrWordModel: -1,
      isActionButtonsVisible: [],

      colorMenuValue0: false,
      colorMenuValue1: false,
      /*readonly: true,
      readOnly: [],*/
      dragging: -1,
      holderDragNode: null,
      dragItemIndex: -1,
      overlay: true,
      showEditForm: false,
      whichForm: "FORM_NEW"
    };
  },
  computed: {
    setListConfig: {
      set: function(value) {
        this.dynamicM["list_config"] = value;
      },
      get: function() {
        return this.dynamicM["list_config"];
      }
    },
    ...mapGetters({
      getterSocketMessage: "storeSocket/getterSocketMessage"
    })
  },
  created() {
    //If there is no IO list data
    if (
        this.dynamicM[this.fieldItem.Field] === null ||
        this.dynamicM[this.fieldItem.Field] === "" ||
        this.dynamicM[this.fieldItem.Field] === undefined
    ) {
      this.overlay = false;
      this.layoutData = {
        cols: "2",
        comps: []
      };
      let getModemIOList = {
        type: "getModemIOList",
        payload: { ModemId: parseInt(this.modemID) }
      };
      this.sendMessageToWS(getModemIOList);
    } else {
      this.overlay = false;

      this.layoutData = JSON.parse(
          decodeURIComponent(this.dynamicM[this.fieldItem.Field])
      );

      this.isActionButtonsVisible = this.layoutData["comps"].map(v => {
        return {
          id: "actionGroup_" + v["id"],
          actionDelete: false,
          actionEdit: false
        };
      });

      let getModemIOList = {
        type: "getModemIOList",
        payload: { ModemId: parseInt(this.modemID) }
      };

      this.sendMessageToWS(getModemIOList);
    }

    console.log("MODEM ID:", this.modemID);
  },
  watch: {
    getterSocketMessage: function(value) {
      const { command, data } = JSON.parse(value.data);
      if (command === "ModemIOList") {
        this.componentOBJ.compName = "overlay-loader";
        this.componentOBJ.compProp = false;
        console.log("ModemIOList", data);
        if (data !== null) {
          console.log("IO List has data");
          //send ınput output list
          const { Elements } = data;
          this.modemIOItems = Object.keys(Elements).map(k => {
            return {
              Id: k,
              Text: Elements[k]
            };
          });
        }
      }
    },
    layoutData: {
      deep: true,
      handler(value) {
        this.dynamicM["list_config"] = encodeURIComponent(
            JSON.stringify(value)
        );
      }
    }
    //deep watch v-model object for changes
    /*rightSettingsModel: {
      deep: true,
      handler(value) {
        //console.log("Deep value right model", value);
      }
    }*/
  },
  methods: {
    swapZeroOne: function () {
      const zero_color = this.rightSettingsModel["zero_color"];
      const zero_label = this.rightSettingsModel["zero_label"];
      const one_color = this.rightSettingsModel["one_color"];
      const one_label = this.rightSettingsModel["one_label"];

      this.rightSettingsModel["zero_color"] = one_color;
      this.rightSettingsModel["zero_label"] = one_label;
      this.rightSettingsModel["one_color"] = zero_color;
      this.rightSettingsModel["one_label"] = zero_label;

    },
    resetFormModel: function() {
      this.bitOrWordModel = -1;
      this.modemIOModel = null;
      this.rightSettingsModel = {
        id: "",
        tip: "",
        label: "",
        birim: "",
        master_id: "",
        bit: "",
        zero_label: "0",
        zero_color: "EE4343",
        one_label: "1",
        one_color: "44C74E",
        input_ui: "0"
      };
    },
    /*
     * IO item sil, index'e göre
     * */
    removeItemAt(index) {
      this.layoutData.comps.splice(index, 1);
      if (this.showEditForm) {
        this.showEditForm = false;
        this.resetFormModel();
      }
    },
    /*
     * Yeni IO item ekle. isActionButtonsVisible sil ve düzenle aksiyon
     * butonlarını göstermek için kullanılan array.
     *
     * layoutData => yeni IO objesi ekle.
     * */
    addNewItem: function() {
      this.showEditForm = true;
      this.whichForm = "FORM_NEW";
    },
    /*
     * Değişiklikleri local olarak kaydet.
     * */
    saveChanges: function() {
      if (this.whichForm === "FORM_NEW") {
        this.isActionButtonsVisible.push({
          actionDelete: false,
          actionEdit: false
        });

        console.log("REFSSS VALID", this.$refs.form.validate());

        if (this.$refs.form.validate()) {
          console.log(this.modemIOModel);
          this.rightSettingsModel["id"] = generateRandomKey();
          //this.deconstructModemIO();
          this.layoutData.comps.push(this.rightSettingsModel);
        }
      } else if (this.whichForm === "FORM_EDIT") {
        console.log("FORM EDIT CLICKED");
      }

      this.resetFormModel();
      this.showEditForm = false;

      //this.$refs.form.reset();
    },
    editItem: function(value, index) {
      console.log("Edit Item");
      this.showEditForm = true;
      this.rightSettingsModel = this.layoutData["comps"][index];
      this.whichForm = "FORM_EDIT";
      /*eslint-disable*/
      //Destructure object to get all values from it
      const {
        tip,
        master_id,
        bit,
        birim,
        label,
        one_color,
        one_label,
        zero_color,
        zero_label,
        id
      } = value;
      console.log("Value", value);
      this.modemIOModel = this.constructModemIOSelectValue(
          tip,
          master_id,
          bit,
          birim
      );

      this.setBitValueModel(tip, bit);

      this.itemEdited = value;
    },
    setBitValueModel: function(tip, bit) {
      console.log("Set bit value model: ", tip, bit);
      //if tip values are like below
      if (tip === "0" || tip === "3" || tip === "4" || tip === "7") {
        //then this is a word. So we should hide bit form fields.
        this.showBitCh = false;
        this.showProcessCh = false;
        this.rightSettingsModel["bit"] = "";
      }
      //if tip => 1,2,5,6,8
      else {
        //if bit is not a number
        if (isNaN(bit)) {
          console.log("Is Nan Bit")
          this.rightSettingsModel["bit"] = "";
          this.rightSettingsModel["birim"] = bit;
          this.showProcessCh = false;
          this.showBitCh = false;
          return;
        }
        //if bit => -1 Show word and disable bit selection
        if (bit === -1) {
          this.rightSettingsModel["bit"] = -1;
          this.showProcessCh = true;
          this.showBitCh = false;
          this.bitOrWordModel = 1;
        }
        //There is a bit
        else {
          this.rightSettingsModel["bit"] = bit;
          this.bitOrWordModel = 0;
          this.showProcessCh = true;
          this.showBitCh = true;
        }
      }
    },
    constructModemIOSelectValue: function(tip, master_id, bit, birim) {
      //If tip => 1 || 2 || 6 || 8
      if (tip === "1" || tip === "2" || tip === "6" || tip === "8") {
        //check tip 8. if tip = 8 && w(which is 'birim') exists
        if (tip === "8" && birim !== "") {
          console.log("Here?",[tip, master_id, birim].join("_"));
          return [tip, master_id, birim].join("_");
        }
        //check tip 8. if tip = 8 && w(which is 'birim') is empty
        else if (tip === "8" && birim === "") {
          //also if bit is -1
          if (bit === -1) {
            console.log([tip, master_id].join("_"));
            return [tip, master_id, ""].join("_");
          }
          //also if bit is not -1
          else {
            console.log([tip, master_id, bit].join("_"));
            return [tip, master_id, bit].join("_");
          }
        } else if (tip === "1" && birim === "") {
          console.log([tip, master_id, bit, birim].join("_"));
          return [tip, master_id, bit].join("_");
        }
        //check tip 8. if tip = 8 && w(which is 'birim') is NOT empty
        else {
          console.log([tip, master_id, birim].join("_"));
          return [tip, master_id, bit, birim].join("_");
        }
      }
      //If tip => 3 || 5 ? x_y_z
      else if (tip === "3" || tip === "5" || tip === "4") {
        console.log([tip, master_id, birim].join("_"));
        return [tip, master_id,birim].join("_");
      }
    },
    /*
     * Sürükleme başla
     * */
    dragStart: function(index, event) {
      //event.dataTransfer.setData("Text", this.id);
      event.dataTransfer.dropEffect = "move";

      event.target.classList.add("draggedItem");
      event.target.classList.add("custom-card-list");

      this.dragging = index;
      this.holderDragNode = event;
    },
    /*
     * Item üzerine gelince.
     * */
    dragEnter: function(index, event) {
      if (index !== this.dragging) {
        event.dataTransfer.dropEffect = "copy";

        event.target.classList.remove("changeBGLeave");

        event.target.classList.add("custom-card-list");
        event.target.classList.add("changeBG");
      }
    },
    /*
     * Item üzerinde değil
     * */
    dragLeave: function(event) {
      event.target.classList.add("changeBGLeave");
      event.target.classList.remove("changeBG");
      console.log("Drag Leave item", event.target);
    },
    /*
     * Sürükleme bitti.
     * */
    dragEnd: function(event) {
      console.log("Drag end", event);
      this.dragging = -1;
      this.holderDragNode.target.classList.remove("draggedItem");
    },

    dragFinish: function(index, event) {
      let lights = document.getElementsByClassName("custom-card-list");
      while (lights.length)
        lights[0].className = lights[0].className.replace(
            /\bcustom-card-list\b/g,
            ""
        );

      event.target.classList.remove("changeBG");
      console.log("Target", event.target)
      this.holderDragNode.target.classList.remove("draggedItem");

      this.moveItem(this.dragging, index);

      event.target.style.marginTop = "2px";
      event.target.style.marginBottom = "2px";

      const items = document.querySelectorAll(".item-draggable");
      //console.log("Items ", items);
      items.forEach(v => {
        v.classList.remove("changeBG");
      })
    },

    moveItem: function(from, to) {
      if (to === -1) {
        this.removeItemAt(from);
      } else {
        this.layoutData.comps.splice(
            to,
            0,
            this.layoutData.comps.splice(from, 1)[0]
        );
      }
    },
    modemIOSelect: function(value) {
      console.log("Seçilen eleman", value);
      let [tip, master_id, bit, birim] = value.split("_");
      this.deconstructModemIO(tip, master_id, bit, birim);
      this.setBitValueModel(tip, bit);
    },
    /*Bit birim yapısını tekrar gözden geçir.*/
    deconstructModemIO: function() {
      const [tip, master_id, bit, birim] = this.modemIOModel.split("_");
      this.rightSettingsModel["tip"] = tip;
      this.rightSettingsModel["master_id"] = master_id;
      if (tip === "4") {
        this.rightSettingsModel["birim"] = bit;
        return;
      }
      if (tip === "8" || tip === "3") {
        this.rightSettingsModel["birim"] = bit;
        return;
      }

      console.log("BİT BİRİM YAPI", this.modemIOModel);
      console.log("LOOKUP", bit);

      const lookup = {
        bit: val => val === "" || typeof val === "undefined",
        birim: val => typeof val === "undefined"
      };

      if (lookup.bit(bit)) {
        this.rightSettingsModel["bit"] = "";
        //this.isBitDisabled = true;
      } else {
        this.rightSettingsModel["bit"] = bit;
        //this.isBitDisabled = false
      }

      if (lookup.birim(birim)) this.rightSettingsModel["birim"] = "";
      else this.rightSettingsModel["birim"] = birim;
    },
    /*
     * Bit ya da word seçimi.
     * */
    selectBitOrWordType: function(value) {
      //Bit => 0 - Word => 1
      //Are we editing an existing object or are we going to add a new object (io element)
      //we are in edit mode
      if (this.itemEdited !== null) {
        console.log(value);
        if (value === 0) {
          this.showBitCh = true;
          this.rightSettingsModel["bit"] = "";
        } else if (value === 1) {
          this.rightSettingsModel["bit"] = -1;
          this.showBitCh = false;
        }
      }
      //We are in add new item mode
      else {
        if (value === 0) {
          this.showBitCh = true;
          this.rightSettingsModel["bit"] = "";
        } else if (value === 1) {
          this.rightSettingsModel["bit"] = -1;
          this.showBitCh = false;
        }
      }
    },
    /*
     * Düzenle ve Sil butonlarını göster
     * */
    showDynamicButtons: function(index) {
      this.isActionButtonsVisible[index].actionDelete = true;
      this.isActionButtonsVisible[index].actionEdit = true;
    },
    /*
     * Düzenle ve Sil butonlarını gizle
     * */
    hideDynamicButtons: function(index) {
      this.isActionButtonsVisible[index].actionDelete = false;
      this.isActionButtonsVisible[index].actionEdit = false;
    },
    /*
     * 0 rengini değiştir
     * */
    change0Color: function(value) {
      console.log("Value Color 0", value);
      if (this.itemEdited !== null) {
        this.rightSettingsModel["zero_color"] = this.getHexValue(value);
      } else {
        this.rightSettingsModel["zero_color"] = this.getHexValue(value);
      }
    },
    /*
     * 1 rengini değiştir
     * */
    change1Color: function(value) {
      console.log("Value Color 1", value);
      if (this.itemEdited !== null) {
        this.rightSettingsModel["one_color"] = this.getHexValue(value);
      } else {
        this.rightSettingsModel["one_color"] = this.getHexValue(value);
      }
    },
    getHexValue: function(value) {
      return value.slice(1, value.length - 2).toLowerCase();
    },
    printAll: function() {
      console.log("Modem IO", this.modemIOItems);
      const comps = this.modemIOItems
          .filter(m => {
            const [tip] = m.Id.split("_");
            return tip !== "0" && m;
          })
          .map(m => {
            const { Id, Text } = m;
            const [tip, master_id, bit, birim] = Id.split("_");
            return {
              id: uuidv4(),
              label: Text,
              tip,
              birim: birim || "",
              master_id,
              bit,
              zero_label: "0",
              zero_color: "cfcfcf",
              one_label: "1",
              one_color: "44C74E",
              input_ui: "1"
            };
          });
      this.isActionButtonsVisible = comps.map(v => {
        return {
          id: "actionGroup_" + v["id"],
          actionDelete: false,
          actionEdit: false
        };
      });
      this.layoutData["comps"] = comps;
      console.log("Filtered", this.layoutData["comps"]);
    }
  }
};
</script>

<style scoped>
.whiteBG {
  transition: 0.2s ease;
  background-color: #fff;
}

.changeBG {
  -webkit-animation: DnDAnimation 1s infinite;
  -moz-animation: DnDAnimation 1s infinite;
  -o-animation: DnDAnimation 1s infinite;
  animation: DnDAnimation 1s infinite;
}

.changeBGLeave {
  -webkit-animation: DnDAnimationLeave 1s;
  -moz-animation: DnDAnimationLeave 1s;
  -o-animation: DnDAnimationLeave 1s;
  animation: DnDAnimationLeave 1s;
}

@keyframes DnDAnimation {
  0% {
    background-color: #fff;
  }
  50% {
    background-color: #678dff;
  }
  100% {
    background-color: #fff;
  }
}

@keyframes DnDAnimationLeave {
  from {
    background-color: #678dff;
  }
  to {
    background-color: #fff;
  }
}

.custom-card-list * {
  pointer-events: none !important;
}

.draggedItem {
  transition: 0.2s ease;
  background-color: white !important;
  opacity: 0.5;
  transform: scale(0.95);
}
/*.item-draggable * {
  pointer-events: none !important;
}*/
</style>
