<script>
import { Line } from "vue-chartjs";
export default {
  extends: Line,
  name: "LineChart",
  props: {
    chartData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: "",
          fontSize: 18,
          fontFamily: "'Fira Sans', sans-serif",
          fontColor: "#000",
          position: "top"
        },
        legend: {
          display: true,
          position: 'top',
          labels: {
            fontSize: 16,
            fontColor: '#000',
            boxWidth: 30
          }
        }
      }
    };
  },
  mounted() {
    const { Legend, Values } = this.chartData;
    this.options.title.text = Legend;

    let xLabels = [];
    let y1Values = [];
    let y2Values = [];
    let y3Values = [];

    Values.forEach(value => {
      const { X, Y1, Y2, Y3 } = value;

      const xDateLabel = new Date(
        Number(String(X).substring(0, 13))
      ).toLocaleString("tr-TR");
      xLabels.push(xDateLabel);
      y1Values.push(Y1);
      y2Values.push(Y2);
      y3Values.push(Y3);
    });

    let chartData = {
      labels: xLabels,
      datasets: [
        {
          label: "Maksimum",
          fill: false,
          borderColor: "#f54257",
          pointBackgroundColor: "#f54257",
          data: y1Values,
        },
        {
          label: "Minimum",
          fill: false,
          borderColor: "#2986f0",
          pointBackgroundColor: "#2986f0",
          data: y2Values
        },
        {
          label: "Ortalama",
          fill: false,
          borderColor: "#20e676",
          pointBackgroundColor: "#20e676",
          data: y3Values
        }
      ]
    };

    console.log("Chart Datas", this.chartData);
    this.renderChart(chartData, this.options)

  }
};
</script>

<style scoped></style>
