<template>
  <v-row no-gutters>
    <v-col>
      <v-autocomplete
          :label="getFieldItem.Label"
          :items="modemComboItems"
          item-text="modem_name"
          item-value="modem_id"
          @input="selectModem"
          v-model="parseVal"
          :readonly="readOnly"
      />

      <v-autocomplete
          label="Gözlenecek Değer"
          :items="modemInputs"
          item-value="Value"
          item-text="Text"
          v-model="dynamicM['linked_value']"
          :readonly="readOnly"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "watchFieldSelectorComponent",
  props: {
    fieldItem: {
      type: Object,
      required: true
    },
    dynamicM: [Array, Object],
    actionType: String,
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      modemComboItems: [],
      modemInputs: []
    };
  },
  computed: {
    parseVal: {
      get: function() {
        return parseInt(this.dynamicM["modem_id"]);
      },
      set: function(value) {
        this.dynamicM["modem_id"] = parseInt(value);
      }
    },
    d_M: {
      get: function() {
        return this.dynamicM;
      },
      set: function(value) {
        this.dynamicM = value;
      }
    },
    getFieldItem: function() {
      return this.fieldItem;
    },
    getActionType: function() {
      return this.actionType;
    },
    ...mapGetters({
      getterSocketMessage: "storeSocket/getterSocketMessage"
    })
  },
  watch: {
    getterSocketMessage: function(value) {
      let command = JSON.parse(value.data).command;
      //console.log("Inputs: " + JSON.parse(value.data));
      if (command === "ModemComboList") {
        console.log(JSON.parse(value.data).data);
        this.modemComboItems = JSON.parse(value.data).data;
        if (this.readOnly) {
          this.selectModem(this.parseVal);
        }
      } else if (command === "ModemInputList") {
        this.setModemInputs(JSON.parse(value.data).data);
      }
    }
  },
  created() {
    this.sendMessageToWS({ type: "getFullModemList", payload: null });

    switch (this.getActionType) {
      case "yeni":
        break;

      case "duzenle":
        var getModemInputs = {
          type: "getModemInputs",
          payload: { ModemId: this.parseVal, Type: "Link" }
        };

        this.sendMessageToWS(getModemInputs);
        break;
    }
  },
  methods: {
    selectModem: function(value) {
      console.log(value);
      let modemInps = {
        type: "getModemInputs",
        payload: { ModemId: value, Type: "Link" }
      };

      this.sendMessageToWS(modemInps);
    },
    setModemInputs: function(value) {
      this.modemInputs = [];
      if (value !== null) {
        Object.keys(value).forEach(key => {
          this.modemInputs.push({
            Value: key,
            Text: value[key]
          });
        });
      }
    }
  }
};
</script>

<style scoped></style>
