<template>
  <v-tooltip color="indigo darken-2" right>
    <template v-slot:activator="{ on }">
      <v-speed-dial
          fixed
          bottom
          dark
          class="speedDialFab"
          :class="showFab === true ? 'show_fab' : 'hide_fab'"
          v-model="fab"
          direction="top"
          :open-on-hover="false"
          transition="slide-y-reverse-transition"
      >
        <template v-slot:activator>
          <v-btn
              v-on="on"
              @click="fabMainClicked(fab)"
              v-model="fab"
              color="indigo darken-2"
              dark
              fab
          >
            <v-icon v-if="fab">close</v-icon>
            <v-icon v-else>view_module</v-icon>
          </v-btn>
        </template>
        <v-btn
            style="transform: translate(-60px ,70px);"
            @click="fabChildClicked(1)"
            fab
            dark
            small
            color="red lighten-1"
        >
          1
        </v-btn>
        <v-btn
            style="transform: translate(-30px ,70px);"
            @click="fabChildClicked(2)"
            fab
            dark
            small
            color="red lighten-1"
        >
          2
        </v-btn>
        <v-btn
            style="transform: translate(30px ,122px);"
            @click="fabChildClicked(3)"
            fab
            dark
            small
            color="red lighten-1"
        >
          3
        </v-btn>

        <v-btn
            style="transform: translate(60px ,225px);"
            @click="fabChildClicked(4)"
            fab
            dark
            small
            color="red lighten-1"
        >
          4
        </v-btn>
      </v-speed-dial>
    </template>
    <span>Izgara düzenini değiştir</span>
  </v-tooltip>
</template>

<script>
/*eslint-disable*/
export default {
  name: "LayoutSpeedDial",
  props: {
    showFab: {
      type:Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      fab: false,
      showGridFAB: false,
    }
  },
  created () {

  },
  methods: {
    fabMainClicked (fabState) {

    },
    fabChildClicked (childItemID) {
      this.$emit('fabChildEvent', childItemID);
    }
  }
}
</script>

<style scoped>
.speedDialFab {
  left: 50%;
  transform: translateX(-50%) scale(0);
}

.hide_fab {
  transform: scale(0);
  transition: all 0.3s ease-out;
}

.show_fab {
  transform: scale(1);
  transition: all 0.3s ease-in;
}
</style>
