<template>
  <v-dialog v-model="showDialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        Yeni Eleman
      </v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col class="col-12">
            <v-text-field label="Label" v-model="ioListModel['label']" />
          </v-col>
          <v-col class="col-12">
            <v-autocomplete
                label="Bağlı Alan"
                :items="bagliAlanlar"
                item-value="Id"
                item-text="Text"
                v-model="bagliAlanModel"
                @input="changeBagliAlan"
            ></v-autocomplete>
          </v-col>
          <v-col class="col-12">
            <v-autocomplete
                v-if="ioListModel['tip'] === '1' || ioListModel['tip'] === '8'"
                label="Gösterge"
                :items="uiItems"
                item-text="Text"
                item-value="Id"
                v-model="ioListModel['input_ui']"
            />
          </v-col>
          <v-col v-if="ioListModel['tip'] === '8' && ioListModel['input_ui'] === '4'" class="col-12">
            <v-text-field label="Birim Değer" v-model="ioListModel['birim_text']" />
          </v-col>
          <v-row no-gutters>
            <v-col class="col-6 px-1">
              <v-text-field
                  label="0 Değer Etiket"
                  v-model="ioListModel['zero_label']"
              >
                <v-icon
                    :color="'#' + ioListModel['zero_color']"
                    slot="prepend"
                    class="zeroLabelIcon"
                >
                  mdi-label
                </v-icon>
              </v-text-field>
            </v-col>
            <v-col class="col-6 px-1">
              <v-text-field
                  label="1 Değer Etiket"
                  v-model="ioListModel['one_label']"
              >
                <v-icon
                    :color="'#' + ioListModel['one_color']"
                    slot="prepend"
                    class="oneLabelIcon"
                >
                  mdi-label
                </v-icon>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col class="col-6 px-1">
              <v-menu
                  ref="cpRef"
                  v-model="zeroColorMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-x
                  min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      label="0 değeri renk"
                      readonly
                      v-on="on"
                      v-model="ioListModel['zero_color']"
                  >
                    <v-icon
                        :color="'#' + ioListModel['zero_color']"
                        slot="prepend"
                        class="zeroLabelIcon"
                    >
                      mdi-invert-colors
                    </v-icon>
                  </v-text-field>
                </template>
                <v-color-picker
                    v-model="ioListModel['zero_color']"
                    @input="change0Color"
                ></v-color-picker>
              </v-menu>
            </v-col>
            <v-col class="col-6 px-1">
              <v-menu
                  ref="cpRef"
                  v-model="oneColorMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-x
                  min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      label="1 değeri renk"
                      readonly
                      v-on="on"
                      v-model="ioListModel['one_color']"
                  >
                    <v-icon
                        :color="'#' + ioListModel['one_color']"
                        slot="prepend"
                    >
                      mdi-invert-colors
                    </v-icon>
                  </v-text-field>
                </template>
                <v-color-picker
                    v-model="ioListModel['one_color']"
                    @input="change1Color"
                ></v-color-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-row>
        <v-divider class="py-2"></v-divider>
        <v-row no-gutters>
          <p class="ma-0">
            <span class="font-weight-medium text--black"
            >Varsayılan tanımlar</span
            >
            (Tercihe göre renk ve etiket seçimleri varsayılan değerlerle de
            yapılabilir.)
          </p>
        </v-row>
        <v-row no-gutters>
          <v-col class="col-12">
            <v-autocomplete
                label="Tanımlanmış Renkler"
                :items="tanimlanmisRenkler"
                @input="changeColors"
                item-text="Text"
                item-value="colors"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="col-12">
            <v-autocomplete
                label="Tanımlanmış Etiketler"
                :items="tanimlanmisEtiketler"
                @input="changeLabels"
                item-text="Text"
                item-value="labels"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="saveDialog">
          Tamam
        </v-btn>
        <v-btn color="blue darken-1" text @click="$emit('dismissNewDialog')">
          İptal
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
export default {
  name: "IOListNewForm",
  props: {
    showDialog: {
      type: Boolean,
      required: true
    },
    bagliAlanlar: {
      type: Array,
      required: true
    },
    lastItem: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      tanimlanmisRenkler: [
        { Id: "0", colors: ["F84C4C", "31DC6B"], Text: "Kırmızı-Yeşil" },
        { Id: "1", colors: ["31DC6B", "F84C4C"], Text: "Yeşil-Kırmızı" },
        { Id: "2", colors: ["CFCFCF", "31DC6B"], Text: "Gri-Yeşil" },
        { Id: "3", colors: ["31DC6B", "CFCFCF"], Text: "Yeşil-Gri" }
      ],
      tanimlanmisEtiketler: [
        { Id: "0", labels: ["Yok", "Var"], Text: "Yok-Var" },
        { Id: "1", labels: ["Var", "Yok"], Text: "Var-Yok" },
        {
          Id: "2",
          labels: ["Devrede", "Devrede Değil"],
          Text: "Devrede-Devrede Değil"
        },
        {
          Id: "3",
          labels: ["Devrede Değil", "Devrede"],
          Text: "Devrede Değil-Devrede"
        },
        { Id: "4", labels: ["Kapalı", "Açık"], Text: "Kapalı-Açık" },
        { Id: "5", labels: ["Açık", "Kapalı"], Text: "Açık-Kapalı" },
        { Id: "6", labels: ["Çalışıyor", "Durdu"], Text: "Çalışıyor-Durdu" },
        { Id: "7", labels: ["Durdu", "Çalışıyor"], Text: "Durdu-Çalışıyor" },
        { Id: "8", labels: ["Normal", "Korumada"], Text: "Normal-Korumada" },
        { Id: "9", labels: ["Korumada", "Normal"], Text: "Korumada-Normal" }
      ],
      bagliAlanModel: "",
      uiItems: [
        { Id: "0", Text: "Grafik" },
        { Id: "1", Text: "Lamba" },
        { Id: "2", Text: "Custom" },
        { Id: "3", Text: "List" },
        { Id: "4", Text: "Text" },
        { Id: "5", Text: "Object" },
      ],
      zeroColorMenu: false,
      oneColorMenu: false,
      ioListModel: {
        id: "",
        label: "Yeni Eleman",
        tip: "",
        birim: "",
        master_id: "",
        bit: "",
        zero_label: "0",
        zero_color: "cfcfcf",
        one_label: "1",
        one_color: "44c74e",
        input_ui: "1",
        x: 1,
        y: 0,
        h: 2,
        w: 1,
        i: 1
      }
    };
  },
  methods: {
    saveDialog: function() {
      this.ioListModel["id"] = uuidv4();
      this.ioListModel["i"] = this.lastItem.i === 0 ? 0 : this.lastItem.i + 1;
      this.$emit("saveNewDialog", this.ioListModel);
    },
    changeLabels: function(v) {
      console.log("Labels Changed", v);
      this.ioListModel["zero_label"] = v[0];
      this.ioListModel["one_label"] = v[1];
    },
    changeColors: function(v) {
      console.log("Colors Changed", v);
      this.ioListModel["zero_color"] = v[0];
      this.ioListModel["one_color"] = v[1];
    },
    getHexValue: function(value) {
      return value.slice(1, value.length - 2).toLowerCase();
    },
    change0Color: function(v) {
      this.ioListModel["zero_color"] = this.getHexValue(v);
    },
    change1Color: function(v) {
      this.ioListModel["one_color"] = this.getHexValue(v);
    },
    changeBagliAlan: function(v) {
      console.log("Change Bagli Alan", v.split("_"));
      const s_field = v.split("_");
      switch (s_field[0]) {
        case "1":
          this.ioListModel["tip"] = s_field[0];
          this.ioListModel["master_id"] = s_field[1];
          this.ioListModel["bit"] = s_field[2];
          this.ioListModel["birim"] = "";
          break;
        case "2":
          this.ioListModel["tip"] = s_field[0];
          this.ioListModel["master_id"] = s_field[1];
          this.ioListModel["bit"] = s_field[2];
          this.ioListModel["birim"] = s_field[3];
          break;
        case "3":
          this.ioListModel["tip"] = s_field[0];
          this.ioListModel["master_id"] = s_field[1];
          this.ioListModel["bit"] = "";
          this.ioListModel["birim"] = s_field[2];
          break;
        case "4":
          this.ioListModel["tip"] = s_field[0];
          this.ioListModel["master_id"] = s_field[1];
          this.ioListModel["bit"] = "";
          this.ioListModel["birim"] = s_field[2];
          break;
        case "8":
          this.ioListModel["tip"] = s_field[0];
          this.ioListModel["master_id"] = s_field[1];
          this.ioListModel["bit"] = "";
          this.ioListModel["birim"] = s_field[2] === "" ? "" : s_field[2];
          break;
      }
    }
  }
};
</script>

<style scoped></style>
