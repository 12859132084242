<template>
  <v-row no-gutters>
    <template v-for="(field, f_index) in getFieldData['Fields']">
      <v-col v-columnMode="getFieldData['ColumnCount']" :key="f_index">
        <v-card class="pa-2" flat tile>
          <v-form ref="duzenleForm" lazy-validation v-model="valid">
            <div
                v-for="(field_item, f_item_index) in field"
                :key="f_item_index"
            >
              <v-col
                  v-if="field_item['ElementType'] === 'text'"

              >
                <p style="color:blue; margin-bottom: 5px;">{{ field_item['Label'] }}</p>
                <p style=" margin-bottom: 5px;">{{ getModalData[field_item.Field] ?
                    getModalData[field_item.Field] : noText }}</p>
              </v-col>
              <v-col
                  no-gutters
                  v-if="field_item['ElementType'] === 'combo'"
              >
                <p style="color:blue; margin-bottom: 5px;">{{ field_item['Label'] }}</p>
                <p style=" margin-bottom: 5px;">{{setComboText(field_item)
                  }}
                </p>
              </v-col>
              <v-col
                  no-gutters
                  v-if="field_item['ElementType'] === 'textarea'"
              >
                <p style="color:blue; margin-bottom: 5px;">{{ field_item['Label'] }}</p>
                <p style=" margin-bottom: 5px;">{{ getModalData[field_item.Field] ?
                    getModalData[field_item.Field] : noText }}
                </p>
              </v-col>



            </div>
          </v-form>
        </v-card>
      </v-col>
      <v-responsive
          v-if="f_index === getFieldData['ColumnCount']"
          :key="`width-${f_index}`"
          width="100%"
      ></v-responsive>
    </template>
  </v-row>
</template>

<script>
export default {
  name: "incele_linked_list",
  data() {
    return {
      valid:true,
      noText: "----------------------",
    };
  },
  props: {
    tabItem: {
      type: Object,
      required: true
    },
    modalData: {
      type: Object,
      required: false
    },
    masterId: {
      required: false,
      type: [Array,String]
    },
  },
  computed: {
    /*
     * tabItem return. Fieldları içeriyor.
     * */
    getFieldData: function() {
      return this.tabItem;
    },
    /*
     * modalData return. Fieldlar dataları içeriyor. düzenle ve incele için kullanılacak.
     * Yeni kısmında bağlı bir field varsa(hidden fieldlar gibi) o zaman kullanılacak.
     * */
    getModalData: function() {
      return this.modalData;
    }
  },
  mounted() {
    console.log("get modal data",this.modalData);
  },
  methods:{
    setComboText: function(fieldItem) {

      let txtVal = "----------------------";
      let value = parseInt(this.modalData[fieldItem.Field]);
      if(fieldItem.ComboItems.Elements!==null){
        fieldItem.ComboItems.Elements.forEach(item => {
          if (value === item.Id) {
            txtVal = item.Text;
          }
        });
      }


      return txtVal;

      //return childData.data[fieldItem.Field]
    },
  }


};
</script>

<style scoped></style>