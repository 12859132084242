<template>
  <v-row no-gutters>
    <v-col cols="6" class="pa-0 align-self-center">
      <span>{{ switchData["label"] }}</span>
    </v-col>
    <v-col cols="6" class="pa-0">
      <v-row no-gutters class="justify-space-around">
        <span :style="'color:#' + switchData['zero_color']">{{
            switchData["zero_label"]
          }}</span>
        <v-switch
            :key="switchData['bit']"
            style="align-items: center;"
            inset
            :disabled="loadingState"
            color="indigo"
            class="mt-0"
            :hide-details="true"
            v-model="switchModel"
            :value="getOutputData"
            true-value="1"
            false-value="0"
            @change="sendOutputSwitch(switchData)"
        >
        </v-switch>
        <span :style="'color:#' + switchData['one_color']">{{
            switchData["one_label"]
          }}</span>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { findBinaryValue } from "@/components/ModemLayoutWizard/ModemLayoutSettings/svgComponentUtils/svgUtilFunctions";
import { mapGetters } from "vuex";

export default {
  name: "slideSwitch",
  props: {
    switchData: {
      required: true,
      type: Object
    },
    modemId: {
      type: Number,
      required: true
    },
    outputData: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      switchModel: "0",
      switchTimeout: null,
      loadingState: false,
      setOutput: {
        type: "setModemOutput",
        payload: { Target: "", Value: 0, ModemId: 0 }
      },
      timeout: 10000
    };
  },
  computed: {
    ...mapGetters({
      getterSocketMessage: "storeSocket/getterSocketMessage"
    }),
    /*
     * If outputData prop changes, this computed prop will fire.
     * This computed prop is bound to <v-switch :value > attribute
     * So any changes in this prop is going to effect the switch.
     * This way we dont need to use the watch object or subscribe to
     * mutations for each component. We already do that in upmost parent component
     * which is ListSetup.vue
     * */
    getOutputData: function() {
      return findBinaryValue(this.outputData, this.switchData["bit"]);
    }
  },
  beforeDestroy() {
    this.switchTimeout && clearTimeout(this.switchTimeout);
  },
  created() {
    this.switchModel = findBinaryValue(this.outputData, this.switchData["bit"]);
  },
  watch: {
    /*
    * In order to reset timeout & switch disabled state
    * we need to watch the changes to getOutputData
    * */
    getOutputData: function (value) {
      console.log("Switch Model Watch getOutputData", value);
      this.switchTimeout && clearTimeout(this.switchTimeout);
      this.loadingState = false;
    }
  },
  methods: {
    /*
    * Return switch to the old state if timeout is not cleared yet.
    * */
    funTimeout: function(value) {
      console.log("Timeout")
      this.loadingState = false;
      this.switchModel = value === "1" ? "0" : "1";
    },
    sendOutputSwitch: function(swData) {
      this.loadingState = true;
      const { bit, master_id, tip } = swData;
      this.setOutput["payload"]["Value"] = Number(this.switchModel);
      this.setOutput["payload"]["ModemId"] = this.modemId;
      this.setOutput["payload"]["Target"] = `${tip}_${master_id}_${bit}`;
      console.log("Setting Output Status", this.setOutput);
      console.log("Clicked SW MODEL", this.switchModel);
      this.sendMessageToWS(this.setOutput);
      this.switchTimeout = setTimeout(
          this.funTimeout,
          this.timeout,
          this.switchModel
      );
    }
  }
};
</script>

<style scoped></style>
