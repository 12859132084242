<template>
  <div>
    <custom-dialog-alert
        eventPositive="dtFormSave"
        eventNegative="dtFormCancel"
        @dtFormSave="dtFormSave"
        @dtFormCancel="dtFormCancel"
        :dynamic-prop="formDialogProp"
    />
    <v-snackbar
        v-if="createSnackbar.visible"
        :color="createSnackbar.color"
        v-model="createSnackbar.visible"
        :timeout="5000"
    >
      {{ createSnackbar.message }}

      <template v-slot:action="{ attrs }">
        <v-btn
            icon
            fab
            color="white"
            text
            v-bind="attrs"
            @click="createSnackbar.visible = false"
        >
          <v-icon>clear</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <basic-dialog
        @dialogDismissed="basicDialogDismissed"
        :dialog-object="basicDialogObject"
    />
    <div v-if="showTableForm">
      <component
          :is="actionType"
          :form-layout="formResponse.layout"
          :form-data="formResponse.data"
          :socket-request="socketRequest['payload']"
          @backPressed="formBackPressed"
      />
    </div>
    <div v-else>
      <!-- app bar toolbar -->
      <!-- app bar filter -->
      <!-- table -->
      <v-app-bar
          height="auto"
          :extended="false"
          class="table_toolbar"
          color="dynamicTableToolbar"
      >
        <v-col class="col-12">
          <v-row no-gutters class="align-self-center justify-space-between">
            <div class="d-flex align-self-center">
              <v-toolbar-title class="pl-2 white--text"
              ><v-icon class="pr-2" color="white"
              >icon-icon-{{ tableLayoutData.icon }}</v-icon
              >
                {{ tableLayoutData.title }}</v-toolbar-title
              >
            </div>
            <v-spacer></v-spacer>
            <div class="d-flex align-self-center">
              <filter-component
                  :filter-data="tableLayoutData.filters"
                  :filter-target="socketRequest.payload.Target"
                  :badge-count="socketRequest.payload.Data.Filters.length"
                  @filterEvent="filterEvent"
              />
            </div>
            <div class="align-self-center">
              <toolbar-buttons
                  v-if="toolbarButtons !== undefined"
                  :button-props="toolbarButtons"
                  @toolbarEvent="tableUtilityEvent"
                  :is-speed-dial="
                  $vuetify.breakpoint.name !== 'lg' &&
                    $vuetify.breakpoint.name !== 'xl'
                "
              />
            </div>
          </v-row>
          <v-row no-gutters>
            <pagination
                :total-page="computeTotalPage"
                :page-number="pageNumber"
                @toFirst="toFirst"
                @toLast="toLast"
                @toPrevious="toPrevious"
                @toNext="toNext"
                @limitChanged="limitChanged"
            />
          </v-row>
        </v-col>
      </v-app-bar>

      <chips-filter
          v-if="socketRequest.payload.Data.Filters.length > 0"
          :filters="socketRequest.payload.Data.Filters"
          :filter-fields="tableLayoutData.filters"
          @removeAllFilters="removeAllFilters"
          @removeFilter="removeFilter"
      />

      <v-col class="col-12">
        <v-skeleton-loader
            v-if="!tableDataAvailable"
            v-bind="attrs"
            type="table"
            loading
        ></v-skeleton-loader>
      </v-col>

      <v-col
          v-if="tableDataAvailable"
          class="simple_table_wrapper"
          sm="auto"
          md="auto"
          lg="auto"
          xl="auto"
      >
        <v-simple-table>
          <template v-slot:default>
            <thead>
            <tr>
              <th
                  v-for="(column, colIndex) in tableLayoutData['columns']"
                  :key="column['Field']"
                  class="text-left"
              >
                {{ column["Label"] }}
                <slot>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                          v-on="on"
                          @click="sortTable(sortingProps[colIndex], colIndex)"
                          icon
                          color="black"
                          right
                      >
                        <v-icon>{{ sortingProps[colIndex]["Icon"] }}</v-icon>
                      </v-btn>
                    </template>
                    <span>{{
                        column["Label"] +
                        " " +
                        sortingProps[colIndex]["Tooltip"]
                      }}</span>
                  </v-tooltip>
                </slot>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(row, rowIndex) in tableRowData"
                :key="rowIndex"
                style="cursor:pointer;"
                @click="clickRowEvent(row)"
                @dblclick="dblClickRowEvent(row)"
            >
              <td v-for="(value, valueIndex) in row" :key="valueIndex">
                <slot
                    v-if="
                      tableLayoutData['columns'][valueIndex]['ColumnType'] ===
                        'checkbox'
                    "
                >
                  <v-checkbox
                      :value="row"
                      v-model="tableRowModel"
                      :checked="tableRowModel.includes(row)"
                      class="mx-2"
                  ></v-checkbox>
                </slot>
                <slot
                    v-else-if="
                      tableLayoutData['columns'][valueIndex]['ColumnType'] ===
                        'radio'
                    "
                >
                  <v-radio-group multiple v-model="tableRowModel">
                    <v-radio class="mx-2" :value="row"></v-radio>
                  </v-radio-group>
                </slot>
                <slot v-else>
                  <span>{{ value }}</span>
                </slot>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>

      <v-row class="no-gutters" style="width: 100%">
        <v-slider
            v-if="computeTotalPage !== 1"
            hide-details
            class="pl-12 pr-12 col-12"
            v-model="sliderNumber"
            :value="sliderNumber"
            :step="stepInterval"
            ticks
            :max="sliderTotal"
            min="1"
            thumb-label
            tick-size="8"
            @click="sliderClickEvent"
        ></v-slider>
      </v-row>

      <div :class="defaultBottomFilter" class="bottomFilterDefaultClass">
        <v-card color="indigo px-2">
          <v-row class="align-center">
            <filter-component
                class="ml-2 mr-2"
                :filter-data="tableLayoutData.filters"
                :filter-target="socketRequest.payload.Target"
            />
            <v-divider dark vertical inset></v-divider>
            <!--            <toolbar-buttons
              class="ml-2 mr-2"
              v-if="toolbarButtons !== undefined"
              :button-props="toolbarButtons"
              @toolbarEvent="tableUtilityEvent"
              :is-speed-dial="$vuetify.breakpoint.name === 'xs'"
              speed-dial-direction="top"
            />-->
          </v-row>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import FilterMenuComponent from "./FilterMenuComponent";
import ChipComponent from "./ChipComponent";
import BasicDialogComponent from "../DialogComponent/BasicDialogComponent";
import CustomDialogAlert from "../DialogComponent/CustomDialogAlert";
import ToolbarButtonsComponent from "@/components/AppbarUtilityComponents/ToolbarButtonsComponent";
import PaginationComponent from "@/components/AppbarUtilityComponents/PaginationComponent";
import { mapGetters } from "vuex";
import store from "../../vuex_store/store_wrapper";
import yeni from "@/components/AppbarUtilityComponents/Forms/yeni";
import incele from "@/components/AppbarUtilityComponents/Forms/incele";
import duzenle from "@/components/AppbarUtilityComponents/Forms/duzenle";

export default {
  name: "DynamicTableComponent",
  components: {
    "filter-component": FilterMenuComponent,
    "chips-filter": ChipComponent,
    "basic-dialog": BasicDialogComponent,
    "custom-dialog-alert": CustomDialogAlert,
    "toolbar-buttons": ToolbarButtonsComponent,
    pagination: PaginationComponent,
    yeni: yeni,
    incele: incele,
    duzenle: duzenle
  },
  props: {
    socketRequest: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      attrs: {
        class: "mb-6",
        boilerplate: false,
        elevation: 2
      },
      tableDataAvailable: false,
      defaultBottomFilter: "bottomFilterHidden",
      unsubscribe: null,

      pageLimit: 10,
      stepInterval: 1,
      sliderNumber: 1,
      sliderTotal: 1,

      pageNumber: 1,
      tableRowModel: [],

      formRequest: {},
      showTableForm: false,

      basicDialogObject: {
        show: false,
        message: "İşlem yapmak için yalnız bir seçim yapmalısınız."
      },
      snackbarObject: {
        visible: false,
        message: "",
        color: ""
      },
      formDialogProp: {},
      sortingProps: [],
      sortCount: 0,

      actionType: "",
      formResponse: {
        layout: {},
        data: {}
      },
      tableRowData: {},
      tableRowCount: 0,
      tableLayoutData: {},
      toolbarButtons: [],
      chipsFilter: []
    };
  },
  computed: {
    ...mapGetters({
      getterSocketMessage: "storeSocket/getterSocketMessage",
      getterIsSocketConnected: "storeSocket/getterIsSocketConnected"
    }),
    createSnackbar: {
      get: function() {
        return this.snackbarObject;
      },
      set: function(snackbar) {
        this.snackbarObject = snackbar;
      }
    },
    computeTotalPage: function() {
      return this.tableRowCount !== 0
          ? Math.ceil(this.tableRowCount / this.pageLimit)
          : 0;
    },
    getTargetId: function() {
      return this.socketRequest.payload.Data.TargetId;
    }
  },
  watch: {
    /*Remove key event listeners when form is visible*/
    showTableForm: function(value) {
      if (value) {
        document.removeEventListener("scroll", this.tableComponentScrolled);
      } else {
        document.addEventListener("scroll", this.tableComponentScrolled);
      }
    },
    tableRowModel: function(value) {
      if (window.pageYOffset > 80 && value.length > 0) {
        this.defaultBottomFilter = "bottomFilterShown";
      } else {
        this.defaultBottomFilter = "bottomFilterHidden";
      }
    },
    pageNumber: function(number) {
      console.log("Page Num", number);
      this.sliderNumber = number;
      this.socketRequest["payload"]["Data"]["Page"] = number;
      this.sendMessageToWS(this.socketRequest);
    },
    computeTotalPage: function(pageNumber) {
      this.pageNumber = 1;
      this.sliderTotal = pageNumber;
      console.log("Page Number", pageNumber);
    }
  },
  beforeDestroy() {
    this.unsubscribe && this.unsubscribe();
    document.removeEventListener("scroll", this.tableComponentScrolled);
    this.resetRequest();
  },
  beforeRouteEnter: function(to, from, next) {
    console.log("Route Enter Before Dynamic Table Component");
    next(vm => {
      vm.sendRequest();
    });
  },
  created() {
    document.addEventListener("scroll", this.tableComponentScrolled);
    console.log("TABLE DYNAMIC TARGET ID", this.socketRequest);
    this.unsubscribe = store.subscribe(mutation => {
      const { type, payload } = mutation;
      if (type === "storeSocket/on_socket_message") {
        const { command, data, layout, message } = JSON.parse(payload.data);
        if (command === "TableData" && layout.target === "DynamicTable") {
          const { RowCount, Rows } = data;
          this.tableRowData = Rows;
          this.tableRowCount = RowCount;
          this.tableLayoutData = layout.data;
          this.toolbarButtons = layout.data.toolbar.Elements;
          if (this.sortCount === 0) {
            this.sortingProps = layout.data.columns.map(col => {
              col["Dir"] = "Asc";
              col["Icon"] = "keyboard_arrow_up";
              col["Tooltip"] = "satırını artan şekilde sırala";
              return col;
            });
          }
          this.tableDataAvailable = true;
        } else if (command === "FormData") {
          if (layout.target === this.getTargetId) {
            console.log("Form Data", data);
            this.formResponse = {
              layout,
              data
            };
            this.showTableForm = true;
          }
        } else if (command === "FormCommandResponse") {
          const { type, text } = message;
          if (type === "Success") {
            const { TargetID } = data;
            if (TargetID === this.getTargetId) {
              this.formSuccess(text, type);
            }
          } else {
            // error
            this.createSnackbar = {
              visible: true,
              message: text,
              color: type.toLowerCase()
            };
          }
        }
      }
    });
  },
  methods: {
    /*Resets the request to default when beforeDestroy is called.*/
    resetRequest: function() {
      this.socketRequest["payload"]["Data"] = {
        Onlydata: false,
        TargetId: "DynamicTable",
        Limit: 10,
        Page: 1,
        Filters: []
      };
    },
    tableComponentScrolled: function() {
      if (window.pageYOffset > 80) {
        this.tableRowModel.length > 0
            ? (this.defaultBottomFilter = "bottomFilterShown")
            : (this.defaultBottomFilter = "bottomFilterHidden");
      } else {
        this.defaultBottomFilter = "bottomFilterHidden";
      }
    },
    sendRequest: function() {
      if (this.getterIsSocketConnected) {
        this.sendMessageToWS(this.socketRequest);
      }
    },
    clickRowEvent: function(row) {
      console.log("Row", row);
      if (this.tableRowModel.length > 0) {
        this.tableRowModel.splice(0, this.tableRowModel.length);
      }
      this.tableRowModel.push(row);
    },
    dblClickRowEvent: function(row) {
      const dataId = row[0];
      this.formRequest = {
        type: "object",
        payload: {
          Target: this.socketRequest.payload.Target,
          Action: "duzenle",
          Data: {
            Id: Number(dataId),
            TargetId: this.socketRequest.payload.Data.TargetId
          }
        }
      };
      this.actionType = "duzenle";
      this.sendMessageToWS(this.formRequest);
      console.log("DBL Click Edit Request", this.formRequest);
    },
    sortTable: function(col) {
      console.log("Clicked Sort", col);
      let { Dir, Field } = col;
      this.socketRequest["payload"]["Data"]["OrderBy"] = Field;
      this.socketRequest["payload"]["Data"]["Dir"] = Dir;
      if (Dir === "Asc") {
        console.log("Is Asc");
        col["Dir"] = "Desc";
        col["Icon"] = "keyboard_arrow_down";
        col["Tooltip"] = "satırını azalan şekilde sırala";
      } else if (Dir === "Desc") {
        col["Dir"] = "Asc";
        col["Icon"] = "keyboard_arrow_up";
        col["Tooltip"] = "satırını artan şekilde sırala";
      }
      this.sortCount += 1;
      this.sendMessageToWS(this.socketRequest);
    },
    tableUtilityEvent: function(data) {
      console.log("Utility Button Clicked", data);
      const { Task, Need_validation } = data;
      if (Need_validation) {
        //duzenle, incele, sil tasks here because they need to be validated.
        if (this.tableRowModel.length > 0) {
          const rowId = this.tableRowModel[0][0];
          if (Task === "sil") {
            this.deleteEntry(rowId);
          } else {
            this.formRequest = {
              type: "object",
              payload: {
                Target: this.socketRequest.payload.Target,
                Action: Task,
                Data: {
                  Id: Number(rowId),
                  TargetId: this.socketRequest.payload.Data.TargetId
                }
              }
            };
            this.actionType = Task;
            this.sendMessageToWS(this.formRequest);
          }
          console.log("Utility Request", this.formRequest);
        } else {
          //show dialog "No Row Selected"
          console.log("No Row Selected");
          this.showTableForm = false;
          this.basicDialogObject.show = true;
        }
      } else {
        //Task: yeni here because it has no validation.
        this.formRequest = {
          type: "object",
          payload: {
            Target: this.socketRequest.payload.Target,
            Action: "yeni",
            Data: { TargetId: this.socketRequest.payload.Data.TargetId }
          }
        };
        console.log("Utility Request Add New", this.formRequest);

        this.sendMessageToWS({
          type: "object",
          payload: {
            Target: this.socketRequest.payload.Target,
            Action: "yeni",
            Data: { TargetId: this.socketRequest.payload.Data.TargetId }
          }
        });
        this.actionType = "yeni";
      }
    },
    dtFormSave: function(r) {
      console.log("Form SAVE DT", r);
      const { customRequest } = r;
      this.sendMessageToWS(customRequest);
      this.formDialogProp.dialogModel = false;
    },
    dtFormCancel: function() {
      console.log("Form CANCEL DT");
      this.formDialogProp.dialogModel = false;
    },
    deleteEntry: function(id) {
      let deleteRq = {
        type: "object",
        payload: {
          Target: this.socketRequest.payload.Target,
          Action: "sil",
          Data: {
            Id: [Number(id)],
            TargetId: this.socketRequest.payload.Data.TargetId
          }
        }
      };

      this.formDialogProp = {
        dialogModel: true,
        dialogTitle: "Silme işlemi",
        dialogMessage: "Bu kaydı silmek istediğinize emin misiniz",
        textPButton: "Tamam",
        textNButton: "İptal",
        customRequest: deleteRq,
        from: "DynamicTableComponent"
      };
    },
    sliderClickEvent: function() {
      this.pageNumber = this.sliderNumber;
    },
    closeDialog: function() {
      this.showTableForm = false;
    },
    basicDialogDismissed: function() {
      this.basicDialogObject.show = false;
    },
    /*
     * Pagination events that bound to PaginationComponent
     * */
    limitChanged: function(v) {
      console.log("Limit Changed", v);
      this.socketRequest["payload"]["Data"]["Limit"] = v;
      this.sendMessageToWS(this.socketRequest);
    },
    toFirst: function() {
      console.log("To First Called");
      this.pageNumber = 1;
    },
    toLast: function() {
      console.log("To Last Called");
      this.pageNumber = this.computeTotalPage;
    },
    toNext: function() {
      console.log("To Next Called");
      this.pageNumber = this.pageNumber + 1;
    },
    toPrevious: function() {
      console.log("To Previous Called");
      this.pageNumber = this.pageNumber - 1;
    },
    /*
     * Form Back button event called.
     * Close the form component and reset the :is prop => actionType
     * */
    formBackPressed: function() {
      this.showTableForm = false;
      this.actionType = "";
    },
    /*
     * Form Success Event received from form component.
     * disable form component & send updated request on save
     * */
    formSuccess: function(message, type) {
      console.log("Form Successs", message);
      this.showTableForm = false;
      this.actionType = "";
      this.createSnackbar = {
        visible: true,
        message: message,
        color: type.toLowerCase()
      };

      //triggers watch object and makes a request for updated table
      this.socketRequest["payload"]["Data"]["Limit"] = 10;
      this.pageNumber = 1;
    },
    filterEvent: function(payload) {
      console.log("Filter Event Received", payload);
      this.socketRequest.payload.Data.Filters = payload;
      this.sendMessageToWS(this.socketRequest);
    },
    removeAllFilters: function() {
      console.log("Remove All Filters Event Received");
      this.socketRequest.payload.Data.Filters = [];
      this.sendMessageToWS(this.socketRequest);
    },
    removeFilter: function(attrs) {
      let { chip, i } = attrs;
      console.log("Chip Remove Event Before", chip);
      chip["Data"].splice(i, 1);
      console.log("Chip Remove Event After", chip);
      if (chip["Data"].length === 0) {
        this.socketRequest.payload.Data.Filters = this.socketRequest.payload.Data.Filters.filter(
            f => {
              if (f["Field"] !== chip["Field"]) {
                return f;
              }
            }
        );
      } else {
        this.socketRequest.payload.Data.Filters.forEach(m => {
          if (m["Field"] === chip["Field"]) {
            m["Data"] = chip["Data"];
          }
        });
      }

      console.log("Filters", this.socketRequest.payload.Data.Filters);
      this.sendMessageToWS(this.socketRequest);
    }
  }
};
</script>

<style scoped>
.bottomFilterDefaultClass {
  bottom: 0;
  left: 50%;
  transform: translate(-50% 0);
  position: fixed;
}

.bottomFilterHidden {
  transform: translate(-50%, 50px);
  transition: 0.5s ease-out;
}

.bottomFilterShown {
  transform: translate(-50%, 0);
  transition: 0.5s ease-out;
}

.custom_slider_wrapper {
  position: sticky !important;
  bottom: 0;
  width: 100%;
}
</style>
