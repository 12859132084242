<template>
  <div v-if="!isSpeedDial">
    <v-tooltip v-for="item in buttonProps" :key="item.Class" color="red" bottom>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" @click="tableUtilityEvent(item)" icon>
          <v-icon color="white">{{ getIcons(item.Class) }}</v-icon>
        </v-btn>
      </template>
      <span>{{ item.Label }}</span>
    </v-tooltip>
  </div>
  <div v-else>
    <v-speed-dial
        style="z-index: 99 !important;"
        v-model="fab"
        :bottom="false"
        :direction="speedDialDirection"
        transition="slide-y-reverse-transition"
    >
      <template v-slot:activator>
        <v-btn small v-model="fab" icon>
          <v-icon color="white" v-if="fab">
            mdi-close
          </v-icon>
          <v-icon color="white" v-else>
            mdi-dots-vertical
          </v-icon>
        </v-btn>
      </template>
      <v-tooltip v-for="item in buttonProps" :key="item.Class" color="red" left>
        <template v-slot:activator="{ on }">
          <v-btn
              v-on="on"
              fab
              dark
              color="indigo"
              small
              @click="tableUtilityEvent(item)"
          >
            <v-icon color="white">{{ getIcons(item.Class) }}</v-icon>
          </v-btn>
        </template>
        <span>{{ item.Label }}</span>
      </v-tooltip>
    </v-speed-dial>
  </div>
</template>

<script>
export default {
  name: "ToolbarButtonsComponent",
  props: {
    buttonProps: {
      type: Array,
      required: true
    },
    isSpeedDial: {
      type: Boolean,
      required: false,
      default: false
    },
    speedDialDirection: {
      type: String,
      required: false,
      default: "bottom"
    },
    eventName: {
      type: String,
      required: false,
      default: "toolbarEvent"
    }
  },
  data() {
    return {
      fab: false
    };
  },
  created() {
    console.log("BUTTON PROPS", this.buttonProps);
  },
  methods: {
    tableUtilityEvent: function(item) {
      this.$emit(this.eventName, item);
    },
    getIcons: function(icon) {
      switch (icon) {
        case "back_button_class":
          return "backspace";
        case "edit_button_class":
          return "create";
        case "delete_button_class":
          return "delete";
        case "view_button_class":
          return "search";
        case "new_button_class":
          return "add";
        case "icon-copy":
          return "content_copy";
        case "kaydet":
          return "save";
      }
    }
  }
};
</script>

<style scoped>
.v-speed-dial__list {
  z-index: 9 !important;
}
</style>
