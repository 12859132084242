//global websocket plugin.
import store from "./src/vuex_store/store_wrapper";

let wsPlugin = {};

//Vue gerekli plugin instance için.
//options optional parametre
wsPlugin.install = function(Vue, options) {
  let connectManually = options.connectManually;
  //let reconnectInterval = options.reconnectInterval || 1000;
  let ws;

  if (connectManually) {
    //otomatik socket bağlantısı - geçilecek data varsa
    Vue.prototype.connectToSocket = socket_message => {
      ws = new WebSocket(options.wssUrl);

      ws.onopen = e => {
        if (e.type === "open") {
          ws.send(JSON.stringify(socket_message));
          store.dispatch("storeSocket/actionSocketOpened").then(() => {
            console.log("Connected to socket: ", e);
          });
        }
      };

      // check later.
      ws.onmessage = e => {
        //console.log("Message from server: ", e);
        const data = JSON.parse(e.data);

        if (data.message !== null) {
          if (
            data.message.type === "Error" &&
            data.message.text === "Token expired"
          ) {
            const dispatchLogoutResult = store.dispatch(
              "storeLoginEvents/actionLogout"
            );
            console.log("Dispatch Logout Response", dispatchLogoutResult);
          }
        }
        store.dispatch("storeSocket/actionSocketMessage", e);
      };

      ws.onerror = e => {
        store.dispatch("storeSocket/actionSocketError", "SOCKET_ERROR");
        if (
          store.getters["storeGridEvents/getCurrentRoute"]["name"] !==
          "LoginView"
        ) {
          store.dispatch("storeLoginEvents/actionLogout");
        }
        console.log("Error connecting to socket: ", e);
      };

      ws.onclose = e => {
        if (e.code === 1005) {
          console.log("Socket closed: ", e);
          store.dispatch("storeSocket/actionSocketClosed", e.code);
        } else {
          console.log("Socket closed: ", e);
          store.dispatch("storeSocket/actionSocketClosed", e.code);
        }
        //ws = null;
      };
    };

    Vue.prototype.sendMessageToWS = data => {
      //bağlantı varsa kontrolü ekle
      console.log("Send mes =>", JSON.stringify(data));
      ws.send(JSON.stringify(data));
    };

    Vue.prototype.sendMessageToWSNoObject = data => {
      console.log("sendMessageToWSNoObject", data);
      //bağlantı varsa kontrolü ekle
      ws.send(data);
    };

    //disconnect manually
    Vue.prototype.disconnectFromSocket = () => {
      if (ws !== null) {
        ws.close();
        console.log("Manuel close called !");
      }
    };
  } else {
    //manuel socket bağlantısı
    ws = new WebSocket(options.wssUrl);

    ws.onopen = () => {};

    ws.onmessage = () => {};
  }
};

export default wsPlugin;
