var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-form',{ref:"single-form",staticClass:"col-12",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.fieldsData['Fields']),function(fields,fieldIndex){return _c('v-col',{key:fieldIndex,staticClass:"pa-2",class:_vm.$vuetify.breakpoint.name === 'sm' ||
          _vm.$vuetify.breakpoint.name === 'xs'
            ? 'col-12'
            : ("col-" + (12 / _vm.fieldsData['ColumnCount']))},[_vm._l((fields),function(field){return [(field['ElementType'] === 'text')?_c('v-text-field',{key:field['Field'],attrs:{"label":field['Label'],"readonly":""},model:{value:(_vm.stickyData[field['Field']]),callback:function ($$v) {_vm.$set(_vm.stickyData, field['Field'], $$v)},expression:"stickyData[field['Field']]"}}):_vm._e(),(field['ElementType'] === 'password')?_c('v-text-field',{key:field['Field'],attrs:{"label":field['Label'],"append-icon":_vm.show_password ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show_password ? 'text' : 'password',"readonly":""},on:{"click:append":function($event){_vm.show_password = !_vm.show_password}},model:{value:(_vm.stickyData[field['Field']]),callback:function ($$v) {_vm.$set(_vm.stickyData, field['Field'], $$v)},expression:"stickyData[field['Field']]"}}):_vm._e(),(
              field['ElementType'] === 'combo' &&
                !field['CustomData'].hasOwnProperty('check_scope')
            )?_c('v-autocomplete',{key:field['Field'],attrs:{"label":field['Label'],"items":field['ComboItems']['Elements'],"item-value":"Id","item-text":"Text","value":_vm.stickyData.hasOwnProperty(field['Field']) &&
                Number(_vm.stickyData[field['Field']]),"readonly":""}}):_vm._e(),(
              field['ElementType'] === 'combo' &&
                field['CustomData'].hasOwnProperty('check_scope')
            )?_c('v-autocomplete',{key:field['Field'],attrs:{"label":field['Label'],"items":field['ComboItems']['Elements'],"item-value":"Id","item-text":"Text","readonly":"","value":_vm.stickyData.hasOwnProperty(field['Field']) &&
                Number(_vm.stickyData[field['Field']])}}):_vm._e(),(field['ElementType'] === 'ModemSelectList')?_c('modem-permissions-comp',{key:field['Field'],attrs:{"id-property":_vm.stickyData[field['Field']],"read-only":true}}):_vm._e(),(field['ElementType'] === 'editor')?_c('text-editor-component',{key:field['Field'],attrs:{"read-only":true,"field":field,"dynamic-m":_vm.stickyData}}):_vm._e(),(field['ElementType'] === 'fileSelectFromServer')?_c('file-upload-comp',{key:field['Field'],attrs:{"field":field,"read-only":true,"field-item":field,"dynamic-m":_vm.stickyData},model:{value:(_vm.stickyData[field['Field']]),callback:function ($$v) {_vm.$set(_vm.stickyData, field['Field'], $$v)},expression:"stickyData[field['Field']]"}}):_vm._e(),(field['ElementType'] === 'modemFieldSelector')?_c('modem-field-selector',{key:field['Field'],attrs:{"action-type":"incele","field-item":field,"dynamic-m":_vm.stickyData,"read-only":true},model:{value:(_vm.stickyData[field['Field']]),callback:function ($$v) {_vm.$set(_vm.stickyData, field['Field'], $$v)},expression:"stickyData[field['Field']]"}}):_vm._e(),(field['ElementType'] === 'pidFieldSelector')?_c('p-id-field-selector-component',{key:field['Field'],attrs:{"field-item":field,"dynamic-m":_vm.stickyData,"read-only":true,"action-type":"incele"}}):_vm._e(),(field['ElementType'] === 'watchFieldSelector')?_c('watch-field-selector-component',{key:field['Field'],attrs:{"field-item":field,"dynamic-m":_vm.stickyData,"read-only":true,"action-type":"incele"}}):_vm._e(),(field['ElementType'] === 'labelEditText')?_c('label-edit-text-component',{key:field['Field'],attrs:{"field":field,"read-only-value":_vm.stickyData[field['CustomData']['target']] !== ''
                ? JSON.parse(
                    decodeURIComponent(
                      _vm.stickyData[field['CustomData']['target']]
                    )
                  )[field['CustomData']['id']]
                : '',"data-value-model":_vm.stickyData,"action-type":"incele"}}):_vm._e(),(field['ElementType'] === 'interactiveList')?_c('interactive-list-component',{key:field['Field'],attrs:{"field":field,"model":_vm.stickyData,"interactive-target-value":Number(_vm.stickyData[field['CustomData']['filterField']]),"action-type":"incele"}}):_vm._e(),(field['ElementType'] === 'listConfigWizard')?_c('i-o-component',{key:field['Field'],attrs:{"action-type":"duzenle","dynamic-m":_vm.stickyData,"field-item":field,"modem-i-d":_vm.stickyData['modem_id'],"read-only":true}}):_vm._e(),(field['ElementType'] === 'alarmwizard')?_c('alarm-wizard',{key:field['Field'],attrs:{"field-name":field['Field'],"config-object":JSON.parse(_vm.stickyData[field['Field']]),"read-only":true}}):_vm._e(),(field['ElementType'] === 'linkedTable')?_c('linked-list-table',{key:field['Field'],attrs:{"field-item":field,"master-table-selected-row":[
              _vm.stickyData[field['CustomData']['DefaultFilter']]
            ]}}):_vm._e()]})],2)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }