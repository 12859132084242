var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.parentName !== 'ModemTabLayout')?_c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-card-title',{staticClass:"pl-0 py-0"},[_vm._v("Etiket ayarları")]),_c('v-col',{staticClass:"col-12"},[_c('v-text-field',{attrs:{"label":"Genişlik"},on:{"input":_vm.svgDimen},model:{value:(_vm.currentItem['genislik']),callback:function ($$v) {_vm.$set(_vm.currentItem, 'genislik', $$v)},expression:"currentItem['genislik']"}})],1),_c('v-col',{staticClass:"col-12"},[_c('v-text-field',{attrs:{"label":"Etiket"},on:{"input":_vm.changeCardLabel},model:{value:(_vm.currentItem['cardLabel']),callback:function ($$v) {_vm.$set(_vm.currentItem, 'cardLabel', $$v)},expression:"currentItem['cardLabel']"}})],1),_c('v-col',{staticClass:"col-6"},[_c('color-picker',{attrs:{"label":"Arkaplan On Renk","color-data":_vm.currentItem['colorsOn']['colorCardBase'],"coloring-type":"fill","field":{
          parent: 'colorsOn',
          child: 'colorCardBase',
          domObject: 'cardBaseRectangle'
        }},on:{"colorSelected":_vm.colorSelected}})],1),_c('v-col',{staticClass:"col-6"},[_c('color-picker',{attrs:{"label":"Arkaplan Off Renk","color-data":_vm.currentItem['colorsOff']['colorCardBase'],"coloring-type":"fill","field":{
          parent: 'colorsOff',
          child: 'colorCardBase'
        }},on:{"colorSelected":_vm.colorSelected}})],1),_c('v-col',{staticClass:"col-12"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"col-6"},[_c('color-picker',{attrs:{"label":"Etiket On Renk","color-data":_vm.currentItem['colorsOn']['colorLabel'],"coloring-type":"fill","field":{
              parent: 'colorsOn',
              child: 'colorLabel',
              domObject: 'cardLabel'
            }},on:{"colorSelected":_vm.colorSelected}})],1),_c('v-col',{staticClass:"col-6"},[_c('color-picker',{attrs:{"label":"Etiket Off Renk","color-data":_vm.currentItem['colorsOff']['colorLabel'],"field":{
              parent: 'colorsOff',
              child: 'colorLabel'
            }},on:{"colorSelected":_vm.colorSelected}})],1)],1)],1)],1),_c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-card-title',{staticClass:"pl-0 py-0 col-12"},[_vm._v("Input ayarları")])],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"col-12"},[_c('v-autocomplete',{attrs:{"hide-details":"","label":"İkon seçiniz","items":_vm.iconList,"item-value":"path","item-text":"key","auto-select-first":true},on:{"change":_vm.selectIcon},model:{value:(_vm.iconModel),callback:function ($$v) {_vm.iconModel=$$v},expression:"iconModel"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"col-6"},[_c('color-picker',{attrs:{"label":"İkon On Renk","color-data":_vm.currentItem['colorsOn']['colorInputIcon'],"field":{
            parent: 'colorsOn',
            child: 'colorInputIcon',
            domObject: 'cardIconGroup'
          }},on:{"colorSelected":_vm.colorSelected}})],1),_c('v-col',{staticClass:"col-6"},[_c('color-picker',{attrs:{"label":"İkon Off Renk","color-data":_vm.currentItem['colorsOff']['colorInputIcon'],"field":{
            parent: 'colorsOff',
            child: 'colorInputIcon'
          }},on:{"colorSelected":_vm.colorSelected}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-select',{attrs:{"items":_vm.bagliAlanItems,"label":"Baglı Input Alanı","item-text":"Text","item-value":"Id"},model:{value:(_vm.currentItem['input_bagli_alan']),callback:function ($$v) {_vm.$set(_vm.currentItem, 'input_bagli_alan', $$v)},expression:"currentItem['input_bagli_alan']"}})],1)],1),_c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-card-title',{staticClass:"pl-0 py-0 col-12"},[_vm._v("Output Ayarları")])],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"col-6 px-1"},[_c('v-text-field',{attrs:{"label":"1 Yazı"},model:{value:(_vm.currentItem['outputTextOn']),callback:function ($$v) {_vm.$set(_vm.currentItem, 'outputTextOn', $$v)},expression:"currentItem['outputTextOn']"}})],1),_c('v-col',{staticClass:"col-6 px-1"},[_c('v-text-field',{attrs:{"label":"0 Yazı"},model:{value:(_vm.currentItem['outputTextOff']),callback:function ($$v) {_vm.$set(_vm.currentItem, 'outputTextOff', $$v)},expression:"currentItem['outputTextOff']"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"col-6"},[_c('color-picker',{attrs:{"label":"1 Yazı Renk","color-data":_vm.currentItem['colorsOn']['colorOutputText'],"field":{
            parent: 'colorsOn',
            child: 'colorOutputText',
            domObject: 'cardOutputText'
          }},on:{"colorSelected":_vm.colorSelected}})],1),_c('v-col',{staticClass:"col-6"},[_c('color-picker',{attrs:{"label":"0 Yazı Renk","color-data":_vm.currentItem['colorsOff']['colorOutputText'],"field":{
            parent: 'colorsOff',
            child: 'colorOutputText'
          }},on:{"colorSelected":_vm.colorSelected}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-select',{attrs:{"items":_vm.bagliAlanItems,"label":"Baglı Output Alanı","item-text":"Text","item-value":"Id"},model:{value:(_vm.currentItem['output_bagli_alan']),callback:function ($$v) {_vm.$set(_vm.currentItem, 'output_bagli_alan', $$v)},expression:"currentItem['output_bagli_alan']"}})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }