// toolbar title, toolbar user
const state = {
    svgScaleInfo: {

    }
};

const getters = {
    getterSVGScaleInfo: state => {
        return state.svgScaleInfo;
    }
};

const mutations = {
    setSVGScaleInfo: (state, svgScaleInfo) => {
        state.svgScaleInfo = svgScaleInfo;
    }
};

const actions = {
    assignSVGScale({ commit }, scale_object) {
        commit("setSVGScaleInfo", scale_object);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
