<template>
  <v-menu
      open-on-hover
      v-model="connMenu"
      :close-on-content-click="false"
      nudge-left="100%"
      bottom
      :offset-y="true"
  >
    <template v-slot:activator="{ on }">
      <v-btn
          width="28"
          height="28"
          fab
          icon
          v-on="on"
          :class="
          getterIsSocketConnected ? 'status_pulse_green' : 'status_pulse_red'
        "
      >
        <v-icon color="white" size="18">{{getterIsSocketConnected ? 'check' : 'priority_high'}}</v-icon>
      </v-btn>
    </template>

    <v-card class="pa-2" outlined :color="themeOptions['cardColor']" flat>
      <div v-if="getterIsSocketConnected">
        <v-list-item-title class="py-1"
        >Bağlantı durumu:
          <span style="color:#52e795;font-weight:600;">Bağlantı var</span> 🚀
        </v-list-item-title>
      </div>
      <div v-else>
        <v-list-item-title class="py-1"
        >Bağlantı durumu:
          <span style="color:#ea4752;font-weight:600;">Bağlantı yok</span> 💀
        </v-list-item-title>
        <v-divider></v-divider>
        <v-card-actions class="justify-center pb-0">
          <v-btn
              @click="reconnectEv"
              :loading="reconnecting"
              :disabled="reconnecting"
              color="#45c37e"
              class="text-capitalize white--text"
          >Tekrar Bağlan<v-icon color="white" right>refresh</v-icon></v-btn
          >
        </v-card-actions>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import store from "@/vuex_store/store_wrapper";

export default {
  name: "ToolbarMenuConnectionStatus",
  data() {
    return {
      connMenu: false,
      reconnecting: false,
      unsubscribe: null
    };
  },
  props: {
    themeOptions: {
      type: Object,
      required: false,
      default: () => {
        return {
          iconColor: "toolbarIcons",
          cardColor: "popupMenuColor"
        };
      }
    }
  },
  computed: {
    ...mapGetters({
      getterSocketMessage: "storeSocket/getterSocketMessage",
      getterIsSocketConnected: "storeSocket/getterIsSocketConnected"
    })
  },
  beforeDestroy() {
    this.unsubscribe && this.unsubscribe();
  },
  created() {
    this.unsubscribe = store.subscribe(this.storeSubscribeHandler);
  },
  methods: {
    ...mapActions({
      //new store file for login events
      actionConnectToSocket: "storeLoginEvents/actionConnectToSocket",
      actionDisconnectFromSocket: "storeLoginEvents/actionDisconnectFromSocket"
    }),
    storeSubscribeHandler: function(res) {
      const { type } = res;
      if (type === "storeSocket/on_socket_close") {
        this.reconnecting = false;
      } else if (type === "storeSocket/on_socket_open") {
        this.reconnecting = false;
      }
    },
    reconnectEv: function() {
      this.reconnecting = true;
      this.actionConnectToSocket();
    }
  }
};
</script>

<style scoped>
.popupMenuColor.theme--dark.v-sheet--outlined {
  border: thin solid rgba(255, 255, 255, 0.12) !important;
}

.status_pulse_green {
  animation: pulse_green 2s infinite;
  box-shadow: 0 0 0 rgba(42, 169, 96, 0.6);
  background-color: #2aa960;
  display: block;
}

.status_pulse_red {
  animation: pulse_red 2s infinite;
  box-shadow: 0 0 0 rgba(169, 19, 23, 0.6);
  background-color: #c13740;
  display: block;
}

@-webkit-keyframes pulse_green {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(42, 169, 96, 0.6);
  }
  70% {
    -webkit-box-shadow: 0 0 0 15px rgba(42, 169, 96, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(42, 169, 96, 0);
  }
}
@keyframes pulse_green {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(42, 169, 96, 0.4);
    box-shadow: 0 0 0 0 rgba(42, 169, 96, 0.6);
  }
  70% {
    -moz-box-shadow: 0 0 0 15px rgba(42, 169, 96, 0);
    box-shadow: 0 0 0 15px rgba(42, 169, 96, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(42, 169, 96, 0);
    box-shadow: 0 0 0 0 rgba(42, 169, 96, 0);
  }
}

@-webkit-keyframes pulse_red {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(169, 19, 23, 0.6);
  }
  70% {
    -webkit-box-shadow: 0 0 0 15px rgba(169, 19, 23, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(169, 19, 23, 0);
  }
}
@keyframes pulse_red {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(169, 19, 23, 0.4);
    box-shadow: 0 0 0 0 rgba(169, 19, 23, 0.6);
  }
  70% {
    -moz-box-shadow: 0 0 0 15px rgba(169, 19, 23, 0);
    box-shadow: 0 0 0 15px rgba(169, 19, 23, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(169, 19, 23, 0);
    box-shadow: 0 0 0 0 rgba(169, 19, 23, 0);
  }
}
</style>
