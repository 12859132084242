<template>
  <v-row no-gutters>
    <v-form class="col-12" ref="single-form" v-model="valid">
      <v-row no-gutters>
        <v-col
            class="pa-2"
            :class="
            $vuetify.breakpoint.name === 'sm' ||
            $vuetify.breakpoint.name === 'xs'
              ? 'col-12'
              : `col-${12 / fieldsData['ColumnCount']}`
          "
            v-for="(fields, fieldIndex) in fieldsData['Fields']"
            :key="fieldIndex"
        >
          <template v-for="field in fields">
            <v-text-field
                v-if="field['ElementType'] === 'text'"
                :key="field['Field']"
                :label="field['Label']"
                :rules="setRule(field, fieldValues[field['Field']])"
                v-model="fieldValues[field['Field']]"
            />
            <v-text-field
                v-if="field['ElementType'] === 'password'"
                :key="field['Field']"
                :label="field['Label']"
                :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show_password ? 'text' : 'password'"
                @click:append="show_password = !show_password"
                :rules="setRule(field, fieldValues[field['Field']])"
                v-model="fieldValues[field['Field']]"
            />
            <v-autocomplete
                v-if="
                field['ElementType'] === 'combo'
              "
                :label="field['Label']"
                :key="field['Field']"
                :items="field['ComboItems']['Elements']"
                item-value="Id"
                item-text="Text"
                :rules="setRule(field, fieldValues[field['Field']])"
                v-model="fieldValues[field['Field']]"
            />

            <v-textarea
                v-if="field['ElementType'] === 'textarea'"
                :label="field['Label']"
                :key="field['Field']"
                :rules="setRule(field, fieldValues[field['Field']])"
                v-model="fieldValues[field['Field']]"
            />

            <interactive-list-component
                :field="field"
                :model="fieldValues"
                :interactive-target-value="
                fieldValues[field['CustomData']['filterField']]
              "
                :key="field['Field']"
                v-if="field['ElementType'] === 'interactiveList'"
            />
<!--            <v-autocomplete
                v-if="
                field['ElementType'] === 'combo' &&
                  field['CustomData'].hasOwnProperty('check_scope')
              "
                :label="field['Label']"
                :key="field['Field']"
                :items="field['ComboItems']['Elements']"
                item-value="Id"
                item-text="Text"
                :rules="setRule(field, fieldValues[field['Field']])"
                readonly
                :value="stickyData !== null && Number(stickyData[field['Field']])"
            />-->

            <label-edit-text-component
                :key="field['Field']"
                v-if="field['ElementType'] === 'labelEditText'"
                :field="field"
                :data-label-model="dataLabels"
                :data-value-model="fieldValues"
            />

            <!-- Text Editor -->
            <text-editor-component
                :key="field['Field']"
                v-if="field['ElementType'] === 'editor'"
                :dynamic-m="fieldValues"
                :field="field"
            />
            <!-- Text Editor -->

            <file-upload-comp
                :key="field['Field']"
                v-if="field['ElementType'] === 'fileSelectFromServer'"
                :field-item="field"
                :dynamic-m="fieldValues"
                v-model="fieldValues[field['Field']]"
            ></file-upload-comp>

            <modem-field-selector
                :key="field['Field']"
                v-if="field['ElementType'] === 'modemFieldSelector'"
                action-type="yeni"
                :field-item="field"
                :dynamic-m="fieldValues"
                v-model="fieldValues[field['Field']]"
            />

            <p-id-field-selector-component
                :key="field['Field']"
                v-if="field['ElementType'] === 'pidFieldSelector'"
                :field-item="field"
                :dynamic-m="fieldValues"
            />

            <watch-field-selector-component
                :key="field['Field']"
                v-if="field['ElementType'] === 'watchFieldSelector'"
                :field-item="field"
                :dynamic-m="fieldValues"
            />

            <alarm-wizard
                ref="alarmWiz"
                :key="field['Field']"
                v-if="field['ElementType'] === 'alarmwizard'"
                :config-object="fieldValues"
                :field-name="field['Field']"
            />

            <v-row
                no-gutters
                :key="field['Field']"
                v-if="field['ElementType'] === 'onlyView'"
            >
              <v-col class="col-12">
                <span>{{ field["Label"] }}</span>
              </v-col>
              <v-col class="col-12">
                <span>-----</span>
              </v-col>
            </v-row>

            <v-alert
                :key="field['Field']"
                v-if="
                field['ElementType'] === 'ModemSelectList' ||
                  field['ElementType'] === 'LinkedList' ||
                  field['ElementType'] === 'linkedTable' ||
                  field['ElementType'] === 'listConfigWizard' ||
                  field['ElementType'] === 'modemLayoutWizard' ||
                  field['ElementType'] === 'setting_config_wizard'
              "
                type="error"
            >
              {{ `${field["Label"]} ${textAlert}` }}
            </v-alert>
          </template>
        </v-col>
      </v-row>
    </v-form>
  </v-row>
</template>

<script>
import { setRuleData } from "@/form_stuff/set_form_rules";
import InteractiveListComponent from "@/components/InteractiveList/InteractiveListComponent";
import LabelEditTextComponent from "@/components/LabelEditText/LabelEditTextComponent";
import TextEditorComponent from "@/components/TextEditorComponent/TextEditorComponent";
import FileUploadComp from "@/components/FileSelector/FileUploadComp";
import ModemFieldSelector from "@/components/ModemFieldSelector/ModemFieldSelector";
import pIdFieldSelectorComponent from "@/components/pIdFieldSelector/pIdFieldSelectorComponent";
import watchFieldSelectorComponent from "@/components/watchFieldSelector/watchFieldSelectorComponent";
import AlarmWizard from "@/components/AlarmWizard/AlarmWizard";

export default {
  name: "SingleForm",
  components: {
    TextEditorComponent,
    InteractiveListComponent,
    LabelEditTextComponent,
    FileUploadComp,
    ModemFieldSelector,
    pIdFieldSelectorComponent,
    watchFieldSelectorComponent,
    AlarmWizard
  },
  data() {
    return {
      valid: false,
      show_password: false,
      fieldValues: {},
      textAlert: "alanına erişmek için önce formu kaydetmelisiniz.",
      dataLabels: {}
    };
  },
  props: {
    fieldsData: {
      type: Object,
      required: false
    },
    stickyData: {
      type: Object,
      required: false
    }
  },
  beforeDestroy() {},
  created() {},
  methods: {
    setRule: function(v, fV) {
      return setRuleData(v, fV);
    },
    /*
     * This functions is going to check if form is valid or not.
     * if form is valid then we add two objects that contains sticky values
     * and form values using spread operator. Lastly we are going to emit an
     * event with those values as parameters.
     * */
    validateForm: function() {
      console.log("Validate Form Called", this.$refs);
      if (this.$refs.hasOwnProperty("alarmWiz")) {
        return (
            this.$refs["single-form"].validate() &&
            this.$refs["alarmWiz"][0].checkWizardValidation()
        );
      }
      return this.$refs["single-form"].validate();
    },
    getFormValues: function() {
      //İnit alarm wizard fields data
      this.$refs.hasOwnProperty("alarmWiz") &&
      this.$refs["alarmWiz"][0].getWizardData();
      return {
        ...this.fieldValues,
        ...this.stickyData,
        ...(Object.keys(this.dataLabels).length > 0 && {
          data_labels: encodeURIComponent(
              JSON.stringify(this.dataLabels)
          ).toString()
        })
      };
    }
  }
};
</script>

<style scoped></style>
