<template>
  <div v-if="parentName !== 'ModemTabLayout'">
    <p>Jenerator Standart</p>
    <v-row no-gutters>
      <v-text-field
          label="Genişlik"
          v-model="currentItem['genislik']"
          @input="svgDimen"
      />
    </v-row>

    <v-row no-gutters v-for="item in statusFields" :key="item['field']">
      <span class="col-12">{{ item["label"] }}</span>
      <v-row no-gutters>
        <v-col class="col-6 px-1">
          <v-autocomplete
              :items="modbusModemInputsList"
              item-text="Text"
              item-value="Id"
              :label="`${item['label']} bağlı alan`"
              v-model="currentItem[item['field']]"
          />
        </v-col>
        <v-col class="col-6 px-1">
          <v-autocomplete
              :items="['Off-On', 'On-Off']"
              label="Animasyon Tipi"
              v-model="currentItem[`${item['field']}_animation`]"
          />
        </v-col>
      </v-row>
    </v-row>

    <v-row no-gutters v-for="item in buttonFields" :key="item['field']">
      <v-col class="col-12">
        <span>{{ item["label"] }}</span>
        <v-autocomplete
            :items="modbusModemButtonList"
            item-text="Text"
            item-value="Id"
            :label="`${item['label']} bağlı alan`"
            v-model="currentItem[item['field']]"
        />
      </v-col>
    </v-row>

    <v-row no-gutters v-for="item in screenFields" :key="item['field']">
      <v-col class="col-12">
        <span>{{ item["label"] }}</span>
        <v-autocomplete
            :items="[
            ...modbusModemInputsList,
            ...modbusModemFuelList,
            ...modbusModemCalismaDurumList,
            ...modbusModemAlarmList
          ]"
            item-text="Text"
            item-value="Id"
            :label="`${item['label']} bağlı alan`"
            v-model="currentItem[item['field']]"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  changeSvgDimension,
  getSVGChildElements
} from "@/components/ModemLayoutWizard/ModemLayoutSettings/svgComponentUtils/svgUtilFunctions";
import store from "@/vuex_store/store_wrapper";

export default {
  name: "jeneratorPanel",
  props: {
    currentItem: {
      type: Object,
      required: false
    },
    modemID: {
      type: [Array, Number],
      required: false
    },
    //from modem tab layout
    parentName: String,
    svgItemObject: {
      type: Object,
      required: false
    },
    listSetup: Object,
    dataProperty: Object
  },
  data() {
    return {
      statusFields: [
        {
          label: "Auto Ready",
          field: "auto_ready"
        },
        {
          label: "Alarm",
          field: "alarm"
        },
        {
          label: "Warning",
          field: "warning"
        },
        {
          label: "Service Request",
          field: "service_request"
        }
      ],
      buttonFields: [
        {
          field: "test",
          label: "Test Button"
        },
        {
          field: "run",
          label: "Man/Run Button"
        },
        {
          field: "auto",
          label: "Auto Button"
        },
        {
          field: "off",
          label: "Off Button"
        }
      ],
      screenFields: [
        {
          field: "genset_l1",
          label: "Genset L1",
          label_id: "#genset_l1_text",
        },
        {
          field: "genset_l2",
          label: "Genset L2",
          label_id: "#genset_l2_text",
        },
        {
          field: "genset_l3",
          label: "Genset L3",
          label_id: "#genset_l3_text",
        },
        {
          field: "sebeke_l1",
          label: "Şebeke Faz L1",
          label_id: "#sebeke_l1_text",
        },
        {
          field: "sebeke_l2",
          label: "Şebeke Faz L2",
          label_id: "#sebeke_l2_text",
        },
        {
          field: "sebeke_l3",
          label: "Şebeke Faz L3",
          label_id: "#sebeke_l3_text",
        },
        {
          field: "aku_voltaj",
          label: "Akü Voltaj",
          label_id: "#aku_voltaj_text",
        },
        {
          field: "calisma_saati",
          label: "Çalışma Saati",
          label_id: "#calisma_saat_text",
        },
        {
          field: "motor_hararet",
          label: "Motor Hararet",
          label_id: "#motor_hararet_text"
        },
        {
          field: "yag_basinc",
          label: "Yağ Basınç",
          label_id: "#yah_basinc_text"
        },
        {
          field: "yag_hararet",
          label: "Yağ Hararet",
          label_id: "#yag_hararet_text"
        },
        {
          field: "yakit_seviye",
          label: "Yakıt Seviye",
          label_id: "#yakit_seviye_text"
        }
      ],
      unsubscribe: null,
      ioListData: [],
      modbusModemButtonList: [],
      modbusModemCalismaDurumList: [],
      modbusModemAlarmList: [],
      modbusModemFuelList: [],
      modbusModemInputsList: [],
      //button stuff
      testButtonClicked: false,
      testButtonTimeout: null,
      runButtonClicked: false,
      runButtonTimeout: null,
      autoButtonClicked: false,
      autoButtonTimeout: null,
      offButtonClicked: false,
      offButtonTimeout: null
    };
  },
  computed: {
    ...mapGetters({
      getterSVGScaleInfo: "storeSVGScale/getterSVGScaleInfo"
    }),
    svgDimen: function() {
      return changeSvgDimension(this.currentItem);
    },
    getDataProperty: function() {
      return this.dataProperty;
    },
    jeneratorFuelAnimation: {
      get: function() {
        return this.jeneratorFuelHeightStyle;
      },
      set: function(height) {
        if (Number(height) > 100) {
          this.jeneratorFuelHeightStyle =
              `height:0px; transform: translateY(0px); transition:1s ease-in-out`;
          return;
        }
        const maxHeightOfFuel = 356;
        let fuelHeight = (maxHeightOfFuel * Number(height)) / 100;
        let translateValue = maxHeightOfFuel - fuelHeight;
        this.jeneratorFuelHeightStyle = `height:${fuelHeight}px; transform: translateY(${translateValue}px); transition:1s ease-in-out`;
      }
    },
    jeneratorStatusAnimations: function() {
      return {
        autoReady: {
          on: `fill:#2193F7; transition: .2s ease-in;`,
          off: `fill:#FFFFFF; transition: .2s ease-in;`
        },
        alarm: {
          on: `fill:#E04343; transition: .2s ease-in;`,
          off: `fill:#FFFFFF; transition: .2s ease-in;`
        },
        warning: {
          on: `fill:#E04343; transition: .2s ease-in;`,
          off: `fill:#FFFFFF; transition: .2s ease-in;`
        },
        serviceRequest: {
          on: `fill:#E04343; transition: .2s ease-in;`,
          off: `fill:#FFFFFF; transition: .2s ease-in;`
        }
      };
    }
  },

  beforeDestroy() {
    this.unsubscribe && this.unsubscribe();
    this.testButtonTimeout && clearTimeout(this.testButtonTimeout);
    this.runButtonTimeout && clearTimeout(this.runButtonTimeout);
    this.autoButtonTimeout && clearTimeout(this.autoButtonTimeout);
    this.offButtonTimeout && clearTimeout(this.offButtonTimeout);
  },
  created() {
    this.unsubscribe = store.subscribe(this.storeHandler);
    if (this.parentName !== "ModemTabLayout") {
      this.getModemIOList();
      this.getModbusInputs();
      this.getModbusFuel();
      this.getModbusAlarmList();
      this.getModbusButtons();
      this.getModbusCalisma();
    }
  },
  mounted() {
    //Called from modem tab layout component in Stations.
    if (this.parentName === "ModemTabLayout") {
      //add event listeners
      this.addButtonEventListeners();
      const { Datas } = this.getDataProperty;
      console.log("Datas In Jenerator Panel => ", Datas);
      console.log("Value SvgObject", this.svgItemObject);

      Object.keys(Datas).forEach(datasKey => {
        /*eslint-disable*/
        const [master_id, tip] = datasKey.split("_");
        //console.log("Splitted: ", master_id, tip, key)

        Object.keys(this.svgItemObject).forEach(key => {
          const value = this.svgItemObject[key];

          if (value === parseInt(tip)) {
            //console.log("Value Equals Tip", this.setFrontendSVG());
            /*console.log("Exists in svgItemObject Key", value)
            console.log("Exists in svgItemObject Value", Datas[datasKey])*/
            if (this.setFrontendSVG().hasOwnProperty(value)) {
              this.setFrontendSVG()[value](Datas[datasKey]);
            }
          }
        });
        if (tip === "63") {
          this.setFrontendSVG()[tip](Datas[datasKey]);
        }
      });
    }
  },
  methods: {
    addButtonEventListeners: function () {
      /* BUTTONS */
      const buttonTestGroup = getSVGChildElements(
          this.svgItemObject,
          "#test_button_click_group"
      );
      buttonTestGroup.addEventListener("click", this.buttonTestClick, false);

      const buttonManRunGroup = getSVGChildElements(
          this.svgItemObject,
          "#run_button_click_group"
      );
      buttonManRunGroup.addEventListener("click", this.buttonRunClick, false);

      const buttonAutoGroup = getSVGChildElements(
          this.svgItemObject,
          "#auto_button_click_group"
      );
      buttonAutoGroup.addEventListener("click", this.buttonAutoClick, false);

      const buttonOffGroup = getSVGChildElements(
          this.svgItemObject,
          "#off_button_click_group"
      );
      buttonOffGroup.addEventListener("click", this.buttonOffClick, false);
    },
    sendOutput: function (field) {
      let setModemOutputs = {
        type: "setModemOutput",
        payload: {
          ModemId: this.modemID,
          Target: `8_0_${field}`,
          Value: 1
        }
      };
      this.sendMessageToWS(setModemOutputs);
    },
    buttonTestClick: function () {
      if (!this.testButtonClicked) {
        this.testButtonClicked = true;
        const spinner = getSVGChildElements(
            this.svgItemObject,
            "#jenerator_button_test_spinner"
        );
        spinner.setAttribute("style", "opacity: 1; transition: .2s ease;");
        spinner.classList.add("spin");

        this.sendOutput(this.svgItemObject['test']);

        this.testButtonTimeout = setTimeout(() => {
          spinner.setAttribute("style", "opacity: 0; transition: .2s ease;");
          spinner.classList.remove("spin");
          this.testButtonClicked = false;
        },5000);
      } else {
        console.log("You cannot click right now !")
      }
    },
    buttonRunClick: function () {
      if (!this.runButtonClicked) {
        this.runButtonClicked = true;
        const spinner = getSVGChildElements(
            this.svgItemObject,
            "#jenerator_button_run_spinner"
        );
        spinner.setAttribute("style", "opacity: 1; transition: .2s ease;");
        spinner.classList.add("spin");

        this.sendOutput(this.svgItemObject['run']);

        this.runButtonTimeout = setTimeout(() => {
          spinner.setAttribute("style", "opacity: 0; transition: .2s ease;");
          spinner.classList.remove("spin");
          this.runButtonClicked = false;
        },5000);
      } else {
        console.log("You cannot click right now !")
      }
    },
    buttonAutoClick: function () {
      if (!this.autoButtonClicked) {
        this.autoButtonClicked = true;
        const spinner = getSVGChildElements(
            this.svgItemObject,
            "#jenerator_button_auto_spinner"
        );
        spinner.setAttribute("style", "opacity: 1; transition: .2s ease;");
        spinner.classList.add("spin");

        this.sendOutput(this.svgItemObject['auto']);

        this.autoButtonTimeout = setTimeout(() => {
          spinner.setAttribute("style", "opacity: 0; transition: .2s ease;");
          spinner.classList.remove("spin");
          this.autoButtonClicked = false;
        },5000);
      } else {
        console.log("You cannot click right now !")
      }
    },
    buttonOffClick: function () {
      if (!this.offButtonClicked) {
        this.offButtonClicked = true;
        const spinner = getSVGChildElements(
            this.svgItemObject,
            "#jenerator_button_off_spinner"
        );
        spinner.setAttribute("style", "opacity: 1; transition: .2s ease;");
        spinner.classList.add("spin");

        this.sendOutput(this.svgItemObject['off']);

        this.offButtonTimeout = setTimeout(() => {
          spinner.setAttribute("style", "opacity: 0; transition: .2s ease;");
          spinner.classList.remove("spin");
          this.offButtonClicked = false;
        },5000);
      } else {
        console.log("You cannot click right now !")
      }
    },
    storeHandler: function(mutation) {
      const { type, payload } = mutation;
      if (type === "storeSocket/on_socket_message") {
        const { data, command } = JSON.parse(payload.data);
        if (command === "ModemIOList") {
          this.ioListData = [];
          const { Elements } = data;
          this.ioListData = Object.entries(Elements).map(([key, value]) => {
            return {
              Id: key,
              Text: value
            };
          });
          console.log(this.ioListData);
        } else if (command === "ModbusComponentsFromFormatResponse") {
          const { Source, List } = data;
          const { Elements } = List;
          console.log("List", List);
          switch (Source) {
            case "ModbusModemInputs":
              Elements !== null
                  ? (this.modbusModemInputsList = Elements)
                  : (this.modbusModemInputsList = []);
              break;
            case "ModbusFuel":
              Elements !== null
                  ? (this.modbusModemFuelList = Elements)
                  : (this.modbusModemFuelList = []);
              console.log(
                  "FUEL LIST",
                  this.modbusModemFuelList,
                  this.ioListData
              );
              if (this.ioListData.length > 0) {
                this.modbusModemFuelList = [
                  ...this.modbusModemFuelList,
                  ...this.ioListData
                ];
              }
              break;
            case "ModbusAlarmList":
              Elements !== null
                  ? (this.modbusModemAlarmList = Elements)
                  : (this.modbusModemAlarmList = []);
              break;
            case "ModbusButtons":
              Elements !== null
                  ? (this.modbusModemButtonList = Elements)
                  : (this.modbusModemButtonList = []);
              break;
            case "ModbusCalisma":
              Elements !== null
                  ? (this.modbusModemCalismaDurumList = Elements)
                  : (this.modbusModemCalismaDurumList = []);
              break;
            default:
          }
        } else if (command === "ProcessData") {
          const { ModemId, Datas } = data;
          //console.log("DATA Pompa Etiket => ", data);
          if (ModemId === this.modemID) {
            this.parseProcessData(Datas);
          }
        }
      }
    },
    parseProcessData: function(Datas) {
      //console.log("Jenerator Datas Incoming...", Datas);
      for (const key in Datas) {
        let splitted = key.split("_");
        /*eslint-disable*/
        let [modbus, modbusFunctionID, unit] = splitted;
        let modbusFunctionData = Datas[key];
        //Burası neden ayrı. 63 this.svgItemObject içerisinde olmadığı için ayrı şekilde kontrol ediyoruz.
        if (modbusFunctionID === "63") {
          this.setFrontendSVG()[modbusFunctionID](modbusFunctionData);
        }
        Object.keys(this.svgItemObject).forEach(value => {
          if (Number(modbusFunctionID) === this.svgItemObject[value]) {
            if (this.setFrontendSVG().hasOwnProperty(modbusFunctionID)) {
              modbusFunctionID !== '' && this.setFrontendSVG()[modbusFunctionID](modbusFunctionData);
            }
          }
        });
      }
    },
    setFrontendSVG: function() {
      const {
        auto,
        test,
        run,
        off,
        alarm,
        alarm_list,
        auto_ready,
        durum,
        fuel,
        service_request,
        warning,
        calisma_durum,
        mod,
        genset_l1,
        genset_l2,
        genset_l3,
        sebeke_l1,
        sebeke_l2,
        sebeke_l3,
        aku_voltaj,
        calisma_saati,
        motor_hararet,
        yag_basinc,
        yag_hararet,
        yakit_seviye
      } = this.svgItemObject;

      return {
        //Status Window,
        [auto_ready]: Data => this.setAutoReady(Data),
        [alarm]: Data => this.setAlarm(Data),
        [warning]: Data => this.setWarning(Data),
        [service_request]: Data => this.setServiceRequest(Data),
        [fuel]: Data => console.log("Fuel Anim"),
        [mod]: Data => console.log("Mod Anim => Buton Indicator Anim !"),
        [genset_l1]: Data => this.setGenset(Data,1),
        [genset_l2]: Data => this.setGenset(Data,2),
        [genset_l3]: Data => this.setGenset(Data,3),
        [sebeke_l1]: Data => this.setSebeke(Data, 1),
        [sebeke_l2]: Data => this.setSebeke(Data, 2),
        [sebeke_l3]: Data => this.setSebeke(Data, 3),
        [aku_voltaj]: Data => this.setAku(Data),
        [calisma_saati]: Data => this.setCalismaSaati(Data),
        [motor_hararet]: Data => this.setMotorHararet(Data),
        [yag_basinc]: Data => this.setYagBasinc(Data),
        [yag_hararet]: Data => this.setYagHararet(Data),
        [yakit_seviye]: Data => this.setYakitSeviye(Data),
        ["63"]: Data => this.setCalismaDurum(Data)
      };
    },
    getModemIOList: function() {
      let requestIOList = {
        type: "getModemIOList",
        payload: {
          TargetID: this.currentItem["id"],
          ModemId: parseInt(this.modemID[0]),
          Secim: "1@3@4@8"
        }
      };
      this.sendMessageToWS(requestIOList);
    },
    getModbusInputs: function() {
      //get auto-ready, alarm, warning, service-request values
      let modbusdata = {
        type: "getModbusComponentFromFormat",
        payload: {
          Id: parseInt(this.modemID[0]),
          WriteType: 0,
          Target: "ModbusModemInputs",
          Formats: [1, 2, 4, 5, 6, 7]
        }
      };
      this.sendMessageToWS(modbusdata);
    },
    getModbusFuel: function() {
      let modbusdata = {
        type: "getModbusComponentFromFormat",
        payload: {
          Id: parseInt(this.modemID[0]),
          WriteType: 0,
          Target: "ModbusFuel",
          Formats: [1, 2, 3, 4, 5, 6, 7, 8]
        }
      };
      this.sendMessageToWS(modbusdata);
    },
    getModbusAlarmList: function() {
      let modbusdata = {
        type: "getModbusComponentFromFormat",
        payload: {
          Id: parseInt(this.modemID[0]),
          WriteType: 0,
          Target: "ModbusAlarmList",
          Formats: [9]
        }
      };
      this.sendMessageToWS(modbusdata);
    },
    getModbusButtons: function() {
      //Test, Run, Auto, Off outputs
      let modbusdata = {
        type: "getModbusComponentFromFormat",
        payload: {
          Id: parseInt(this.modemID[0]),
          WriteType: 2,
          Target: "ModbusButtons",
          Formats: [1, 2, 5]
        }
      };
      this.sendMessageToWS(modbusdata);
    },
    getModbusCalisma: function() {
      //Test, Run, Auto, Off outputs
      let modbusdata = {
        type: "getModbusComponentFromFormat",
        payload: {
          Id: parseInt(this.modemID[0]),
          WriteType: 0,
          Target: "ModbusCalisma",
          Formats: [10]
        }
      };
      this.sendMessageToWS(modbusdata);
    },
    setAutoReady: function(Data) {
      console.log("Auto Ready Data Received", Data);
      const { auto_ready_animation } = this.svgItemObject;
      const group = getSVGChildElements(
          this.svgItemObject,
          "#auto_ready_status_g"
      );
      if (Data === "1.000000") {
        group.setAttribute(
            "style",
            auto_ready_animation === "Off-On"
                ? this.jeneratorStatusAnimations["autoReady"]["on"]
                : this.jeneratorStatusAnimations["autoReady"]["off"]
        );
      } else {
        group.setAttribute(
            "style",
            auto_ready_animation === "Off-On"
                ? this.jeneratorStatusAnimations["autoReady"]["off"]
                : this.jeneratorStatusAnimations["autoReady"]["on"]
        );
      }
    },
    setAlarm: function(Data) {
      console.log("Alarm Data Received", Data);
      const { alarm_animation } = this.svgItemObject;
      const group = getSVGChildElements(this.svgItemObject, "#alarm_status_g");
      if (Data === "1.000000") {
        group.setAttribute(
            "style",
            alarm_animation === "Off-On"
                ? this.jeneratorStatusAnimations["alarm"]["on"]
                : this.jeneratorStatusAnimations["alarm"]["off"]
        );
      } else {
        group.setAttribute(
            "style",
            alarm_animation === "Off-On"
                ? this.jeneratorStatusAnimations["alarm"]["off"]
                : this.jeneratorStatusAnimations["alarm"]["on"]
        );
      }
    },
    setWarning: function(Data) {
      console.log("Warning Data Received", Data);
      const { warning_animation } = this.svgItemObject;
      const group = getSVGChildElements(
          this.svgItemObject,
          "#warning_status_g"
      );
      if (Data === "1.000000") {
        group.setAttribute(
            "style",
            warning_animation === "Off-On"
                ? this.jeneratorStatusAnimations["warning"]["on"]
                : this.jeneratorStatusAnimations["warning"]["off"]
        );
      } else {
        group.setAttribute(
            "style",
            warning_animation === "Off-On"
                ? this.jeneratorStatusAnimations["warning"]["off"]
                : this.jeneratorStatusAnimations["warning"]["on"]
        );
      }
    },
    setServiceRequest: function(Data) {
      console.log("Service Request Received", Data);
      const { service_request_animation } = this.svgItemObject;
      const group = getSVGChildElements(
          this.svgItemObject,
          "#service_request_status_g"
      );
      if (Data === "1.000000") {
        group.setAttribute(
            "style",
            service_request_animation === "Off-On"
                ? this.jeneratorStatusAnimations["serviceRequest"]["on"]
                : this.jeneratorStatusAnimations["serviceRequest"]["off"]
        );
      } else {
        group.setAttribute(
            "style",
            service_request_animation === "Off-On"
                ? this.jeneratorStatusAnimations["serviceRequest"]["off"]
                : this.jeneratorStatusAnimations["serviceRequest"]["on"]
        );
      }
    },
    setCalismaDurum: function(Data) {
      const indicatorAuto = getSVGChildElements(this.svgItemObject,"#jenerator_button_auto_indicator");
      const indicatorTest = getSVGChildElements(this.svgItemObject,"#jenerator_button_test_indicator");
      const indicatorRun = getSVGChildElements(this.svgItemObject,"#jenerator_button_run_indicator");
      const indicatorOff = getSVGChildElements(this.svgItemObject,"#jenerator_button_off_indicator");
      this.clearAllCircleAnimations(indicatorAuto,indicatorTest,indicatorRun,indicatorOff);
      if (typeof Data === "string") {
        Data = JSON.parse(Data);
      }
      const { tr, eng } = Data;
      console.log("Calışma Durum Data Not Parsed", Data);
      console.log("Calısma Durum", tr, eng);

      const functions = {
        AUTO: () => indicatorAuto.classList.add(
            "startBlink"
        ),
        TEST: () => indicatorTest.classList.add(
            "startBlink"
        ),
        "MANUAL(D700)/RUN(D500)": () => indicatorRun.classList.add(
            "startBlink"
        ),
        STOP: () => {
          indicatorOff.classList.add(
              "startBlink"
          )
          indicatorAuto.classList.remove(
              "startBlink"
          )
          indicatorTest.classList.remove(
              "startBlink"
          )
          indicatorRun.classList.remove(
              "startBlink"
          )
        },
        "...": () => {
          indicatorOff.classList.add(
              "startBlink"
          )
          indicatorAuto.classList.remove(
              "startBlink"
          )
          indicatorTest.classList.remove(
              "startBlink"
          )
          indicatorRun.classList.remove(
              "startBlink"
          )
        }
      };

      functions[tr]();
    },
    setGenset: function(Data,Type) {
      const {
        birimler: { genset_l1, genset_l2, genset_l3 }
      } = this.svgItemObject;

      console.log("Genset Data Received", Data,Type)

      if (Type === 1) {
        const genset_l1_text = getSVGChildElements(
            this.svgItemObject,
            "#genset_l1_deger"
        );
        const genset_l1_value = genset_l1_text.getElementsByTagName("tspan")[0];
        genset_l1_value.innerHTML = `${Number(Data).toFixed(1)} ${genset_l1}`;
      } else if (Type === 2) {
        const genset_l2_text = getSVGChildElements(
            this.svgItemObject,
            "#genset_l2_deger"
        );
        const genset_l2_value = genset_l2_text.getElementsByTagName("tspan")[0];
        genset_l2_value.innerHTML = `${Number(Data).toFixed(1)} ${genset_l2}`;
      } else if (Type === 3) {
        const genset_l3_text = getSVGChildElements(
            this.svgItemObject,
            "#genset_l3_deger"
        );
        const genset_l3_value = genset_l3_text.getElementsByTagName("tspan")[0];
        genset_l3_value.innerHTML = `${Number(Data).toFixed(1)} ${genset_l3}`;
      }
    },
    setSebeke: function (Data, Type) {
      const {
        birimler: { sebeke_l1, sebeke_l2, sebeke_l3 }
      } = this.svgItemObject;
      if (Type === 1) {
        const sebeke_l1_text = getSVGChildElements(
            this.svgItemObject,
            "#sebeke_l1_deger"
        );
        const sebeke_l1_value = sebeke_l1_text.getElementsByTagName("tspan")[0];
        sebeke_l1_value.innerHTML = `${Number(Data).toFixed(1)} ${sebeke_l1}`;
      } else if (Type === 2) {
        const sebeke_l2_text = getSVGChildElements(
            this.svgItemObject,
            "#sebeke_l2_deger"
        );
        const sebeke_l2_value = sebeke_l2_text.getElementsByTagName("tspan")[0];
        sebeke_l2_value.innerHTML = `${Number(Data).toFixed(1)} ${sebeke_l2}`;
      } else if (Type === 3) {
        const sebeke_l3_text = getSVGChildElements(
            this.svgItemObject,
            "#sebeke_l3_deger"
        );
        const sebeke_l3_value = sebeke_l3_text.getElementsByTagName("tspan")[0];
        sebeke_l3_value.innerHTML = `${Number(Data).toFixed(1)} ${sebeke_l3}`;
      }
    },
    setAku: function (Data) {
      const {
        birimler: { aku_voltaj }
      } = this.svgItemObject;
      const text = getSVGChildElements(
          this.svgItemObject,
          "#aku_voltaj_deger"
      );
      const value = text.getElementsByTagName("tspan")[0];
      value.innerHTML = `${Number(Data).toFixed(1)} ${aku_voltaj}`;
    },
    setCalismaSaati: function (Data) {
      const {
        birimler: { calisma_saati }
      } = this.svgItemObject;
      const text = getSVGChildElements(
          this.svgItemObject,
          "#calisma_saat_deger"
      );
      const value = text.getElementsByTagName("tspan")[0];
      value.innerHTML = `${Number(Data).toFixed(1)} ${calisma_saati}`;
    },
    setMotorHararet: function (Data) {
      const {
        birimler: { motor_hararet }
      } = this.svgItemObject;
      const text = getSVGChildElements(
          this.svgItemObject,
          "#motor_hararet_value"
      );
      const value = text.getElementsByTagName("tspan")[0];
      value.innerHTML = `${Number(Data).toFixed(1)} ${motor_hararet}`;
    },
    setYagBasinc: function (Data) {
      const {
        birimler: { yag_basinc }
      } = this.svgItemObject;
      const text = getSVGChildElements(
          this.svgItemObject,
          "#yag_basinc_deger"
      );
      const value = text.getElementsByTagName("tspan")[0];
      value.innerHTML = `${Number(Data).toFixed(1)} ${yag_basinc}`;
    },
    setYagHararet: function (Data) {
      const {
        birimler: { yag_hararet }
      } = this.svgItemObject;
      const text = getSVGChildElements(
          this.svgItemObject,
          "#yag_hararet_deger"
      );
      const value = text.getElementsByTagName("tspan")[0];
      value.innerHTML = `${Number(Data).toFixed(1)} ${yag_hararet}`;
    },
    setYakitSeviye: function (Data) {
      const {
        birimler: { yakit_seviye }
      } = this.svgItemObject;
      const text = getSVGChildElements(
          this.svgItemObject,
          "#yakit_seviye_deger"
      );
      const value = text.getElementsByTagName("tspan")[0];
      value.innerHTML =Number(Data) > 100 ? `------ ${yakit_seviye}` : `${Number(Data).toFixed(1)} ${yakit_seviye}`;

      const rect = getSVGChildElements(
          this.svgItemObject,
          "#jenerator_fuel_bar_indicator"
      );

      this.jeneratorFuelAnimation = Number(Data).toFixed(1);
      rect.setAttribute("style", this.jeneratorFuelAnimation);
      rect.setAttribute("y", "100");
    },
    clearAllCircleAnimations: function(auto,test,run,off) {
      console.log("Clearing animation classes");
      auto.classList.remove(
          "startBlink"
      );
      test.classList.remove(
          "startBlink"
      );
      run.classList.remove(
          "startBlink"
      );
      off.classList.remove(
          "startBlink"
      );
    }
  }
};
</script>

<style scoped></style>
