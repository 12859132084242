var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.parentName !== 'ModemTabLayout')?_c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-text-field',{attrs:{"label":"Genişlik"},on:{"input":_vm.svgDimen},model:{value:(_vm.currentItem['genislik']),callback:function ($$v) {_vm.$set(_vm.currentItem, 'genislik', $$v)},expression:"currentItem['genislik']"}})],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-autocomplete',{attrs:{"label":"Bağlı Alan","items":_vm.bagliAlanItems,"item-text":"Text","item-value":"Id","clearable":""},model:{value:(_vm.currentItem['bagli_alan']),callback:function ($$v) {_vm.$set(_vm.currentItem, 'bagli_alan', $$v)},expression:"currentItem['bagli_alan']"}})],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"col-4"},[_c('v-text-field',{attrs:{"label":"Etiket Yazı"},on:{"input":_vm.changeLabel},model:{value:(_vm.currentItem['etiketText']),callback:function ($$v) {_vm.$set(_vm.currentItem, 'etiketText', $$v)},expression:"currentItem['etiketText']"}})],1),_c('v-col',{staticClass:"col-4 px-2"},[_c('v-text-field',{attrs:{"label":"Etiket Yazı Boyutu"},on:{"input":_vm.changeLabelFontSize},model:{value:(_vm.currentItem['etiketFontSize']),callback:function ($$v) {_vm.$set(_vm.currentItem, 'etiketFontSize', $$v)},expression:"currentItem['etiketFontSize']"}})],1),_c('v-col',{staticClass:"col-4"},[_c('v-menu',{ref:"cpRef",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-x":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Etiket Renk","prepend-icon":"event","readonly":"","return-object":""},model:{value:(_vm.currentItem['etiketColor']),callback:function ($$v) {_vm.$set(_vm.currentItem, 'etiketColor', $$v)},expression:"currentItem['etiketColor']"}},on),[_c('v-icon',{attrs:{"slot":"prepend","color":("#" + (_vm.currentItem['etiketColor']))},slot:"prepend"},[_vm._v(" mdi-invert-colors ")])],1)]}}],null,false,1158548625),model:{value:(_vm.colorMenuEtiketRenk),callback:function ($$v) {_vm.colorMenuEtiketRenk=$$v},expression:"colorMenuEtiketRenk"}},[_c('v-color-picker',{on:{"input":_vm.changeEtiketColor},model:{value:(_vm.currentItem['etiketColor']),callback:function ($$v) {_vm.$set(_vm.currentItem, 'etiketColor', $$v)},expression:"currentItem['etiketColor']"}})],1)],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"col-4"},[_c('v-menu',{ref:"cpRef",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-x":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Arkaplan Renk","prepend-icon":"event","readonly":"","return-object":""},model:{value:(_vm.currentItem['baseArkaplan']),callback:function ($$v) {_vm.$set(_vm.currentItem, 'baseArkaplan', $$v)},expression:"currentItem['baseArkaplan']"}},on),[_c('v-icon',{attrs:{"slot":"prepend","color":("#" + (_vm.currentItem['baseArkaplan']))},slot:"prepend"},[_vm._v(" mdi-invert-colors ")])],1)]}}],null,false,3314182457),model:{value:(_vm.colorMenuBaseRenk),callback:function ($$v) {_vm.colorMenuBaseRenk=$$v},expression:"colorMenuBaseRenk"}},[_c('v-color-picker',{on:{"input":_vm.changeArkaplanColor},model:{value:(_vm.currentItem['baseArkaplan']),callback:function ($$v) {_vm.$set(_vm.currentItem, 'baseArkaplan', $$v)},expression:"currentItem['baseArkaplan']"}})],1)],1),_c('v-col',{staticClass:"col-4"},[_c('v-menu',{ref:"cpRef",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-x":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Arkaplan Çerçeve Renk","prepend-icon":"event","readonly":"","return-object":""},model:{value:(_vm.currentItem['baseCerceve']),callback:function ($$v) {_vm.$set(_vm.currentItem, 'baseCerceve', $$v)},expression:"currentItem['baseCerceve']"}},on),[_c('v-icon',{attrs:{"slot":"prepend","color":("#" + (_vm.currentItem['baseCerceve']))},slot:"prepend"},[_vm._v(" mdi-invert-colors ")])],1)]}}],null,false,1986517592),model:{value:(_vm.colorMenuBaseStrokeRenk),callback:function ($$v) {_vm.colorMenuBaseStrokeRenk=$$v},expression:"colorMenuBaseStrokeRenk"}},[_c('v-color-picker',{on:{"input":_vm.changeArkaplanStrokeColor},model:{value:(_vm.currentItem['baseCerceve']),callback:function ($$v) {_vm.$set(_vm.currentItem, 'baseCerceve', $$v)},expression:"currentItem['baseCerceve']"}})],1)],1),_c('v-col',{staticClass:"col-4"},[_c('v-menu',{ref:"cpRef",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-x":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Değer Renk","prepend-icon":"event","readonly":"","return-object":""},model:{value:(_vm.currentItem['degerTextColor']),callback:function ($$v) {_vm.$set(_vm.currentItem, 'degerTextColor', $$v)},expression:"currentItem['degerTextColor']"}},on),[_c('v-icon',{attrs:{"slot":"prepend","color":("#" + (_vm.currentItem['degerTextColor']))},slot:"prepend"},[_vm._v(" mdi-invert-colors ")])],1)]}}],null,false,2330597850),model:{value:(_vm.colorMenuValueRenk),callback:function ($$v) {_vm.colorMenuValueRenk=$$v},expression:"colorMenuValueRenk"}},[_c('v-color-picker',{on:{"input":_vm.changeValueColor},model:{value:(_vm.currentItem['degerTextColor']),callback:function ($$v) {_vm.$set(_vm.currentItem, 'degerTextColor', $$v)},expression:"currentItem['degerTextColor']"}})],1)],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"col-4"},[_c('v-text-field',{attrs:{"label":"Birim"},on:{"input":_vm.changeBirimText},model:{value:(_vm.currentItem['birimText']),callback:function ($$v) {_vm.$set(_vm.currentItem, 'birimText', $$v)},expression:"currentItem['birimText']"}})],1),_c('v-col',{staticClass:"col-4 px-2"},[_c('v-text-field',{attrs:{"label":"Birim Yazı Boyutu"},on:{"input":_vm.changeBirimFontSize},model:{value:(_vm.currentItem['birimFontSize']),callback:function ($$v) {_vm.$set(_vm.currentItem, 'birimFontSize', $$v)},expression:"currentItem['birimFontSize']"}})],1),_c('v-col',{staticClass:"col-4"},[_c('v-menu',{ref:"cpRef",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-x":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Birim Renk","prepend-icon":"event","readonly":"","return-object":""},model:{value:(_vm.currentItem['birimTextColor']),callback:function ($$v) {_vm.$set(_vm.currentItem, 'birimTextColor', $$v)},expression:"currentItem['birimTextColor']"}},on),[_c('v-icon',{attrs:{"slot":"prepend","color":("#" + (_vm.currentItem['birimTextColor']))},slot:"prepend"},[_vm._v(" mdi-invert-colors ")])],1)]}}],null,false,2514740398),model:{value:(_vm.colorMenuBirimRenk),callback:function ($$v) {_vm.colorMenuBirimRenk=$$v},expression:"colorMenuBirimRenk"}},[_c('v-color-picker',{on:{"input":_vm.changeBirimColor},model:{value:(_vm.currentItem['birimTextColor']),callback:function ($$v) {_vm.$set(_vm.currentItem, 'birimTextColor', $$v)},expression:"currentItem['birimTextColor']"}})],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }