/*const floatRegex = /^\d+\.?\d*$/;
const numericRegex = /^[0-9]+$/;
const phoneRegex = /^(?:[0-9]{10,11},)*[0-9]{10,11}$/;
const mailRegex = /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/;*/
const mailRegex = /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/;
const ipRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
export const setRuleData = (item, value) => {
  const { Field, Required, Validations, ElementType, Label, Mask } = item;

  if (Required) {
    return requiredTrue(value, Field, Validations, ElementType, Label, Mask);
  } else {
    return requiredFalse(value, Field, Validations, ElementType, Label);
  }
};

const requiredTrue = (value, Field, Validations, ElementType, Label, Mask) => {
  if (Validations.length === 0) {
    return [v => !!v || `${Label} alanı boş bırakılamaz`];
  }

  const { ValidationType, Param1 } = Validations[0];
  if (ValidationType === "length") {
    if (ElementType === "text") {
      if (
        Mask === "phone" ||
        Field === "timeout" ||
        Field === "kp" ||
        Field === "ki" ||
        Field === "kd" ||
        Field === "inputMin" ||
        Field === "inputMax" ||
        Field === "vprecision" ||
        Field === "bias"
      ) {
        return [
          v => !!v || `${Label} alanı boş bırakılamaz`,
          /* Type: text && Mask phone ise aşağıdaki gibi */
          v =>
            !isNaN(Number(v)) ||
            `${Label} alanına nümerik karakter girmelisiniz`,
          v =>
            v && v.length !== 0
              ? v.length >= Number(Param1) ||
                `${Label} alanı en az ${Number(Param1)} karakter olmalıdır`
              : ""
        ];
      }
      return [
        v => !!v || `${Label} alanı boş bırakılamaz`,
        v =>
          v && v.length !== 0
            ? v.length >= Number(Param1) ||
              `${Label} alanı en az ${Number(Param1)} karakter olmalıdır`
            : ""
      ];
    } else if (ElementType === "combo") {
      if (typeof value === "undefined") {
        return [
          v => !!v || `${Label} alanı için en az bir seçim yapmalısınız.`
        ];
      }
      //return [v => !!v || `${Label} alanı için en az bir seçim yapmalısınız.`];
    }
  } else if (ValidationType === "email") {
    return [
      v => !!v || `${Label} alanı boş bırakılamaz`,
      v =>
        (v || "").length >= 1
          ? mailRegex.test(v) !== false || "Geçerli bir E Posta adresi giriniz."
          : (v || "").length >= 1
    ];
  } else if (ValidationType === "ipadress") {
    return [
      v => !!v || `${Label} alanı boş bırakılamaz`,
      v => ipRegex.test(v) !== false || `Geçerli bir ${Label} giriniz.`
    ];
  } else if (ValidationType === "numeric") {
    return [
      v => !!v || `${Label} alanı boş bırakılamaz`,
      /* Type: text && Mask phone ise aşağıdaki gibi */
      v => !isNaN(Number(v)) || `${Label} alanına nümerik karakter girmelisiniz`
    ];
  } else if (ValidationType === "number") {
    if (Param1 === "float" && Field === "enlem") {
      return [
        v => !!v || `${Label} alanı boş bırakılamaz`,
        v =>
          !isNaN(Number(v)) || `${Label} alanına nümerik karakter girmelisiniz`,
        v => (v > -90.0 && v < 90.0) || `Geçerli bir ${Label} değeri giriniz.`
      ];
    } else if (Param1 === "float" && Field === "boylam") {
      return [
        v => !!v || `${Label} alanı boş bırakılamaz`,
        v =>
          !isNaN(Number(v)) || `${Label} alanına nümerik karakter girmelisiniz`,
        v => (v > -180.0 && v < 180.0) || `Geçerli bir ${Label} değeri giriniz.`
      ];
    }
  }
};

const requiredFalse = (value, Field, Validations, ElementType, Label) => {
  /* If required is false and there is no validation
   * then return empty rules array => no check */
  if (Validations.length === 0) {
    return [];
  }

  if (value === "undefined" || !value) {
    return [];
  }

  const { ValidationType, Param1 } = Validations[0];

  if (ValidationType === "length") {
    if (ElementType === "text") {
      if (Field === "vprecision" || Field === "gsm_number") {
        return [
          v =>
            !isNaN(Number(v)) ||
            `${Label} alanına nümerik karakter girmelisiniz`,
          v =>
            (v || "").length >= Number(Param1) ||
            `${Label} en az ${Number(Param1)} karakter olmalıdır`
        ];
      }
      return [
        v =>
          (v || "").length >= Number(Param1) ||
          `${Label} alanı en az ${Number(Param1)} karakter olmalıdır`
      ];
    } else if (ElementType === "password") {
      return [
        //v => !!v || `${Label} alanı boş bırakılamaz`,
        v =>
          (v || "").length >= Number(Param1) ||
          `${Label} alanı ${Number(Param1)} karakterden büyük olmalıdır`
      ];
    } else if (ElementType === "combo") {
      if (typeof value === "undefined") {
        return [
          v => !!v || `${Label} alanı için en az bir seçim yapmalısınız.`
        ];
      }
    }
  }
};

/*export const setFormRules = item => {
  let ruleIndividual = [];
  if (item.Validations.length === 1) {
    const { ValidationType, Param1 } = item.Validations[0];

    let floatRegex = /^\d+\.?\d*$/;
    let numericRegex = /^[0-9]+$/;

    let phoneRegex = /^(?:[0-9]{10,11},)*[0-9]{10,11}$/;
    let mailRegex = /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/;

    if (ValidationType === "length") {
      //uzunluk validation
      if (parseInt(Param1) > 1) {
        if (
          item.Field === "telefon" ||
          item.Field === "gsm_number" ||
          item.Field === "cep_tel"
        ) {
          if (item.Required === true) {
            ruleIndividual = [
              v => !!v || item.Label + " alanı boş bırakılamaz.",
              v =>
                (v || "").length >= 1
                  ? phoneRegex.test(v) !== false ||
                    "Geçerli bir telefon numarası giriniz..."
                  : (v || "").length > 1
            ];
          } else if (item.Required === false) {
            ruleIndividual = [
              v =>
                (v || "").length >= 1
                  ? phoneRegex.test(v) !== false ||
                    "Geçerli bir telefon numarası giriniz.."
                  : (v || "").length > 1
            ];
          }
        } else {
          if (item.Required === true) {
            ruleIndividual = [
              v => !!v || item.Label + " alanı boş bırakılamaz.",
              v =>
                v && v.length !== 0
                  ? v.length >= Param1 ||
                    item.Label +
                      " " +
                      (Param1 - 1) +
                      " karakterden büyük olmalıdır."
                  : ""
            ];
          } else if (item.Required === false) {
            ruleIndividual = [
              v =>
                (v || "").length >= 1
                  ? v.length >= Param1 ||
                    item.Label +
                      " " +
                      (Param1 - 1) +
                      " karakterden büyük olmalıdır."
                  : (v || "").length > 1
            ];
          }
        }
      } else if (parseInt(Param1) === 1) {
        if (item.ElementType === "text" && item.Required === true) {
          ruleIndividual = [v => !!v || item.Label + " alanı boş bırakılamaz."];
        } else if (item.ElementType === "combo" && item.Required === true) {
          ruleIndividual = [
            v => v > -1 || item.Label + " alanı boş bırakılamaz."
          ];
        }
      }
      //RTU ve modemler ime required true length param1=""
      //Modbus cihaz tanımı linked list data formatı
      else {
        if (
          (item.ElementType === "text" || item.ElementType === "combo") &&
          item.Required === true
        ) {
          ruleIndividual = [v => !!v || item.Label + " alanı boş bırakılamaz."];
        }
      }
    } else if (ValidationType === "number") {
      if (Param1 === "float") {
        if (item.Required === true) {
          ruleIndividual = [
            v => !!v || item.Label + " alanı boş bırakılamaz.",
            v => floatRegex.test(v) !== false || "Geçerli bir sayı giriniz"
          ];
        } else if (item.Required === false) {
          ruleIndividual = [
            v => floatRegex.test(v) !== false || "Geçerli bir sayı giriniz"
          ];
        }
      } else if (Param1 === "decimal") {
        if (item.Required === true) {
          ruleIndividual = [
            v => !!v || item.Label + " alanı boş bırakılamaz.",
            v =>
              numericRegex.test(v) !== false ||
              "Sadece sayısal değerler girilebilir"
          ];
        } else if (item.Required === false) {
          ruleIndividual = [
            v =>
              numericRegex.test(v) !== false ||
              "Sadece sayısal değerler girilebilir.."
          ];
        }
      }
    } else if (ValidationType === "email") {
      if (Param1 > 0) {
        ruleIndividual = [
          v =>
            v && v.length !== 0
              ? v.length >= Param1 ||
                item.Label +
                  " " +
                  (Param1 - 1) +
                  " karakterden büyük olmalıdır."
              : ""
        ];
      } else {
        if (item.Field === "alarm_kodu") {
          ruleIndividual = [];
        } else {
          if (item.Required === true) {
            ruleIndividual = [
              v => !!v || item.Label + " alanı boş bırakılamaz.",

              v =>
                (v || "").length >= 1
                  ? mailRegex.test(v) !== false ||
                    "Geçerli bir E Posta adresi giriniz."
                  : (v || "").length >= 1
            ];
          } else if (item.Required === false) {
            ruleIndividual = [
              v =>
                (v || "").length >= 1
                  ? mailRegex.test(v) !== false ||
                    "Geçerli bir E Posta adresi giriniz."
                  : (v || "").length >= 1
            ];
          }
        }
      }
    } else if (ValidationType === "") {
      //
      if (item.Field === "gsm_number") {
        if (item.Required === true) {
          ruleIndividual = [
            v => !!v || item.Label + " alanı boş bırakılamaz.",
            v =>
              (v || "").length >= 1
                ? phoneRegex.test(v) !== false ||
                  "Geçerli bir telefon numarası giriniz"
                : (v || "").length > 1
          ];
        } else if (item.Required === false) {
          ruleIndividual = [
            v =>
              (v || "").length >= 1
                ? phoneRegex.test(v) !== false ||
                  "Geçerli bir telefon numarası giriniz.."
                : (v || "").length > 1
          ];
        }
      }
    }
  }

  //Analog Kart tipi Linked List Basamak Sayısı
  //sanal değer tipleri vprecision
  else if (item.Validations.length === 2) {
    const ValidationType0 = item.Validations[0].ValidationType;
    const Param1 = item.Validations[0].Param1;
    const ValidationType1 = item.Validations[1].ValidationType;

    let numericRegex = /^[0-9]+$/;
    if (
      (ValidationType0 === "length" && ValidationType1 === "numeric") ||
      ValidationType1 === "number"
    ) {
      if (Param1 > 0) {
        if (item.Required === true) {
          ruleIndividual = [
            v => !!v || item.Label + " alanı boş bırakılamaz.",
            v =>
              numericRegex.test(v) !== false ||
              "Sadece sayısal değerler girilebilir.."
          ];
        } else if (item.Required === false) {
          ruleIndividual = [
            v =>
              numericRegex.test(v) !== false ||
              "Sadece sayısal değerler girilebilir.."
          ];
        }
      }
    }
  }
  return ruleIndividual;
};*/
