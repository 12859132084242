<template>
  <div>
    <div v-for="set in componentData['Datasets']" :key="set['Legend']">
      <line-chart v-if="set['ChartType'] === 'Line'" :chart-data="set" />
      <bar-chart v-if="set['ChartType'] === 'Bar'" :chart-data="set" />
    </div>
  </div>
</template>
<script>
import LineChart from "./ChartComponents/LineChart";
import BarChart from "./ChartComponents/BarChart";
export default {
  components: {
    "bar-chart": BarChart,
    "line-chart": LineChart
  },
  props: {
    componentData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },
  created() {
    console.log("Chart DATASET", this.listData);
  },
  mounted() {}
};
</script>

<style scoped></style>
