<template>
  <p>Pompa Uclu</p>
</template>

<script>

export default {
  name: "pompaUclu",
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {
  }
};
</script>

<style scoped></style>
