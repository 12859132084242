<template>
  <v-form class="mx-4" ref="olcumPanelForm" v-model="validForm">
    <v-row no-gutters>
      <v-autocomplete
        label="Bir İstasyon seçimi yapınız"
        :items="stationsArray"
        item-text="Text"
        item-value="Id"
        v-model="olcumModel['station']"
        :rules="requiredRuleMultiple"
        @change="selectStationEvent"
        :multiple="true"
      />
    </v-row>
    <v-row no-gutters>
      <v-combobox
        hide-selected
        small-chips
        deletable-chips
        label="Bir Değer seçim yapınız"
        :items="degerArray"
        item-text="Text"
        item-value="Id"
        v-model="olcumModel['deger']"
        :rules="requiredRuleMultiple"
        :multiple="true"
      />
    </v-row>
    <v-row no-gutters>
      <v-select
        :items="periodArray"
        item-text="Text"
        item-value="Id"
        v-model="olcumModel['Periyod']"
        label="Periyod seçiniz."
        :rules="requiredRules"
      />
    </v-row>
    <v-row no-gutters>
      <v-col class="col-12">
        <v-dialog
          width="290px"
          transition="scale-transition"
          :close-on-content-click="false"
          v-model="dialogModel['Baslangic']"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Başlangıç tarihini seçiniz"
              v-on="on"
              readonly
              prepend-icon="event"
              v-model="olcumModel['Baslangic']"
              :rules="requiredRules"
            />
          </template>
          <date-time
            @OzelRaporEvent="dateReceivedEvent"
            @closeDatePickerEvent="closeDatePickerEvent"
            :picker-object="{
              isTimeActive: true,
              DialogModel: false,
              Field: 'Baslangic',
              EventName: 'OzelRaporEvent'
            }"
          />
        </v-dialog>
      </v-col>
      <v-col class="col-12">
        <v-dialog
          width="290px"
          transition="scale-transition"
          :close-on-content-click="false"
          v-model="dialogModel['Bitis']"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Bitiş tarihini seçiniz"
              v-on="on"
              readonly
              prepend-icon="event"
              v-model="olcumModel['Bitis']"
              :rules="requiredRules"
            />
          </template>
          <date-time
            @OzelRaporEvent="dateReceivedEvent"
            @closeDatePickerEvent="closeDatePickerEvent"
            :picker-object="{
              isTimeActive: true,
              DialogModel: false,
              Field: 'Bitis',
              EventName: 'OzelRaporEvent'
            }"
          />
        </v-dialog>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <panel-buttons @panelEvent="panelButtonEvents" @clearForm="clearForm" />
    </v-row>
  </v-form>
</template>

<script>
import CustomDateTimePickerComponent from "../../CustomDateTimePicker/CustomDateTimePickerComponent";
import PanelButtonsOlcum from "@/components/ReportsBottomPanel/PanelButtons/PanelButtonsOlcum";

export default {
  name: "OlcumRaporlarPanel",
  components: {
    "date-time": CustomDateTimePickerComponent,
    "panel-buttons": PanelButtonsOlcum
  },
  props: {
    stationsArray: {
      type: Array,
      required: true
    },
    degerArray: {
      type: Array,
      required: false,
      default : () => {
        return []
      }
    }
  },
  data() {
    return {
      olcumModel: {},
      dialogModel: {},
      validForm: true,
      requiredRules: [v => !!v || "Boş bırakılamaz"],
      requiredRuleMultiple: [v => v.length > 0 || "Birden fazla seçim yapınız"],
      stationListArray: [],
      periodArray: [
        { Id: 1, Text: "15 Dakikalık" },
        { Id: 2, Text: "Saatlik" },
        { Id: 3, Text: "Günlük" },
        { Id: 4, Text: "Haftalık" },
        { Id: 5, Text: "Aylık" },
        { Id: 6, Text: "Yıllık" }
      ],
    };
  },
  methods: {
    panelButtonEvents: function(tip) {
      const isValid = this.$refs["olcumPanelForm"].validate();
      if (isValid) {
        console.log("Valid Olcum", tip);
        const keys = this.olcumModel["deger"].map(obj => obj.Id);
        const olcumRequest = {
          type: "getValueReport",
          payload: {
            Ids: this.olcumModel["station"],
            Keys: keys,
            Tip: tip,
            Period: this.olcumModel["Periyod"],
            Baslangic: this.olcumModel["Baslangic"],
            Bitis: this.olcumModel["Bitis"]
          }
        };
        console.log("Request", olcumRequest);
        this.sendMessageToWS(olcumRequest);
      }
    },
    selectStationEvent: function(Ids) {
      if (typeof Ids !== "undefined") {
        console.log("ID", Ids);
        console.log("Last one", Ids[Ids.length - 1]);
        let getReportFields = {
          type: "getReportFields",
          payload: { Id: Ids[Ids.length - 1] }
        };
        console.log("Sending Get Report Fields", getReportFields);
        this.sendMessageToWS(getReportFields);
      }
    },
    dateReceivedEvent: function(payload) {
      const { date, Field, DialogModel } = payload;
      this.olcumModel[Field] = date;
      this.dialogModel[Field] = DialogModel;
    },
    closeDatePickerEvent: function(payload) {
      this.dialogModel[payload] = false;
    },
    clearForm: function() {
      this.$refs["olcumPanelForm"].reset();
    }
  }
};
</script>

<style scoped></style>
