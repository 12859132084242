var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"justify-space-between align-center",attrs:{"no-gutters":""}},[_c('div',[_c('span',{staticClass:"white--text"},[_vm._v("Sayfa başına gösterim:")]),_c('v-select',{staticClass:"mt-2",attrs:{"label":"10","items":_vm.pageLimit,"hide-details":"","solo":"","dense":""},on:{"change":function($event){return _vm.$emit(_vm.eventNames[0], _vm.limit)}},model:{value:(_vm.limit),callback:function ($$v) {_vm.limit=$$v},expression:"limit"}})],1),_c('div',{staticClass:"align-self-center"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-tooltip',{attrs:{"color":"red darken-2","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.pageNumber === 1,"text":"","icon":"","color":"white"},on:{"click":function($event){return _vm.$emit(_vm.eventNames[1])}}},on),[_c('v-icon',[_vm._v("first_page")])],1)]}}])},[_c('span',{staticClass:"white--text"},[_vm._v("İlk Sayfa")])]),_c('v-tooltip',{attrs:{"color":"red darken-2","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.pageNumber === 1,"text":"","icon":"","color":"white"},on:{"click":function($event){return _vm.$emit(_vm.eventNames[2])}}},on),[_c('v-icon',[_vm._v("arrow_left")])],1)]}}])},[_c('span',{staticClass:"white--text"},[_vm._v("Geri")])]),_c('span',{staticClass:"white--text",staticStyle:{"line-height":"48px"}},[_vm._v(_vm._s(_vm.totalPage === 0 ? "0" : _vm.pageNumber)+" / "+_vm._s(_vm.totalPage === 0 ? "0" : _vm.totalPage))]),_c('v-tooltip',{attrs:{"color":"red darken-2","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.pageNumber === _vm.totalPage || _vm.totalPage === 0,"text":"","icon":"","color":"white"},on:{"click":function($event){return _vm.$emit(_vm.eventNames[3])}}},on),[_c('v-icon',[_vm._v("arrow_right")])],1)]}}])},[_c('span',{staticClass:"white--text"},[_vm._v("İleri")])]),_c('v-tooltip',{attrs:{"color":"red darken-2","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.pageNumber === _vm.totalPage || _vm.totalPage === 0,"text":"","icon":"","color":"white"},on:{"click":function($event){return _vm.$emit(_vm.eventNames[4])}}},on),[_c('v-icon',[_vm._v("last_page")])],1)]}}])},[_c('span',{staticClass:"white--text"},[_vm._v("Son Sayfa")])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }