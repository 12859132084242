<template>
  <div class="text-center">
    <v-menu
        :close-on-content-click="false"
        :close-on-click="true"
        :nudge-width="200"
        offset-y
        v-model="menu"
    >
      <template v-slot:activator="{ on: menu }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn v-on="{ ...tooltip, ...menu }" icon>
              <v-badge
                  :content="badgeCount"
                  :value="badgeCount"
                  :overlap="true"
                  color="green"
                  :left="true"
              >
                <template v-if="badgeCount > 0" v-slot:badge>
                  <span>{{ badgeCount }}</span>
                </template>
                <v-icon color="white">filter_list</v-icon>
              </v-badge>
            </v-btn>
          </template>
          <span>Filtrele / Süz</span>
        </v-tooltip>
      </template>

      <v-card>
        <v-list>
          <v-card-title class="ma-0 py-0">Filtrele</v-card-title>
          <v-divider></v-divider>
          <v-list-item v-for="item in filterData" :key="item['Field']">
            <v-text-field
                v-if="item['Type'] === 'text'"
                :label="item['Label']"
                v-model="filterModel[item['Field']]"
                clearable
            />
            <v-select
                v-if="item['Type'] === 'combo' && defaultFilter !== item['Field']"
                :label="item['Label']"
                :items="item['Conf']['ComboItems']['Elements']"
                item-text="Text"
                item-value="Id"
                :multiple="item['Conf']['MultiSelect']"
                v-model="filterModel[item['Field']]"
            />
            <v-select
                v-if="defaultFilter === item['Field']"
                :label="item['Label']"
                :items="item['Conf']['ComboItems']['Elements']"
                item-text="Text"
                item-value="Id"
                :multiple="item['Conf']['MultiSelect']"
                :value="
                findDefaultFilter(item['Conf']['ComboItems']['Elements'])[0][
                  'Id'
                ]
              "
                readonly
                disabled
            />
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="menu = false">Kapat</v-btn>
          <v-btn color="primary" text @click="filterRequest">Filtrele</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "FilterMenuComponent",
  props: {
    filterData: {
      type: Array,
      required: false
    },
    filterTarget: {
      type: String,
      required: false
    },
    filterEventName: {
      type: String,
      required: false,
      default: "filterEvent"
    },
    defaultFilter: {
      type: String,
      required: false,
      default: ""
    },
    idFilter: {
      type: String,
      required: false,
      default: ""
    },
    badgeCount: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      menu: false,
      filterModel: {},
      chipData: [],
      indicator: 0
    };
  },
  methods: {
    findDefaultFilter: function(Elements) {
      return Elements.filter(e => e.Id === Number(this.idFilter));
    },
    filterRequest: function() {
      const filters = Object.entries(this.filterModel).map(([key, value]) => {
        return {
          Field: key,
          Data: Array.isArray(value) ? value : [value]
        };
      });
      console.log("Filter Request", filters);
      this.$emit(this.filterEventName, filters);
    }
  }
};
</script>

<style scoped></style>
