<template>
  <v-app-bar dense :extended="false" class="indigo accent-3 elevation-0" dark>
    <v-icon class="mr-2">{{
      iconAndTitle.icon === "kullanici" ? "account_box" : iconAndTitle.icon
    }}</v-icon>
    <v-app-bar-title v-text="iconAndTitle.title" />
    <v-spacer></v-spacer>
    <v-row no-gutters class="justify-end">
      <v-btn
        v-for="item in getToolbarElements"
        :key="item['Task']"
        icon
        fab
        color="white"
        @click="$emit('toolbarEvent', item['Task'])"
        ><v-icon color="white">{{ getIcons(item.Class) }}</v-icon>
      </v-btn>
    </v-row>
  </v-app-bar>
</template>

<script>
export default {
  name: "FormToolbar",
  props: {
    formToolbar: {
      type: Object,
      required: true
    },
    iconAndTitle: {
      type: Object,
      required: true
    }
  },
  computed: {
    getToolbarElements: function() {
      return this.formToolbar["Elements"];
    }
  },
  methods: {
    getIcons: function(icon) {
      switch (icon) {
        case "back_button_class":
          return "backspace";
        case "edit_button_class":
          return "save";
        case "delete_button_class":
          return "delete";
        case "view_button_class":
          return "search";
        case "new_button_class":
          return "save";
        case "icon-copy":
          return "content_copy";
        case 'kaydet':
          return 'save';
      }
    }
  }
};
</script>

<style scoped>
</style>
