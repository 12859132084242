<template>
  <!-- Model Field Selector -->
  <v-row no-gutters v-if="getFieldItem.ElementType === 'modemFieldSelector'">
    <v-col>
      <v-autocomplete
          v-if="actionType === 'yeni'"
          :label="getFieldItem.Label"
          v-model="parseVal"
          :items="allModemsArray"
          item-text="modem_name"
          item-value="modem_id"
          @input="exampleModemSelected"
          :readonly="readOnly"
      />

      <v-autocomplete
          v-if="actionType === 'duzenle'"
          :label="getFieldItem.Label"
          :value="parseInt(dynamicM[getFieldItem.Field])"
          :items="allModemsArray"
          item-text="modem_name"
          item-value="modem_id"
          @input="exampleModemSelected"
          :readonly="readOnly"
      />

      <!-- X Y Z Q W -->
      <v-select
          :key="h_index"
          v-for="(hidden, h_index) in XYZWQ_items"
          :label="hidden.Label"
          v-model="dynamicM[hidden.Field]"
          :items="calculationItems"
          item-text="Text"
          item-value="Value"
          persistent-hint
          :hint="
          calculationItems.length > 0 ? '' : 'Örnek modem seçimi yapmalısınız.'
        "
          :readonly="readOnly"
      />
      <!-- X Y Z Q W -->
    </v-col>
  </v-row>
  <!-- Model Field Selector -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ModemFieldSelector",
  props: {
    readOnly: {
      required: false,
      type: Boolean,
      default: false
    },
    /*
     * Hangi action type geldiğini prop ile öğrenmek gerekiyor. (Yeni, duzenle vb.)
     * action type'a göre dinamik model ya doldurulacak ya da boş kalacak.
     * */
    actionType: String,
    /*
     * fieldItem prop, field ile ilgili objeyi içeriyor.
     * */
    fieldItem: {
      type: Object,
      required: true
    },
    /*
     * dynamic model prop yeni component için Array düzenle component için Object
     * */
    dynamicM: [Array, Object]
  },
  data() {
    return {
      /*
       * v-model || value bind array'i
       * */
      dynamicModel: [],
      /*
       * X Y Z Q W fieldlarını popüle etmek için gerekli array.
       * */
      XYZWQ_items: [
        { Field: "input1", Label: "x" },
        { Field: "input2", Label: "y" },
        { Field: "input3", Label: "z" },
        { Field: "input4", Label: "q" },
        { Field: "input5", Label: "w" }
      ],
      /*
       * Örnek modemler field items bu array ile popüle edilecek.
       * */
      allModemsArray: [],
      /*
       * X Y Z Q W field items bu array ile popüle edilecek.
       * */
      calculationItems: []
    };
  },
  computed: {
    parseVal: {
      get: function() {
        return parseInt(this.dynamicM["ornek_modem"]);
      },
      set: function(value) {
        this.dynamicM["ornek_modem"] = parseInt(value);
      }
    },
    d_M: {
      get: function() {
        return this.dynamicM;
      },
      set: function(value) {
        this.dynamicM = value;
      }
    },
    getActionType: function() {
      return this.actionType;
    },
    getFieldItem: function() {
      return this.fieldItem;
    },
    //soket mesaj kontrolü yapıması gerekecektir.
    ...mapGetters({
      getterSocketMessage: "storeSocket/getterSocketMessage"
    })
  },
  watch: {
    getterSocketMessage: function(value) {
      let command = JSON.parse(value.data).command;
      let data = JSON.parse(value.data).data;
      if (command === "ModemComboList") {
        this.allModemsArray = data;
        if (this.readOnly) {
          this.exampleModemSelected(this.parseVal);
        }
      } else if (command === "ModemFieldList") {
        this.setModemFieldListItems(data);
      }
    }
  },
  created() {
    this.sendMessageToWS({ type: "getFullModemList", payload: null });
    /*
     * Action type = yeni ise ; yeni bir form datası geliyor ancak bu form datasının fieldları boş olacak.
     * preloaded data gelmiyor. (hidden field geliyorsa durum değişir.)
     *
     * Action type = duzenle ise ; bir kayıt düzenleneceğinden data dolu gelecek. bunu v-model için kullanacağımız
     * değişkene tanıtmamız gerek.
     * */
    switch (this.getActionType) {
      case "yeni":
        break;

      case "duzenle":
        var getFullModemValueFields = {
          type: "getFullModemValueFields",
          payload: {
            TargetID: "calculation",
            ModemId: this.parseVal
          }
        };

        this.sendMessageToWS(getFullModemValueFields);
        break;
    }
  },
  methods: {
    setModemFieldListItems: function(data) {
      this.calculationItems = [];
      let { TargetID, Elements } = data;
      if (TargetID === "calculation") {
        this.calculationItems = Object.keys(Elements).map(key => {
          return {
            Text: Elements[key],
            Value: key
          };
        });
      }
    },
    exampleModemSelected: function(modemID) {
      console.log("Selected Value", modemID);
      let getFullModemValueFields = {
        type: "getFullModemValueFields",
        payload: {
          TargetID: "calculation",
          ModemId: modemID
        }
      };

      this.sendMessageToWS(getFullModemValueFields);
    }
  }
};
</script>

<style scoped></style>
