<template>
  <div v-if="parentName !== 'ModemTabLayout'">
    <v-row no-gutters>
      <v-text-field
        label="Genişlik"
        v-model="currentItem['genislik']"
        @input="svgDimen"
      />
    </v-row>

    <v-row no-gutters>
      <v-col class="col-12">
        <v-menu
          ref="cpRef"
          v-model="colorMenuPBSpinner"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-x
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Spinner Renk"
              prepend-icon="event"
              readonly
              v-on="on"
              return-object
              v-model="currentItem['spinner_color']"
            >
              <v-icon
                :color="`${currentItem['spinner_color']}`"
                slot="prepend"
                class="oneLabelIcon"
              >
                mdi-invert-colors
              </v-icon>
            </v-text-field>
          </template>
          <v-color-picker
            v-model="currentItem['spinner_color']"
            @input="changeSpinnerColor"
          ></v-color-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col class="col-6 px-1">
        <v-text-field
          type="number"
          @input="changeLabelSize"
          label="Etiket Yazı Büyüklüğü"
          v-model="currentItem['etiketFontSize']"
        />
      </v-col>
      <v-col class="col-6 px-1">
        <v-text-field
          @input="changeLabel"
          label="Button Etiket"
          v-model="currentItem['elementLabel']"
        />
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-autocomplete
        label="Animasyon Tipi (0 - 1 şeklinde düşünülmeli)"
        :items="animationTypes"
        v-model="currentItem['animation_type']"
        @input="changeSwitchBase"
      />
    </v-row>

    <v-row no-gutters>
      <v-autocomplete
        v-model="currentItem['bagli_alan']"
        item-text="Text"
        item-value="Id"
        label="Bağlı Alan"
        :items="bagliAlanItems"
      />
    </v-row>

    <v-row no-gutters>
      <v-text-field label="Timeout (MS)" v-model="currentItem['timeoutMS']" />
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  changeSvgDimension,
  findBinaryValue
} from "@/components/ModemLayoutWizard/ModemLayoutSettings/svgComponentUtils/svgUtilFunctions";
import store from "@/vuex_store/store_wrapper";

export default {
  name: "switchModern",
  props: {
    currentItem: {
      type: Object,
      required: false
    },
    modemID: {
      type: [Array, Number],
      required: false
    },
    //from modem tab layout
    parentName: String,
    svgItemObject: {
      type: Object,
      required: false
    },
    listSetup: Object,
    dataProperty: Object
  },
  data() {
    return {
      colorMenuPBSpinner: false,
      switchValue: "0",
      animationTypes: [
        "Kırmızı-Yeşil",
        "Yeşil-Kırmızı",
        "Gri-Yeşil",
        "Yeşil-Gri"
      ],
      colors: {
        kirmiziYesil: ["#F34A4A", "#55D278"],
        yesilKirmizi: ["#55D278", "#F34A4A"],
        yesilGri: ["#55D278", "#969698"],
        griYesil: ["#969698", "#55D278"]
      },
      bagliAlanItems: [],
      unsubscribe: null,
      isButtonPressed: false,
      switchTimeout: null
    };
  },
  computed: {
    ...mapGetters({
      getterSVGScaleInfo: "storeSVGScale/getterSVGScaleInfo"
    }),
    svgDimen: function() {
      return changeSvgDimension(this.currentItem);
    },
    getBaseGroupId: function() {
      if (this.parentName === "ModemTabLayout") {
        return document
          .getElementById(this.svgItemObject["id"])
          .getElementsByTagName("svg")[0];
      } else {
        return document
          .getElementById(this.currentItem["id"])
          .getElementsByTagName("svg")[0];
      }
    },
    getClickGroup: function() {
      const base = this.getBaseGroupId;
      return base.querySelector("#switch_click_g");
    },
    getSwitchSpinner: function() {
      const base = this.getBaseGroupId;
      return base.querySelector("#sw_spinner");
    },
    getSwitchBase: function() {
      const base = this.getBaseGroupId;
      return base.querySelector("#switch_base_colorful");
    },
    getSwitchNudge: function() {
      const base = this.getBaseGroupId;
      return base.querySelector("#switch_nudge_g");
    },
    getSwitchLabel: function() {
      const base = this.getBaseGroupId;
      return base.querySelector("#sw_etiket");
    }
  },
  beforeDestroy() {
    this.unsubscribe && this.unsubscribe();
    this.switchTimeout !== null && clearTimeout(this.switchTimeout);
/*    if (this.parentName === "ModemTabLayout") {
      this.getClickGroup.removeEventListener("click", this.swClickEvent);
    }*/
  },
  created() {
    this.unsubscribe = store.subscribe(this.storeHandler);
    if (this.parentName !== "ModemTabLayout") {
      this.getBagliAlanInputs();
    }
  },
  mounted() {
    if (this.parentName === "ModemTabLayout") {
      this.getClickGroup.addEventListener("click", this.swClickEvent, false);
      this.setScadaData(this.dataProperty["Id"], this.dataProperty["Datas"]);
      console.log("Switch Data Çözülecek", this.dataProperty);
    }
  },
  methods: {
    storeHandler: function(mutation) {
      const { type, payload } = mutation;
      if (type === "storeSocket/on_socket_message") {
        const { data, command } = JSON.parse(payload.data);
        if (command === "ModemIOList") {
          const { TargetID, Elements } = data;
          const { id } =
            this.parentName === "ModemLayoutWizard"
              ? this.currentItem
              : this.svgItemObject;
          if (id === TargetID) {
            console.log("Modem Inputlar", data);
            this.bagliAlanItems = Object.keys(Elements).map(key => {
              return {
                Text: Elements[key],
                Id: key
              };
            });
          }
        } else if (command === "ProcessData") {
          const { ModemId, Datas } = data;
          if (ModemId === this.modemID) {
            console.log("Switch Modern Data Received !");
            this.setScadaData(ModemId, Datas);
          }
        }
      }
    },
    setScadaData: function(ModemId, Datas) {
      if (Object.keys(Datas).length > 0) {
        const { bagli_alan } = this.svgItemObject;
        const [tip, master, bit] = bagli_alan.split("_");
        if (Datas.hasOwnProperty(`${tip}_${master}`)) {
          console.log("PushButton Tip MasterId Bit", tip, master, bit);
          this.switchValue = findBinaryValue(Datas[`${tip}_${master}`], bit);
          console.log("Switch Value", this.switchValue);
          this.switchAnimFilter();
        }
      }
    },
    switchAnimFilter: function() {
      this.switchTimeout && clearTimeout(this.switchTimeout);
      this.isButtonPressed = false;

      if (this.switchValue === "0") {
        this.switchOff();
      } else if (this.switchValue === "1") {
        this.switchOn();
      }
    },
    swClickEvent: function() {
      if (!this.isButtonPressed) {
        this.isButtonPressed = true;
        //this.getSwitchSpinner.classList.add("switch_spin");

        if (this.switchValue === "0") {
          this.switchOn();
        } else if (this.switchValue === "1") {
          this.switchOff();
        }

        const { bagli_alan, timeoutMS } = this.svgItemObject;
        const [tip, master_id, bit] = bagli_alan.split("_");

        const setOutput = {
          type: "setModemOutput",
          payload: {
            ModemId: this.modemID,
            Target: `${tip}_${master_id}_${bit}`,
            Value: this.switchValue === "1" ? 0 : 1
          }
        };
        console.log("Offset Switch Clicked Command", setOutput);
        this.sendMessageToWS(setOutput);
        this.switchTimeout = setTimeout(
          this.switchTimeoutFun,
          Number(timeoutMS)
        );
      } else {
        console.log("Cannot click switch at this time");
      }
    },
    switchTimeoutFun: function() {
      //this.getSwitchSpinner.classList.remove("switch_spin");
      if (this.switchValue === "0") {
        //off
        this.switchOff();
      } else if (this.switchValue === "1") {
        //on
        this.switchOn();
      }
    },
    switchOn: function() {
      const { oneColor } = this.svgItemObject;
      this.getSwitchBase.setAttribute(
        "style",
        `fill: ${oneColor}; transition: .5s ease-in-out;`
      );
      this.getSwitchNudge.setAttribute(
        "style",
        "transition: .5s ease-in-out; transform: translateX(36px)"
      );
    },
    switchOff: function() {
      const { zeroColor } = this.svgItemObject;
      this.getSwitchBase.setAttribute(
        "style",
        `fill: ${zeroColor}; transition: .5s ease-in-out;`
      );
      this.getSwitchNudge.setAttribute(
        "style",
        "transition: .5s ease-in-out; transform: translateX(0)"
      );
    },
    changeLabelSize: function() {
      const tspan = this.getSwitchLabel.getElementsByTagName("tspan")[0];
      tspan.setAttribute("font-size", this.currentItem["etiketFontSize"]);
    },
    changeLabel: function() {
      const tspan = this.getSwitchLabel.getElementsByTagName("tspan")[0];
      tspan.innerHTML = this.currentItem["elementLabel"];
    },
    changeSpinnerColor: function() {
      this.getSwitchSpinner.setAttribute(
        "stroke",
        `${this.currentItem["spinner_color"]}`
      );
    },
    changeSwitchBase: function(v) {
      console.log("Switch Anim", v);
      switch (v) {
        case "Kırmızı-Yeşil":
          this.getSwitchBase.setAttribute("fill", this.colors.kirmiziYesil[0]);
          this.currentItem.zeroColor = this.colors.kirmiziYesil[0];
          this.currentItem.oneColor = this.colors.kirmiziYesil[1];
          break;
        case "Yeşil-Kırmızı":
          this.getSwitchBase.setAttribute("fill", this.colors.yesilKirmizi[0]);
          this.currentItem.zeroColor = this.colors.yesilKirmizi[0];
          this.currentItem.oneColor = this.colors.yesilKirmizi[1];
          break;
        case "Gri-Yeşil":
          this.getSwitchBase.setAttribute("fill", this.colors.griYesil[0]);
          this.currentItem.zeroColor = this.colors.griYesil[0];
          this.currentItem.oneColor = this.colors.griYesil[1];
          break;
        case "Yeşil-Gri":
          this.getSwitchBase.setAttribute("fill", this.colors.yesilGri[0]);
          this.currentItem.zeroColor = this.colors.yesilGri[0];
          this.currentItem.oneColor = this.colors.yesilGri[1];
          break;
      }
    },
    getBagliAlanInputs: function() {
      let getModemIOList = {
        type: "getModemIOList",
        payload: {
          TargetID: this.currentItem["id"],
          ModemId: parseInt(this.modemID[0]),
          Secim: "2@3@4@5"
        }
      };
      this.sendMessageToWS(getModemIOList);
    }
  }
};
</script>

<style scoped></style>
