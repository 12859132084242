<template>
  <v-row no-gutters>
    <v-col>
      <v-autocomplete
          :label="getFieldItem.Label"
          :items="modemComboItems"
          item-text="modem_name"
          item-value="modem_id"
          @input="selectModem"
          v-model="parseVal"
          :readonly="readOnly"
      />

      <v-autocomplete
          label="Girdi"
          :items="modemInputs"
          item-value="Value"
          item-text="Text"
          v-model="dynamicM['input_variable']"
          :readonly="readOnly"
      />

      <v-autocomplete
          label="Çıktı"
          :items="modemOutputs"
          item-value="Value"
          item-text="Text"
          v-model="dynamicM['output_variable']"
          :readonly="readOnly"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "pIdFieldSelectorComponent",
  props: {
    fieldItem: {
      type: Object,
      required: true
    },
    dynamicM: [Array, Object],
    actionType: String,
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      modemInputs: [],
      modemOutputs: [],
      modemComboItems: []
    };
  },
  computed: {
    parseVal: {
      get: function() {
        return parseInt(this.dynamicM["modem_id"]);
      },
      set: function(value) {
        this.dynamicM["modem_id"] = parseInt(value);
      }
    },
    d_M: {
      get: function() {
        return this.dynamicM;
      },
      set: function(value) {
        this.dynamicM = value;
      }
    },
    getFieldItem: function() {
      return this.fieldItem;
    },
    getActionType: function() {
      return this.actionType;
    },
    ...mapGetters({
      getterSocketMessage: "storeSocket/getterSocketMessage"
    })
  },
  created() {
    this.sendMessageToWS({ type: "getFullModemList", payload: null });
    if (this.actionType === 'duzenle' || this.actionType === 'incele') {
      this.sendMessageToWS({
        type: "getModemInputs",
        payload: { ModemId: this.parseVal, Type: "Link" }
      });
      this.sendMessageToWS({
        type: "getModemOutputs",
        payload: { ModemId: this.parseVal, Type: "Link" }
      });
    }
  },
  watch: {
    getterSocketMessage: function(value) {
      let command = JSON.parse(value.data).command;
      //console.log("Inputs: " + JSON.parse(value.data));
      if (command === "ModemComboList") {
        console.log(JSON.parse(value.data).data);
        this.modemComboItems = JSON.parse(value.data).data;
        if (this.readOnly) {
          this.sendMessageToWS({
            type: "getModemInputs",
            payload: { ModemId: this.parseVal, Type: "Link" }
          });
          this.sendMessageToWS({
            type: "getModemOutputs",
            payload: { ModemId: this.parseVal, Type: "Link" }
          });
        }
      } else if (command === "ModemInputList") {
        //console.log(JSON.parse(value.data).data);
        //this.modemInputs = JSON.parse(value.data).data;
        this.setModemInputs(JSON.parse(value.data).data);
      } else if (command === "ModemOutputList") {
        //console.log(JSON.parse(value.data).data);
        //this.modemOutputs = JSON.parse(value.data).data;
        this.setModemOutputs(JSON.parse(value.data).data);
      }
    }
  },
  methods: {
    setModemInputs: function(value) {
      this.modemInputs = [];
      if (value !== null) {
        Object.keys(value).forEach(key => {
          this.modemInputs.push({
            Value: key,
            Text: value[key]
          });
        });
      }
    },
    setModemOutputs: function(value) {
      this.modemOutputs = [];
      if (value !== null) {
        Object.keys(value).forEach(key => {
          this.modemOutputs.push({
            Value: key,
            Text: value[key]
          });
        });
      }
    },
    selectModem: function(value) {
      console.log(value);
      let modemInps = {
        type: "getModemInputs",
        payload: { ModemId: value, Type: "Link" }
      };
      let modemOuts = {
        type: "getModemOutputs",
        payload: { ModemId: value, Type: "Link" }
      };

      this.sendMessageToWS(modemInps);
      this.sendMessageToWS(modemOuts);
    }
  }
};
</script>

<style scoped></style>
