<template>
  <div id="base_grid" v-on:mousemove="mouseMoveEvent">
    <div class="modemPopover" v-if="gridLayout.length !== 0">
      <modem-g-popover
          key="Grid"
          @modemEvent="modemEventReceived"
          :pre-selected="selectedModemsLayout"
      />
    </div>

    <div v-if="selectedModemsLayout.length === 0">
      <stations-no-data
          title="Hiçbir istasyon seçili değil 😕"
          subtitle="Bir istasyon seçmek isterseniz"
      >
        <template v-slot:custom-component>
          <modem-g-popover
              key="NoData"
              @modemEvent="modemEventReceived"
              :pre-selected="gridLayout.length === 0 ? [] : selectedModemsLayout"
              icon-name="add"
              button-color="indigo accent-2"
          />
        </template>
      </stations-no-data>
    </div>

    <grid-layout
        v-else
        :layout.sync="gridLayout"
        :col-num="colNum"
        :row-height="rowHeight"
        :is-draggable="true"
        :is-resizable="true"
        :is-mirrored="false"
        :vertical-compact="true"
        :margin="itemMargin"
        :use-css-transforms="true"
        :responsive="true"
        autoSize
        @layout-ready="layoutReadyEvent"
    >
      <grid-item
          :ref="`grid_item_tab_list_${item.modem_id}`"
          v-for="item in gridLayout"
          :class="['gridItemModem_' + item.modem_id, themeBackgroundColor]"
          :x="item.x"
          :y="item.y"
          :w="item.w"
          :h="item.h"
          :i="item.i"
          :key="item.i"
          :is-resizable="item.resizable"
          :style="
          item[`tab_list_${item.modem_id}`]
            ? 'overflow-y: scroll;'
            : 'overflow-y: hidden;'
        "
          @resized="resizedGridItem"
          @container-resized="containerResizedEvent"
      >
        <modem-tab-layout
            :hasCloseButton="false"
            :style="'height:' + rowHeight + ';'"
            :genislik_w="item.w"
            :key="item.i"
            :modem-data="item"
            @tabChanged="tabChanged"
        />
      </grid-item>
    </grid-layout>

    <grid-dial
        @fabChildEvent="fabChildEvent"
        :show-fab="showGridFAB"
        id="grid-dial"
    />
  </div>
</template>

<script>
import VueGridLayout from "vue-grid-layout";
import ModemTabLayout from "./ModemTabLayout";
import store from "../../../vuex_store/store_wrapper";
import { mapActions } from "vuex";
import ModemGroupPopover from "@/components/ModemGroupWizard/ModemGroupPopover";
import StationsNoData from "@/components/NoDataViews/NoDataWithSlot";
import LayoutSpeedDial from "@/components/GridComponent/ModemGridNew/LayoutSpeedDial";
import { fabEvents } from "@/components/GridComponent/ModemGridNew/grid_layout_fab_events";

export default {
  name: "ModemDynamicGridComponent",
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    "modem-tab-layout": ModemTabLayout,
    "modem-g-popover": ModemGroupPopover,
    "stations-no-data": StationsNoData,
    "grid-dial": LayoutSpeedDial
  },
  props: {
    selectedModemsLayout: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      unsubscribe: null,
      /*Cray cray*/
      colWidth: 0,
      rowHeight: 20,
      itemMargin: [20, 20],
      colNum: 12,
      /*Cray cray*/
      gridLayout: [],
      showGridFAB: false,
      tab: "",
      dimenPayload: { itemHeight: 0, itemWidth: 0 },
    };
  },
  computed: {
    getSelectedModemsLayout: function() {
      return this.selectedModemsLayout;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    themeBackgroundColor: function() {
      return this.theme === "dark" ? "darkGridBG" : "lightGridBG";
    }
  },
  beforeDestroy() {
    //Unsubscribe from store mutation
    this.unsubscribe && this.unsubscribe();
  },
  created() {
    //If any layout item exists then set the layout
    this.getSelectedModemsLayout.length > 0 &&
    this.setInitialLayout(this.getSelectedModemsLayout);
    //subscribing to on socket message mutation with an handler function
    this.unsubscribe = store.subscribe(this.storeSubscribeHandler);
  },
  methods: {
    tabChanged: function({ modem_id, tab}) {
      const { itemHeight } = this.dimenPayload;

      if (Number(itemHeight) < 976) {
        this.gridLayout.filter(v => {
          if (v.hasOwnProperty(tab)) {
            v[tab] = true;
          } else {
            v[`tab_list_${modem_id}`] = false;
          }
        });
      } else {
        this.gridLayout.filter(v => {
          if (v.hasOwnProperty(tab)) {
            v[tab] = false;
          }
        });
      }
    },
    //This action is for scaling the svg inside the grid item.
    ...mapActions({
      assignSVGScale: "storeSVGScale/assignSVGScale"
    }),
    // Handling socket messages and other stuff if it's necessary
    storeSubscribeHandler: function(mutation) {
      const { type, payload } = mutation;
      if (type === "storeSocket/on_socket_message") {
        const { command, layout, data } = JSON.parse(payload.data);
        if (command === "ListOfModemLayouts") {
          //List Of Pre Selected Modem Layouts
          console.log(
              "STORE MDGC ListOfModemLayouts -- Modem Dynamic Grid Component",
              layout
          );
          if (layout !== null) {
            this.setInitialLayout(layout);
          } else {
            this.gridLayout = [];
          }
        } else if (command === "Registration") {
          if (data !== null) {
            //Get updated Modem Layout after registration process
            this.sendMessageToWS({
              type: "getModemsLayouts",
              payload: null
            });
          }
        } else if (command === "UnRegistration") {
          if (data !== null) {
            //Get updated Modem Layout after unregistration process
            this.sendMessageToWS({
              type: "getModemsLayouts",
              payload: null
            });
          }
        }
      } else if (type === "storeSVGScale/setSVGScaleInfo") {
        //console.log("Dimen Payload", payload);
        this.dimenPayload = payload;
      }
    },
    layoutReadyEvent: function(mountedLayout) {
      console.log("Layout Mounted", mountedLayout);
      const innerHeight = window.innerHeight;
      //const innerWidth = window.innerWidth;
      mountedLayout.forEach(item => {
        const { h, modem_id } = item;
        if (h === 12) {
          this.rowHeight = Math.round(
              (innerHeight - Math.max(0, h - 1) * this.itemMargin[1]) / h -
              this.itemMargin[1] / 2
          );
          const element = document.querySelector(`.gridItemModem_${modem_id}`);
          console.log(element.clientWidth);
        }
      });
    },
    setInitialLayout: function(layout) {
      this.gridLayout = layout.map(obj => {
        const { Layout, Data } = obj;
        const { modem_id, modem_name, listsetup } = Layout;
        const { Guncelleme } = Data;
        return {
          x: 0,
          y: 0,
          h: 12,
          w: 12,
          i: modem_id,
          modem_id: modem_id,
          modem_name: modem_name,
          son_guncelleme: Guncelleme,
          list_setup: listsetup,
          Layout: Layout,
          Data: Data,
          [`tab_list_${modem_id}`]: false
        };
      });
    },
    //request function
    sendModemLayoutRequest: function(data) {
      if (data.checked) {
        //belli bir modem checked olduysa request gönder data.modem.modem_id
        let request = {
          type: "registerModem",
          payload: { ModemId: data.modem.modem_id }
        };
        this.sendMessageToWS(request);
      } else {
        //unchecked olduysa sil
        let request = {
          type: "unregisterModem",
          payload: { ModemId: data.modem.modem_id }
        };
        this.sendMessageToWS(request);
      }
    },
    /*eslint-disable*/
    //When grid item is resized; send action to resize the SVG if Layout['haveScada'] is true
    resizedGridItem: function(i, newH, newW, newHPx, newWPx) {
      this.sendSVGResizeAction(newHPx, newWPx);
    },
    /*eslint-disable*/
    //When container is resized; send action to resize the SVG if Layout['haveScada'] is true
    containerResizedEvent: function(i, newH, newW, newHPx, newWPx) {
      this.sendSVGResizeAction(newHPx, newWPx);
    },
    //Send action assignSVGScale
    sendSVGResizeAction: function(height, width) {
      this.gridLayout.forEach(item => {
        const { Layout } = item;
        if (Layout["haveScada"]) {
          this.assignSVGScale({
            itemHeight: height,
            itemWidth: width
          });
        }
      });
    },
    fabChildEvent: function(i) {
      return this.layoutUpdatedEvent(fabEvents(i, this.gridLayout));
    },
    layoutUpdatedEvent: function(newLayout) {
      console.log("Updated layout: ", newLayout);
      this.gridLayout = newLayout;
      this.$nextTick(() => {
        this.gridLayout.forEach(item => {
          const { modem_id, Layout } = item;
          if (Layout["haveScada"]) {
            const element = document.querySelector(
                `.gridItemModem_${modem_id}`
            );
            this.assignSVGScale({
              itemHeight: element.clientHeight,
              itemWidth: element.clientWidth
            });
            console.log(
                `Element ${modem_id}, width: ${element.clientWidth}, height: ${element.clientHeight}`
            );
          }
        });
      });
    },
    mouseMoveEvent: function(e) {
      e.preventDefault();

      const windowHeight = window.innerHeight;
      const pageY = e.pageY;
      const scrollY = window.scrollY;
      this.showGridFAB = pageY > windowHeight + scrollY - 150;
    },
    modemEventReceived: function(v) {
      console.log("Modem Event Received", v);
      const { isSelected, modem_id } = v;
      if (isSelected) {
        console.log("Modem Event Received::Selected", v);
        const request = {
          type: "registerModem",
          payload: { ModemId: modem_id }
        };
        this.sendMessageToWS(request);
      } else {
        console.log("Modem Event Received: DeSelected", v);
        const request = {
          type: "unregisterModem",
          payload: { ModemId: modem_id }
        };
        this.sendMessageToWS(request);
      }
    }
  }
};
</script>

<style scoped>
.vue-grid-item {
  /*background: white;*/
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px !important;
  padding-bottom: 20px;
}

.last_update_bar {
  position: fixed !important;
  bottom: 5px !important;
  left: 50%;
  text-align: center !important;
  transform: translateX(-50%);
}

.last_update_bar span {
  background: #3f51b5;
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
}

.last_update_bar.noupdate span {
  background: #b52e36;
}

.lightGridBG {
  background-color: #fff;
}

.darkGridBG {
  background-color: #191927;
}
</style>
